import Notification from '../../../Library/notification';
class Invoice {

    constructor(parent){
        this.parent = parent;
    }
    
    addPayment(addManualyCustomerPayment,paymentDate,paymentItems,customerID,membershipDuration){

        let notify = new Notification();  

        if(paymentItems.length > 0){
            for(let i in paymentItems){
                paymentItems[i].price = parseFloat(paymentItems[i].price);
            }
        }

        if(membershipDuration != 0){
            addManualyCustomerPayment({
                variables:{
                    paymentDate,
                    paymentItems,
                    customerID
                }
            })
            
        }else{
            notify.setNotification(null,'Aby jste mohli vkládat platbu klientovi, je nutné abyměl nastavené a uložené člentví.',false,true,this.parent.props.client);
        }

    }

    changeInvoiceItemValue(e,index,type){

        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;

        var arr = [...this.parent.state.paymentItems];
        arr[index] = {...arr[index],[type]:v};

        this.parent.setState({paymentItems:arr});


    }

    removeInvoiceItem(index){

        var arr = [...this.parent.state.paymentItems];
        arr.splice(index,1);
        this.parent.setState({paymentItems:arr});


    }

}

export default Invoice;