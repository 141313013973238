/**
 * Třía která obsahuje veškeré pomocné funkce, pro práci s přidáváním a editací zákazníků
 */

import {GET_CUSTOMER,GET_CUSTOMERS,GET_CUSTOMER_BY_EMAIL,HAS_CUSTOMER_AFFILIATE} from '../Queries/customer.js';
import Notification from '../../../Library/notification';
import Helper from '../../../Library/helper';

class Customer {

    constructor(parent){
        this.parent = parent;
    }

    /**
     * při inicializaci přidání zákazníka nebo jeho úpravy vytáhne potřebná data k zákazníkovi
     */

    async getData(){

        var customerID = 0;
        if(this.parent.props.selectedCustomerID)customerID = this.parent.props.selectedCustomerID;
        
        var data = await this.parent.props.client.query({ 
            query: GET_CUSTOMER,
            errorPolicy:"all",
            variables:{customerID:customerID,lang:"cz"}, 
            fetchPolicy: 'network-only'
        });

        if(data.errors){
            this.parent.setState({apolloError:data.errors[0].message});
        }

        if(data.data){

            var allCountries = [];
            var allMembershipDurations = [];
            var allCustomerTypes = [];
            var allDomains = [];
            var allGuides = [];
            var countryID = 60;
            var deliveryCountryID = 60;
            
            if(data.data.allDomains && data.data.allDomains.length > 0){
                allDomains = data.data.allDomains;
            }

            if(data.data.allAdminMembershipDurations && data.data.allAdminMembershipDurations.length > 0){
                allMembershipDurations = data.data.allAdminMembershipDurations;
            }

            if(data.data.allCustomerTypes && data.data.allCustomerTypes.length > 0){
                allCustomerTypes = data.data.allCustomerTypes;
            }

            if(data.data.allGuides && data.data.allGuides.length > 0){
                allGuides = data.data.allGuides;
            }

            if(data.data.allCountries && data.data.allCountries.length > 0){
                allCountries =  data.data.allCountries;
                for(let val of allCountries){
                    if(val.defaultSelected == 1){
                        countryID = val.countryID;
                        deliveryCountryID = val.countryID;
                    }
                }
            }
            
            if(data.data.oneCustomer){

                var selectedMaterials = [];
                if(data.data.oneCustomer.materials.length > 0){
                    for(let val of data.data.oneCustomer.materials){
                        selectedMaterials.push(val.materialID);
                    }
                }

                this.parent.setState({
                    apolloError:"",selectedCustomerID:customerID,allGuides, allCountries,allMembershipDurations,allDomains,allCustomerTypes,oldCustomerTypeID:data.data.oneCustomer.customerTypeID,oldMembershipTo:data.data.oneCustomer.membershipTo,
                    formData:{...this.parent.state.formData,
                        customerID:          data.data.oneCustomer.customerID,
                        guideID:             data.data.oneCustomer.guideID,
                        customerTypeID:      data.data.oneCustomer.customerTypeID,
                        questionnaireID:     data.data.oneCustomer.questionnaireID,
                        name:                data.data.oneCustomer.name,
                        surname:             data.data.oneCustomer.surname,
                        astralName:          data.data.oneCustomer.astralName,
                        email:               data.data.oneCustomer.email,
                        tel:                 data.data.oneCustomer.tel,
                        bankAccount:         data.data.oneCustomer.bankAccount,
                        street:              data.data.oneCustomer.street,
                        city:                data.data.oneCustomer.city,
                        zip:                 data.data.oneCustomer.zip,
                        countryID:           data.data.oneCustomer.countryID,
                        domainID:            data.data.oneCustomer.domainID,
                        emailTextToGuide:    data.data.oneCustomer.emailTextToGuide,
                        firstPaymentUrl:     data.data.oneCustomer.firstPaymentUrl,
                        description:         data.data.oneCustomer.description,
                        autoPayments:        data.data.oneCustomer.autoPayments,
                        membershipDuration:  data.data.oneCustomer.membershipDuration,
                        selectedMaterials:   selectedMaterials,
                        affiliateProfit:     data.data.oneCustomer.affiliateProfit,
                        affiliateProfit2:    data.data.oneCustomer.affiliateProfit2
  
                    }
                });

            }else{
                this.parent.setState({ apolloError:"",allCustomerTypes,allGuides, allCountries,allMembershipDurations,allDomains,formData:{...this.parent.state.formData, countryID, deliveryCountryID }});
            }
        }
    }

    /**
     * Přidání nebo úprava nového zákazníka
     * @param {*} addCustomer funkce z apollo, pomocí které se posílají data na server
     */
    async addCustomer(addCustomer){

        const data = this.parent.state.formData;
        const oldCustomerTypeID = this.parent.state.oldCustomerTypeID;
        const oldMembershipTo = this.parent.state.oldMembershipTo;
        let notify = new Notification();   

        if((data.name != "" && data.surname != "") || data.customerTypeID == 6 || oldCustomerTypeID == 6 || oldMembershipTo == null){         
            
            if(data.domainID != 0 || data.customerTypeID == 6 || oldCustomerTypeID == 6){

                if((data.customerID == 0 && data.membershipDuration != 0 && (data.customerTypeID == 1 || data.customerTypeID == 7)) || data.customerID != 0 || (data.customerTypeID != 1 && data.customerTypeID != 7)){

                    const helper = new Helper();
                    if(data.email != "" && helper.validateEmail(data.email)){

                        data.autoPayments     = parseInt(data.autoPayments);
                        data.affiliateProfit  = parseFloat(data.affiliateProfit);
                        data.affiliateProfit2 = parseFloat(data.affiliateProfit2);

                        var custEmail = await this.parent.props.client.query({ 
                            query: GET_CUSTOMER_BY_EMAIL,
                            errorPolicy:"all",
                            variables:{email:data.email, customerID:data.customerID}, 
                            fetchPolicy: 'network-only'
                        });
                
                        if(custEmail.errors){
                            this.parent.setState({apolloError:custEmail.errors[0].message});
                        }else{
                            if(custEmail.data && custEmail.data.customerByEmail){
                                notify.setNotification(null,'Tento email už používá jiný zákazník',false,true,this.parent.props.client); 
                            }else{

                                
                                if(data.customerID != 0){

                                    var custData = await this.parent.props.client.query({ 
                                        query: HAS_CUSTOMER_AFFILIATE,
                                        errorPolicy:"all",
                                        variables:{customerID:data.customerID}, 
                                        fetchPolicy: 'network-only'
                                    });

                                    if(custData && custData.data.hasCustomerAffiliate && custData.data.hasCustomerAffiliate.domainID != data.domainID){

                                        notify.setNotification(null,'Tento Vládce Síly využívá affiliate, a proto mu nelze vyměnit doména.',false,true,this.parent.props.client);

                                    }else{
                                        addCustomer({
                                            variables:data
                                        });
                                    }

                                }else{
                                    addCustomer({
                                        variables:data
                                    });
                                }
                            }
                        }

                    }else{
                        notify.setNotification(null,'Email není ve správném tvaru',false,true,this.parent.props.client); 
                    }
                }else{
                    notify.setNotification(null,'Nevybrali jste členství',false,true,this.parent.props.client); 
                }
            }else{
                notify.setNotification(null,'Nevyplnili jste doménu',false,true,this.parent.props.client); 
            }
           
        }else{
            notify.setNotification(null,'Nevyplnili jste jméno a příjmení zákazníka.',false,true,this.parent.props.client);
        }

    }

    /**
     * Při vložení nebo úpravě zákazníka dojde k update cache a vykreslí se opět seznam veškerých zákazníků
     * @param {*} cache apollo cahe v které jsou oložena data
     * @param {*} response položka, která byla vložena nebo upravována
     */

    updateCustomerList(cache,response,selectedCustomerID,customerListVariables){

        const { allCustomers } = cache.readQuery({ query: GET_CUSTOMERS, variables:customerListVariables });

        if(!(selectedCustomerID && selectedCustomerID != 0)){

            cache.writeQuery({ 
                query:GET_CUSTOMERS,
                variables:customerListVariables,
                data:{
                    allCustomers: [response.data.addEditCustomer,...allCustomers]
                } 
            });

        }else{

            var arr = [...allCustomers];
            
            arr.forEach((item,index) => {
                if(item.customerID == response.data.addEditCustomer.customerID){

                    arr[index].customerID         = response.data.addEditCustomer.customerID;
                    arr[index].customerTypeID     = response.data.addEditCustomer.customerTypeID;
                    arr[index].name               = response.data.addEditCustomer.name;
                    arr[index].surname            = response.data.addEditCustomer.surname;
                    arr[index].company            = response.data.addEditCustomer.company;
                    arr[index].tel                = response.data.addEditCustomer.tel;
                    arr[index].email              = response.data.addEditCustomer.email;
                    arr[index].membership         = response.data.addEditCustomer.membership;
                    arr[index].autoPayments       = response.data.addEditCustomer.autoPayments;
                    arr[index].membershipDuration = response.data.addEditCustomer.membershipDuration;
                    arr[index].customerType       = response.data.addEditCustomer.customerType;

                    if(response.data.addEditCustomer.membershipTo){
                        arr[index].membershipTo = response.data.addEditCustomer.membershipTo;
                    }
                }

            });

            cache.writeQuery({ 
                query:GET_CUSTOMERS,
                variables:customerListVariables,
                data:{
                    allCustomers: arr
                } 
            });
        }
    }

    /**
     * Update seznamu zákazníků po smazání vybraných zákazníků
     * @param {*} cache apollo cache, kde jsou uložena data
     * @param {*} response hodnota, která se vrátila po smazání potahu
     */
    
    updateAfterDeleteCustomer(cache, response,customerListVariables){

        var resp = response.data.deleteCustomers.split(",");

        const { allCustomers } = cache.readQuery({ query: GET_CUSTOMERS,variables:customerListVariables });
        var arr = [...allCustomers];

        resp.forEach((it,ind) => {
            arr.forEach((item,index) => {
                if(item.customerID == it){
                    arr = [...arr.slice(0,index),...arr.slice(index + 1)]
                }
            });
        })
        
    
        cache.writeQuery({ 
            query:GET_CUSTOMERS,
            variables:customerListVariables,
            data:{
                allCustomers: [...arr]
            } 
        });

    }

    updateAfterRemoveMembership(cache, response,customerListVariables){

        var resp = response.data.removeMembership;

        const { allCustomers } = cache.readQuery({ query: GET_CUSTOMERS,variables:customerListVariables });
        var arr = [...allCustomers];

        arr.forEach((item,index) => {
            if(item.customerStripeID == resp.customerStripeID){
                arr[index].autoPayments = resp.autoPayments;
            }

        });
    
        cache.writeQuery({ 
            query:GET_CUSTOMERS,
            variables:customerListVariables,
            data:{
                allCustomers: [...arr]
            } 
        });

    }

    /**
     * pomocná funkce pro editor, která ukládá vložený text do popisu
     * @param {*} data text pro popis
     */

    setDescription(data){
        this.parent.setState({...this.parent.state,formData:{...this.parent.state.formData,["description"]: data}});
    }
    setEmailTextToGuide(data){
        this.parent.setState({...this.parent.state,formData:{...this.parent.state.formData,["emailTextToGuide"]: data}});
    }

    /**
     * funkce ukládá data, která byla vložena do formuláře
     * @param {*} e ukazatel na element
     */
    
    formHandle(e){
	  
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        this.parent.setState({formData:{...this.parent.state.formData,[n]: v}});
              
    }

}

export default Customer;