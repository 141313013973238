/*
    Výběr veškerých kategorií
*/
import React,{ Component,useState } from 'react';
import {useQuery,useMutation} from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import NoItems from '../../../GlobalComponents/NoItems';
import {GET_QUESTIONNAIRES,DELETE_QUESTIONNAIRES} from '../Queries/questionnaire.js';
import AddEditQuestionnaire from './AddEditQuestionnaire';
import Helper from '../../../Library/helper';
import edit from '../../../Public/Images/edit.svg';
import Notification from '../../../Library/notification';
import UseQuestionnaire from '../Library/useQuestionnaire';
import { withApollo } from 'react-apollo';

const Questionnaire = ({client}) => {

    const listVariables = {
        lang:"cz"
    }

    const [showAddEditQuestionnaire, SetShowAddEditQuestionnaire] = useState();
    const [selectedQuestionnaireID, SetSelectedQuestionnaireID] = useState();

    const [DeleteQuestionnaires,{loading:deleteLoading, error:deleteError}] = useMutation(DELETE_QUESTIONNAIRES,{

        update:async (cache, response) => {
            
            var resp = response.data.deleteQuestionnaire.split(",");

            const { getAllQuestionnaires } = cache.readQuery({ query: GET_QUESTIONNAIRES ,variables:listVariables});
            var arr = [...getAllQuestionnaires];
            
            resp.forEach((it,ind) => {
                arr.forEach((item,index) => {
                    if(item.questionnaireID == it){
                        arr = [...arr.slice(0,index),...arr.slice(index + 1)]
                    }
                });
            }) 
            
            cache.writeQuery({ 
                query:GET_QUESTIONNAIRES,
                variables:listVariables,
                data:{
                    getAllQuestionnaires: [...arr]
                } 
            });

            let notify = new Notification();
            notify.setNotification(cache,'Úspěšně smazáno',true,false);

        },
        onError:(error) => {
            const helper = new Helper(); 
            let notify = new Notification();
            notify.setNotification(null,helper.getApolloErrorText(error),false,true,client);
        }
    });

    const {
        selectedItems,
        showDeleteNotifi,
        DelQuestionnaire,
        ShowDelNotifi,
        AddRemoveItemToDelete
    } = UseQuestionnaire(DeleteQuestionnaires,client);

    const {data,loading, error} = useQuery(GET_QUESTIONNAIRES,{
        variables:listVariables
    });

    const OpenAddEditQuestionnaire = (type,questionnaireID) => {

        SetSelectedQuestionnaireID(questionnaireID);
        SetShowAddEditQuestionnaire(type); 
        
    }

    var err = "";
    if(error){
        const helper = new Helper(); 
        err = helper.getApolloErrorText(error)
    }
  
    return(
        <div id="settings" className="whole-container" >
            
            <div className="row" >
                <div className="col-sm one">
                    <div className="card main">
                        
                        <div className="card-header d-flex align-items-center">
                            Seznam dotazníků
                            <button className="btn btn-primary btn-thiner ml-auto" onClick={() => OpenAddEditQuestionnaire(true)}>Přidat</button>
                            <button className="btn btn-danger btn-thiner ml-2" onClick={() => ShowDelNotifi()}>Smazat označené</button>
                        </div>
                        <div className="card-body">

                            {err != "" ?
                                <Error text={err} />
                            :
                                (loading ?
                                    <Loading />
                                :
                                    <>
                                        <div className="data-list">
                                            
                                                <div className="data-list-item header">
                                                    <div className="data-list-item-content">
                                                        <div>Název dotazníku</div>
                                                        <div className="text-right">Možnosti</div>
                                                    </div>
                                                </div>
         
                                                <div>
                                                    {data.getAllQuestionnaires && data.getAllQuestionnaires.map((item,index) => {
                                                        
                                                        var checked = false;
                                                        for(let i in selectedItems){
                                                            if(selectedItems[i] == item.questionnaireID)checked = true;
                                                        }

                                                        return (
                                                            
                                                            <div key={index} className="data-list-item">
                                                                <div className="data-list-item-content categories">
                                                                    <div>
                                                                        {item.name}
                                                                    </div>
                                                                    <div className="text-right">
                                                                        <img onClick={() => OpenAddEditQuestionnaire(true,item.questionnaireID)} className="edit-icon" src={edit} /> 
                                                                        {item.questionnaireID != 1 ?
                                                                            <input className="delete-checked" type="checkbox" name="questionnairesToDelete[]" checked={checked} onChange = {(e) => AddRemoveItemToDelete(e,item.questionnaireID)} />
                                                                        :null}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                        
                                                    })}
                                                </div>
                                                            
                                            
                                        </div>
                                        {data.getAllQuestionnaires && data.getAllQuestionnaires.length == 0 &&
                                            <NoItems text={"Momentálně se zde nenachází žádné položky."} />
                                        }
                                        {showDeleteNotifi && <ModalNotification yesNo={true} text={'Opravdu chcete vymazat vybrané položky ?'} callback={DelQuestionnaire} /> }
                                        {showAddEditQuestionnaire &&
                                            <AddEditQuestionnaire listVariables={listVariables} OpenAddEditQuestionnaire={OpenAddEditQuestionnaire} selectedQuestionnaireID={selectedQuestionnaireID} />
                                        }
                                    </>
                                )
                            }
       
                        </div>
                    </div>
                </div>
            </div> 			
        </div>
    
    );
}


export default withApollo(Questionnaire);