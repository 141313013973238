import { useState } from "react";
import Notification from '../../../Library/notification';

const UseCourse = (deleteForms,client) => {

    const [selectedItems, SetSelectedItems] = useState([]);
    const [showDeleteNotifi, SetDeleteNotify] = useState(false);

    const DelCourse = (action) => {
        if(action)
        {
            let courseIDs = selectedItems.join(",");
            deleteForms({
                variables:{
                    courseIDs
                }
            }); 
        }

        SetDeleteNotify(false);
    }

    const ShowDelNotifi = () => {

        if(selectedItems.length > 0){
            SetDeleteNotify(true);
        }else{
            let notify = new Notification();
            notify.setNotification(null,'Nevybrali jste žádnou položku.',false,true,client);
        }
    }

    const AddRemoveItemToDelete = (e,courseID) => {

        var checked = e.target.checked;
        var arr = [...selectedItems];

        if(!checked){
            for(let i in selectedItems){
                if(selectedItems[i] == courseID){
                    arr.splice(i,1);
                }
            }
        }else{
            arr = [courseID,...arr];
        }
        
        SetSelectedItems(arr);
  
    }

    return{
        selectedItems,
        showDeleteNotifi,
        DelCourse,
        ShowDelNotifi,
        AddRemoveItemToDelete
    }

}

export default UseCourse;