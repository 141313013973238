/**
 * soubor se seznamem veškerých graphql dotazů pro content manager
 */
import gql from 'graphql-tag';

/**
 * upload fotky na pozadí sekce
 */
export const UPLOAD_IMAGE = gql`
    mutation UploadImage($f:Upload!,$sizes:[String],$fileName:String){
        uploadImage(f:$f,sizes:$sizes,fileName:$fileName)
    }
`;

export const UPLOAD_VIDEO = gql`
    mutation UploadVideo($f:Upload!,$fileName:String){
        uploadVideo(f:$f,fileName:$fileName)
    }
`;

export const REMOVE_IMAGE = gql`
    mutation RemoveImage($f:String!){
        removeImage(f:$f)
    }
`;
export const REMOVE_VIDEO = gql`
    mutation RemoveVideo($f:String!){
        removeVideo(f:$f)
    }
`;

export const UPDATE_CONTENT = gql`
    mutation UpdateLinkContent($content:String,$linkID:ID){
        updateLinkContent(content:$content,linkID:$linkID)
    }
`;

export const GET_CONTENT_MANAGER_DATA = gql`
    query GetContentManagerData($onlyEnabled:Boolean,$linkID:ID){
        allLanguageMutations(onlyEnabled:$onlyEnabled){
            suffix
            main
        }
        linkContent(linkID:$linkID){
            content
        }
        allForms(lang:"cz"){
            name
            formID
        }
        allServices(lang:"cz"){
            name
            serviceID
            price
            discountPrice
        }
    }
`;