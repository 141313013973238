/**
 *  komponenta pro štítky pro produkty
 */
import React,{ Component, Fragment } from 'react';
import AddEditEvent from './AddEditEvent';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import Notification from '../../../Library/notification';
import { withApollo } from 'react-apollo';
import {GET_EVENTS,DELETE_EVENTS} from '../Queries/event';
import {Query,Mutation} from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import edit from '../../../Public/Images/edit.svg';
import NoItems from '../../../GlobalComponents/NoItems';
import EventLib from '../Library/event';
import Helper from '../../../Library/helper';
import moment from 'moment';

const INITIAL_STATE = {
    showAdd : false,
    showDeleteNotifi:false,
    deleteEvent:"",
    selectedEvents:[],
    domain:"https://www.astralniakademie.cz",
    listVariables:{
        lang:"cz",
        limit:30,
        offset:0,
        onlyFuture:false
    }
}

class AllEvents extends Component{

    constructor(props){
       super(props);
       this.state = INITIAL_STATE;
       this.openCloseModal  = this.openCloseModal.bind(this);
       this.deleteEvent   = this.deleteEvent.bind(this);
       this.delEvent      = this.delEvent.bind(this);
       this.showDelNotifi   = this.showDelNotifi.bind(this);
       this.setLang         = this.setLang.bind(this);

    }

    openCloseModal(type,eventID){
        this.setState({
            showAdd:type,
            selectedEventID:eventID
        })
    }

    /**
     * smazání štítků
     * @param {*} deleteEvents funkce, která posílá data na server
     * @param {*} eventID ID eventu
     */
    
    deleteEvent(e,deleteEvents,eventID){

        var checked = e.target.checked;
        var arr = [...this.state.selectedEvents];

        if(!checked){
            for(let i in this.state.selectedEvents){
                if(this.state.selectedEvents[i] == eventID){
                    arr.splice(i,1);
                }
            }
        }else{
            arr = [eventID,...arr];
        }

        this.setState({
            deleteEvents,
            selectedEvents:arr
        });
  
    }

    delEvent(action){

        if(action){
            let eventIDs = this.state.selectedEvents.join(",");
            this.state.deleteEvents({
                variables:{
                    eventIDs: eventIDs
                }
            })
        }
        this.setState(INITIAL_STATE);
    }

    showDelNotifi(){

        if(this.state.selectedEvents.length > 0){
            this.setState({
                showDeleteNotifi:true
            });
        }else{
            let notify = new Notification();
            notify.setNotification(null,'Nevybrali jste event.',false,true,this.props.client);
        }
    }

    setLang(e){

        var data = e.target.value;
        data = data.split("-");

        this.setState({domain:data[2] + "://www." + data[1],listVariables:{...this.state.listVariables,lang:data[0]}})
    }

    render(){

        const {selectedEvents,listVariables,domain} = this.state;

        return(

            <div id="settings" className="whole-container" >
            	
                <div className="row" >
                    <div className="col-sm one">
                        <Query query={GET_EVENTS} variables={listVariables}>
                            {
                                ({data,loading,error,fetchMore }) => {
                                    
                                    if(error) return (<Error text={error} />);

                                    return(
                                    <div className="card main">
                                        
                                        <div className="card-header d-flex align-items-center">
                                            Seznam akcí  
                                            <button className="btn btn-primary btn-thiner ml-auto" onClick={() => this.openCloseModal(true)}>Přidat</button>
                                            <button className="btn btn-danger btn-thiner ml-2" onClick={() => this.showDelNotifi()}>Smazat označené</button>
                                        </div>
                                        <div className="card-body">
                                            
                                            {loading ?
                                                <Loading />
                                                
                                                :
                                            
                                                <div>
                                                    <div className="data-list">
                                                        
                                                        <div className="data-list-item header">
                                                            <div className="data-list-item-content">
                                                                
                                                                <div>Nadpis</div>
                                                                <div>Datum</div>
                                                                <div className="text-center">Aktivní</div>
                                                                <div className="text-center">Opakující se</div>
                                                                <div className="text-right">Možnosti</div>
                                                            </div>
                                                        </div>
                                                        <Mutation
                                                            mutation = {DELETE_EVENTS}
                                                            update = {async (cache, response) => {

                                                                let event = new EventLib(this);
                                                                event.updateAfterDelete(cache, response,listVariables);

                                                                let notify = new Notification();
                                                                notify.setNotification(cache,'Úspěšně smazáno',true,false);
                                            
                                                            }}
                                                            onError = {(error) => {
                                                                const helper = new Helper(); 
                                                                let notify = new Notification();
                                                                notify.setNotification(null,helper.getApolloErrorText(error),false,true,this.props.client);
                                                            }}
                                                        >
                                                            {
                                                                (deleteEvent,response) => {
                                                            
                                                                    return(
                                                                        <Fragment>
                                                                            {data.allEvents && data.allEvents.map((item,index) => {

                                                                                var checked = false;
                                                                                for(let i in selectedEvents){
                                                                                    if(selectedEvents[i] == item.eventID)checked = true;
                                                                                }
                                                                                                                                                
                                                                                return (
                                                                                
                                                                                    <div key={index} className="data-list-item-content">
                                                                                        <div>{item.title}</div>
                                                                                        <div>{moment(item.eventDate).format("DD.MM.YYYY HH:mm")}</div>
                                                                                        <div className="text-center">{(item.active == 1 ? "Ano" : "Ne")}</div>
                                                                                        <div className="text-center">{(item.recurringType != 0 ? (item.recurringType == 1 ? "každý den" : (item.recurringType == 2 ? "každý týden" : "každý měsíc")) : "Ne")}</div>
                                                                                        <div className="text-right">
                                                                                            <img onClick={() => this.openCloseModal(true,item.eventID)} className="edit-icon" src={edit} /> 
                                                                                            <input className="delete-checked" type="checkbox" name="deleteEvent[]" checked={checked} onChange = {(e) => this.deleteEvent(e,deleteEvent,item.eventID)} />
                                                                                        </div>
                                                                                    </div>
                                                                                            
                                                                                )
                                                                                
                                                                            })} 
                                                                        </Fragment>
                                                                    )
                                                                }
                                                            }
                                                        </Mutation>
                                                    </div>

                                                    {data.allEvents && data.allEvents.length == 0 ?
                                                        <NoItems text={"Momentálně se zde nenachází žádné akce."} />
                                                    :
                                                        <div className="text-center p-5">
                                                            <button className="btn btn-primary" onClick={() => fetchMore({
                                                                variables: {
                                                                    offset: data.allEvents.length
                                                                },
                                                                updateQuery: (prev, { fetchMoreResult }) => {
                                                                    if (!fetchMoreResult) return prev;
                                                                    return Object.assign({}, prev, {
                                                                        allEvents: [...prev.allEvents, ...fetchMoreResult.allEvents]
                                                                    });
                                                                }
                                                            })}> Načíst další akce </button>
                                                        </div> 

                                                    }
                                                
                                                </div>

                                            }
                                                    
                                        </div>
                                    </div>
                                );
                            }
                        }
                        </Query>
                    </div>
                </div> 	
                {this.state.showAdd &&
                    <AddEditEvent listVariables={listVariables} openCloseModal={this.openCloseModal} selectedEventID={this.state.selectedEventID} />
                }	
                {this.state.showDeleteNotifi && <ModalNotification yesNo={true} text={'Opravdu chcete vymazat vybrané akce ?'} callback={this.delEvent} /> }	
            </div>
        );

    }

}

export default withApollo(AllEvents);