import React,{ Component, Fragment } from 'react';
import {Query,Mutation} from 'react-apollo';
import {GET_ARTICLE_RECOMMENDED,DELETE_RECOMMENDED_ARTICLE,ADD_RECOMMENDED_ARTICLE,UPDATE_RECOMMENDED_ARTICLE_PRIORITY} from '../Queries/article.js';
import Loading from '../../../GlobalComponents/Loading';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import Notification from '../../../Library/notification';
import Error from '../../../GlobalComponents/Error';
import NoItems from '../../../GlobalComponents/NoItems';
import SearchInput from '../../../GlobalComponents/SearchInput';
import {ARTICLE_IMAGE_URL,SERVER_URL} from '../../../Config/index';
import { withApollo } from 'react-apollo';
import Article from '../Library/article.js';
import move from '../../../Public/Images/move.svg';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Helper from '../../../Library/helper';

const INITIAL_STATE = {
    showDeleteNotifi:false,
    deleteRecommendedArticle:"",
    articleRecommendedID:0,
    selectedRecArticles:[],
    showSearchedArticles:false,
    searchedArticles:[]
}

class RecommendedArticles extends Component{

    constructor(props){
        super(props);

        this.state = INITIAL_STATE;

    }

    componentDidMount(){
        this.article = new Article(this);
    }

    /**
     * smazání doporučených článků
     * @param {*} deleteRecommendedArticle funkce, která posílá data na server
     * @param {*} articleRecommendedID ID doporučeného článku
     */
    
    deleteItems = (e,deleteRecommendedArticle,articleRecommendedID) => {

        var checked = e.target.checked;
        var arr = [...this.state.selectedRecArticles];

        if(!checked){
            for(let i in this.state.selectedRecArticles){
                if(this.state.selectedRecArticles[i] == articleRecommendedID){
                    arr.splice(i,1);
                }
            }
        }else{
            arr = [articleRecommendedID,...arr];
        }

        
        this.setState({
            deleteRecommendedArticle,
            selectedRecArticles:arr
        });
  
    }

    delItems = (action) => {

        if(action){

            let recommendedArticleIDs = this.state.selectedRecArticles.join(",");

            this.state.deleteRecommendedArticle({
                variables:{
                    recommendedArticleIDs: recommendedArticleIDs
                }
            })
        }
        this.setState(INITIAL_STATE);
    }

    showDelNotifi = () => {

        if(this.state.selectedRecArticles.length > 0){
            this.setState({
                showDeleteNotifi:true
            });
        }else{
            let notify = new Notification();
            notify.setNotification(null,'Nevybrali jste článek.',false,true,this.props.client);
        }
    }

    searchArticle = (value) =>{
        this.article.seachArticle(value);
    }
    
    onDragEnd = (result,updateRecommendedArticlePriority) => {

        const IDs = result.draggableId.split("-");

        updateRecommendedArticlePriority({
            variables:{
                articleRecommendedID: IDs[0],
                fromIndex:result.source.index,
                toIndex:  result.destination.index
            }
        });

    }

    generateRandomRecommendedChanged = (e) => {
        const t = e.target;
        this.props.setGenerateRandomRecommendedValue(t.checked);
    }


    render(){

        const {articleID,generateRandomRecommended} = this.props;
        const {selectedRecArticles,showSearchedArticles,searchedArticles} = this.state;
        return(
            <div>
                
                <Query query={GET_ARTICLE_RECOMMENDED} variables={{articleID,lang:"cz"}} fetchPolicy = 'network-only' >
                {({data,loading,error,refetch}) => {

                    if(loading) return (<Loading />);
                    if(error) return (<Error text={error} />);
                
                    return(

                        <Mutation 
                            mutation={DELETE_RECOMMENDED_ARTICLE}
                            
                            update = {(cache, response) => {

                                let ar = new Article();
                                ar.updateAfterDeleteRecommendedArticle(cache, response,articleID);

                                let notify = new Notification();
                                notify.setNotification(cache,'Úspěšně smazáno',true,false);

                            }}
                        >   
                        {(deleteRecommendedArticle) => {    

                            return(

                                <Fragment>
                                    
                                    <div className="col-12">
                                        <div className="form-check form-group">
                                            <input className="form-check-input" type="checkbox" value="" id="generateRandomRecommended" name="generateRandomRecommended" checked={generateRandomRecommended} onChange = {(e) => this.generateRandomRecommendedChanged(e)} />
                                            <label className="form-check-label" htmlFor="generateRandomRecommended">
                                                Generovat doporučené články náhodně
                                            </label>
                                        </div>
                                    </div>
                                    {!generateRandomRecommended &&
                                        <Fragment>

                                            <div className="row form-group mb-0">
                                                <div className="col">
                                                    <SearchInput className="form-control" placeholder="Vyhledat článek" onChange = {this.searchArticle} />
                                                </div>
                                                <div className="col col-sm-2">
                                                    <button className="btn btn-danger btn-block ml-auto" onClick={() => this.showDelNotifi()}>Smazat označené</button>
                                                </div>
                                            </div>

                                            {showSearchedArticles &&

                                                <Mutation 
                                                    mutation={ADD_RECOMMENDED_ARTICLE}

                                                    update = {(cache, response) => {

                                                        let ar = new Article();
                                                        ar.updateAfterAddRRecommendedArticle(cache, response,articleID);

                                                        let notify = new Notification();
                                                        notify.setNotification(cache,'Úspěšně přidáno',true,false);

                                                        this.setState({
                                                            showSearchedArticles:false,
                                                            searchedArticles:[]
                                                        })

                                                    }}
                                                >   
                                                    {(addRecommendedArticle) => {  
                                                        return(
                                                            <div className="form-group">
                                                                <div className="row">
                                                                    <div className="col-10">
                                                                        <div className="data-list">                                                                

                                                                            {searchedArticles && searchedArticles.map((item,index) => (

                                                                                <div key={index} className="data-list-item-content">
                                                                                    <div>
                                                                                        {item.photo && 
                                                                                            <img className="article-list-img mr-2" alt={item.title} src={SERVER_URL + "/" + ARTICLE_IMAGE_URL + "/mala_" + item.photo} />
                                                                                        }
                                                                                        {item.title}
                                                                                    </div>
                                                                                    <div className="text-right">
                                                                                        <button className="btn btn-primary btn-thiner ml-auto" onClick={() => addRecommendedArticle({variables:{recommendedArticleID: item.articleID, articleID}})} >Přidat</button>
                                                                                    </div>
                                                                                </div>

                                                                            ))}

                                                                            {searchedArticles && searchedArticles.length == 0 &&
                                                                                <NoItems text={"Nebyl nalezen žádný vyhovující článek."} />
                                                                            } 

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }}
                                                </Mutation>
                                            }
        
                                            <div className="data-list mt-2">

                                                <div className="data-list-item header">
                                                    <div className="data-list-item-content">
                                                        <div>Název článku</div>
                                                        <div className="text-right">Možnosti</div>
                                                    </div>
                                                </div>

                                                <Mutation 
                                                    mutation={UPDATE_RECOMMENDED_ARTICLE_PRIORITY} 
                                                    update={() => {
                                                        refetch();
                                                    }}
                                                >
                                                {
                                                    (updateRecommendedArticlePriority,{error}) => {

                                                        if(error){
                                                            const helper = new Helper(); 
                                                            return (<Error text={helper.getApolloErrorText(error)} />);
                                                        }

                                                        return (
                                                            <DragDropContext onDragEnd={(result) => this.onDragEnd(result,updateRecommendedArticlePriority)}>
                                                                <Droppable droppableId="droppable">
                                                                    {(provided, snapshot) => (
                                                                        <div
                                                                            {...provided.droppableProps}
                                                                            ref={provided.innerRef}
                                                                        >
                                                                            {data.recommendedArticles && data.recommendedArticles.map((item,index) => {

                                                                                var checked = false;
                                                                                for(let i in selectedRecArticles){
                                                                                    if(selectedRecArticles[i] == item.articleRecommendedID)checked = true;
                                                                                }

                                                                                return(
                                                                                    <Draggable key={index} draggableId={item.articleRecommendedID} index={index}>
                                                                                        {(provided, snapshot) => (
                                                                                        <div className="data-list-item" 
                                                                                            ref={provided.innerRef}
                                                                                            {...provided.draggableProps}
                                                                                            {...provided.dragHandleProps}
                                                                                        >
                                                                                            <div key={index} className="data-list-item-content">
                                                                                                <div>
                                                                                                    
                                                                                                    <img className="move-icon" src={move} />
                                                                                                    {item.article.photo && 
                                                                                                        <img className="article-list-img mr-2" alt={item.article.title} src={SERVER_URL + "/" + ARTICLE_IMAGE_URL + "/mala_" + item.article.photo} />
                                                                                                    }
                                                                                                    {item.article.title}
                                                                                                </div>
                                                                                                <div className="text-right">

                                                                                                    <input className="delete-checked" type="checkbox" name="deleteRecommendedArticle[]" checked={checked} onChange = {(e) => this.deleteItems(e,deleteRecommendedArticle,item.articleRecommendedID)} />
                                                                                                    
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        )}
                                                                                    </Draggable>
                                                                                )
                                                                            })}  
                                                                            
                                                                            {provided.placeholder}
                                                                        </div>
                                                                    )}
                                                                </Droppable>
                                                            </DragDropContext>
                                                        )
                                                }}
                                                </Mutation>

                                                {data.recommendedArticles && data.recommendedArticles.length == 0 &&
                                                    <NoItems text={"Momentálně se zde nenachází žádné doporučené články."} />
                                                }       

                                            </div>

                                            {this.state.showDeleteNotifi && <ModalNotification yesNo={true} text={'Opravdu chcete odstranit vybrané články ?'} callback={this.delItems} /> }
                                        
                                        </Fragment>

                                    }
                                </Fragment>
                                    
                                
                            )   
                            
                        }}
                        </Mutation>
                          
                    )

                }}

                </Query>

            </div>
        );

    }
}

export default withApollo(RecommendedArticles);
