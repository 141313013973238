/**
 * Třía která obsahuje veškeré pomocné funkce, pro práci s přidáváním a editací stavů objednávek
 */

import Notification from '../../../Library/notification';
import {GET_MEMBERSHIPS, GET_MEMBERSHIP} from '../Queries/index.js';

class Membership {

    constructor(parent){
        this.parent = parent;
    }

    /**
     * Přidání/Editace videa
     * @param {*} addMembership funkce z apollo, pomocí které se posílají data na server
     */

    addMembership = (addMembership) => {

        const data = this.parent.state.formData;
             
        let langs = [];
        for(const lang of data.langs){
            langs.push({
                emailText: lang.emailText,
                lang:      lang.lang
            });
        }

        addMembership({
            variables:{
                membershipDurationID: data.membershipDurationID,
                thanksLinkID:         data.thanksLinkID,
                langs
            }
        });
                
    } 


    /**
     * 
     * @param {*} cache apollo cache, kde jsou uložena data
     * @param {*} response hodnota, která se vrátila po smazání objednávky 
     */

    updateAfterDelete(cache, response){

        var resp = response.data.deleteMemberships.split(",");

        const { allMemberships } = cache.readQuery({ query: GET_MEMBERSHIPS ,variables:{lang:'cz'}});
        var arr = [...allMemberships];
        
        resp.forEach((it,ind) => {
            arr.forEach((item,index) => {
                if(item.membershipDurationID == it){
                    arr = [...arr.slice(0,index),...arr.slice(index + 1)]
                }
            });
        }) 
        
        cache.writeQuery({ 
            query:GET_MEMBERSHIPS,
            variables:{lang:'cz'},
            data:{
                allMemberships: [...arr]
            } 
        });

    }
    
    /**
     * Při vložení nebo úpravě zákazníka dojde k update cache a vykreslí se opět seznam veškerých zákazníků
     * @param {Object} cache apollo cahe v které jsou oložena data
     * @param {Object} response položka, která byla vložena nebo upravována
     * @param {Number} selectedID id položky
     * @param {Object} variables parametry posílané do query
     */

    updateList(cache,response,selectedID,variables){

        const { allMemberships } = cache.readQuery({ query: GET_MEMBERSHIPS,variables:variables });
        
        if(!(selectedID && selectedID != 0)){

            cache.writeQuery({ 
                query:GET_MEMBERSHIPS,
                variables:variables,
                data:{
                    allMemberships: [...allMemberships,response.data.addEditMembership]
                } 
            });

        }else{

            var arr = [...allMemberships];
            
            arr.forEach((item,index) => {
                
                if(item.membershipDurationID == response.data.addEditMembership.membershipDurationID){
                    arr[index].membershipDurationID = response.data.addEditMembership.membershipDurationID;
                }

            });
            cache.writeQuery({ 
                query:GET_MEMBERSHIPS,
                variables:variables,
                data:{
                    allMemberships: arr
                } 
            });
        }
    }
    
    /**
     * při inicializaci přidání kategorie nebo její úpravy vytáhne potřebná data k dopravě
     */

    async getData(){

        var membershipDurationID = 0;
        if(this.parent.props.selectedMembershipID)membershipDurationID = this.parent.props.selectedMembershipID;
        
        var data = await this.parent.props.client.query({ 
            query: GET_MEMBERSHIP,
            errorPolicy:"all",
            variables:{membershipDurationID:membershipDurationID}, 
            fetchPolicy: 'network-only'
        });      
        
        if(data.errors){
            this.parent.setState({apolloError:data.errors[0].message});
        }
        
        //kdyz upravujeme zaznam
        if(data.data){                  
            if(data.data.membershipWithLangs){    
                let langs = this.completeLangsArray(data.data.membershipWithLangs.langs,data.data.allLanguageMutations);     
                this.parent.setState({
                    apolloError:"",
                    selectedMembershipID:membershipDurationID,
                    allLanguageMutations: data.data.allLanguageMutations,
                    allLinks:data.data.allLinks,
                    formData:{...this.parent.state.formData,
                        membershipDurationID:     data.data.membershipWithLangs.membershipDurationID,
                        thanksLinkID:             data.data.membershipWithLangs.thanksLinkID,
                        langs:langs
                    }
                });
            }else{
                this.parent.setState({ apolloError:""});
            }
        }

        //kdyz pridavame zaznam
        if(membershipDurationID == 0){
            let langs = this.completeLangsArray([],data.data.allLanguageMutations);            
            
            this.parent.setState({
                allLanguageMutations: data.data.allLanguageMutations,
                allLinks:data.data.allLinks,
                formData:{...this.parent.state.formData,
                    langs:langs
                }
            });
        }

    }

    completeLangsArray = (langsFromDb,allActiveLanguages) => {
        let langs = langsFromDb;
        for(const l of allActiveLanguages){
            let langSet = false;
            for(const lang of langs){
                if(l.suffix == lang.lang){
                    langSet = true;
                    break;
                }
            }

            if(!langSet){
                langs.push({
                    lang:l.suffix,
                    emailText:"",
                });
            }
        }
        return langs;
    }

    /**
     * funkce ukládá data, která byla vložena do formuláře
     * @param {*} e ukazatel na element
     */
    
    formHandle(e){
	  
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        this.parent.setState({formData:{...this.parent.state.formData,[n]: v}});
              
    }
    /**
     * funkce ukládá data po zmene jazykove mutace ve formulari, která byla vložena do formuláře
     * @param {*} e ukazatel na element
     */
    
    formLangObjectHandle(e){
	  
        const t = e.target;
        const v = t.value;
        
        for(const lang of this.parent.state.allLanguageMutations){
            if(lang.languageID == v){
                this.parent.setState({formData:{...this.parent.state.formData,selectedLang: lang.suffix,selectedCurrency:lang.currencyTitle}});
            }
        }

              
    }
    
    /**
     * funkce ukládá data (jazykove mutace), která byla vložena do formuláře
     * @param {*} e ukazatel na element
     */
    
    formLangHandle(e){
        
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        let langs       = this.parent.state.formData.langs;
        for(const lang of langs){
            if(lang.lang == this.parent.state.formData.selectedLang){
                lang[n] = v;
            }
        }

        this.parent.setState({...this.parent.state,formData:{...this.parent.state.formData,langs: langs}});
         
    }

    /**
     * funkce ukládá data (jazykove mutace), která byla vložena do formuláře přes tinyMCE editor
     * @param {String} data data tinyMCE editoru
     * @param {String} paramName nazev parametru, kteremu nastavujeme textovou hodnotu
     */
    
    formLangHandleEditor(content,paramName){
        
        let langs       = this.parent.state.formData.langs;

        for(const lang of langs){
            if(lang.lang == this.parent.state.formData.selectedLang){
                lang[paramName] = content;
            }
        }

        this.parent.setState({...this.parent.state,formData:{...this.parent.state.formData,langs: langs}});
    }
    
    /**
     * Vraci hodnotu zadaneho parametru z jazykovych mutaci, podle zadaneho jazyku
     * @param {String} propertyName - název proměnné v objektu lang
     * 
     * @returns property value
     */
    getLangValue = (propertyName) => {
        for(const lang of this.parent.state.formData.langs){
            if(lang.lang == this.parent.state.formData.selectedLang){
                return lang[propertyName];
            }
        }
        return "";
    }
}

export default Membership;