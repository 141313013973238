/*
    Výběr veškerých kategorií
*/
import React,{ Component } from 'react';
import {Query,Mutation} from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import NoItems from '../../../GlobalComponents/NoItems';
import {GET_EVS_MONTH_All_DATA,DELETE_EVS_MONTH_DATA} from '../Queries/eliteVS.js';
import AddEditEliteVSMonthData from './AddEditEliteVSMonthData';
import Helper from '../../../Library/helper';
import edit from '../../../Public/Images/edit.svg';
import Notification from '../../../Library/notification';
import EliteVSLib from '../Library/eliteVS';
import { withApollo } from 'react-apollo';

const INITIAL_STATE = {
    showDeleteCategoryNotifi : false,
    showDoNotDelete:false,
    showAddCategory:false,
    selectedEvsMonthDataID:0,
    evsMonthDataID:0,
    deleteCat:null,
    selectedItems:[],
    showDeleteNotifi:false,
    deleteEvsMonthData:null,
    listVariables:{
        lang:"cz"
    }

}

class EliteVSMonthData extends Component{


    constructor(props){
        super(props);
        this.state = INITIAL_STATE;
    }


    delCategory = (action) => {
        if(action){
            let evsMonthDataIDs = this.state.selectedItems.join(",");
            this.state.deleteEvsMonthData({
                variables:{
                    evsMonthDataIDs
                }
            });
            
            this.setState(INITIAL_STATE);
        }else{
            this.setState({
                showDeleteNotifi:false
            });
        }
    }

    showDoNotDel = (action) => {
        this.setState({
            showDoNotDelete:action
        });
    }

    openCloseModal = (type,evsMonthDataID) => {
        this.setState({
            showAddCategory:type,
            selectedEvsMonthDataID:evsMonthDataID
        })
    }
    
    /**
     * smazání kategorie
     * @param {*} deleteEvsMonthData funkce, která posílá data na server
     * @param {*} evsMonthDataID ID kategorie
     */
    
    checkItemToDelete = (e,deleteEvsMonthData,evsMonthDataID) => {

        var checked = e.target.checked;
        var arr = [...this.state.selectedItems];

        if(!checked){
            for(let i in this.state.selectedItems){
                if(this.state.selectedItems[i] == evsMonthDataID){
                    arr.splice(i,1);
                }
            }
        }else{
            arr = [evsMonthDataID,...arr];
        }
        
        this.setState({
            deleteEvsMonthData,
            selectedItems:arr
        });
  
    }
    
    openCloseAddCategoryModal = (type) => {
        this.setState({
            showAddCategory:type,
        })
    }
    
    showDelNotifi = () => {

        if(this.state.selectedItems.length > 0){
            this.setState({
                showDeleteNotifi:true
            });
        }else{
            let notify = new Notification();
            notify.setNotification(null,'Nevybrali jste žádnou položku.',false,true,this.props.client);
        }
    }

    getMonthString(month){

        var m = "";
        switch(month){
            case 1: m = "Leden"; break;
            case 2: m = "Únor"; break;
            case 3: m = "Březen"; break;
            case 4: m = "Duben"; break;
            case 5: m = "Květen"; break;
            case 6: m = "Červen"; break;
            case 7: m = "Červenec"; break;
            case 8: m = "Srpen"; break;
            case 9: m = "Září"; break;
            case 10: m = "Říjen"; break;
            case 11: m = "Listopad"; break;
            case 12: m = "Prosinec"; break;
        }

        return m;
    }

    render(){
        
        const {selectedItems,listVariables,selectedEvsMonthDataID} = this.state;

        return(
            
            	
            <div className="row" >
                <div className="col-sm one">
                    <div className="card main">
                        
                        <div className="card-header d-flex align-items-center">
                            Data pro jednotlivé měsíce
                            <button className="btn btn-primary btn-thiner ml-auto" onClick={() => this.openCloseModal(true,0)}>Přidat</button>
                            <button className="btn btn-danger btn-thiner ml-2" onClick={() => this.showDelNotifi()}>Smazat označené</button>
                        </div>
                        <div className="card-body">
                            <Query query={GET_EVS_MONTH_All_DATA} errorPolicy="all" variables={listVariables}>
                                {
                                    ({data,loading,error,refetch }) => {
                                        if(error){
                                            const helper = new Helper(); 
                                            return (<Error text={helper.getApolloErrorText(error)} />);
                                        }
                                        if(loading) return (<Loading />);
                                        
                                        return(
                                            <div>
                                                <div className="data-list">
                                                    
                                                        <div className="data-list-item header">
                                                            <div className="data-list-item-content">
                                                                <div>Měsíc a rok</div>
                                                                <div className="text-right">Možnosti</div>
                                                            </div>
                                                        </div>

                                                        <Mutation
                                                            mutation = {DELETE_EVS_MONTH_DATA}
                                                            update = {async (cache, response) => {
                                                                
                                                                refetch();

                                                                let notify = new Notification();
                                                                notify.setNotification(cache,'Úspěšně smazáno',true,false);
                                            
                                                            }}
                                                            onError = {(error) => {
                                                                const helper = new Helper(); 
                                                                let notify = new Notification();
                                                                notify.setNotification(null,helper.getApolloErrorText(error),false,true,this.props.client);
                                                            }}
                                                        >
                                                            {
                                                                (deleteEvsMonthData,response) => {
                                                            
                                                                    return(
                                                                        
                                                                        <div>
                                                                            {data.evsMonthData && data.evsMonthData.map((item,index) => {
                                                                                
                                                                                if(item.evsMonthDataID){
                                                                                    
                                                                                    var checked = false;
                                                                                    for(let i in selectedItems){
                                                                                        if(selectedItems[i] == item.evsMonthDataID)checked = true;
                                                                                    }

                                                                                    return (
                                                                                        
                                                                                        <div key={index} className="data-list-item">
                                                                                            <div className="data-list-item-content categories">
                                                                                                <div>{this.getMonthString(item.month)} {item.year}</div>
                                                                                                <div className="text-right">
                                                                                                    <img onClick={() => this.openCloseModal(true,item.evsMonthDataID)} className="edit-icon" src={edit} /> 
                                                                                                    <input className="delete-checked" type="checkbox" name="servicesToDelete[]" checked={checked} onChange = {(e) => this.checkItemToDelete(e,deleteEvsMonthData,item.evsMonthDataID)} />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                }else{
                                                                                    return "";
                                                                                }
                                                                            })}
                                                                        </div>
                                                                    )
                                                                }
                                                            }
                                                        </Mutation>
                                                    
                                                </div>
                                                {data.evsMonthData && data.evsMonthData.length == 0 &&
                                                    <NoItems text={"Momentálně se zde nenachází žádné položky."} />
                                                }
                                                {this.state.showDeleteNotifi && <ModalNotification yesNo={true} text={'Opravdu chcete vymazat vybrané položky ?'} callback={this.delCategory} /> }
                                                {this.state.showAddCategory &&
                                                    <AddEditEliteVSMonthData refetch={refetch} listVariables={listVariables} openCloseModal={this.openCloseModal} selectedEvsMonthDataID={this.state.selectedEvsMonthDataID} />
                                                }
                                            </div>
                                        );
                                    }
                                }
                            </Query>
                        </div>
                    </div>
                </div>
            </div> 			
            
        
        );

    }

}


export default withApollo(EliteVSMonthData);