/**
 * soubor se seznamem veškerých graphql dotazů pro kategorie
 */
import gql from 'graphql-tag';

/**
 * vložení kategorie
 */
export const ADD_EDIT_COURSE = gql`
    mutation AddEditCourse($courseID:ID,$guideID:ID,$emailHeaderTypeID:ID,$questionnaireID:ID,$name:String,$langs:[CourseLangsInput]){
        addEditCourse(courseID:$courseID,guideID:$guideID, emailHeaderTypeID:$emailHeaderTypeID, questionnaireID:$questionnaireID, name:$name, langs:$langs){
            courseID
            name
        }
    }
`;

/**
 *  Data pro jednu kategorii
 */

export const GET_COURSE = gql`
    query CourseWithLangs($courseID: ID!){
        courseWithLangs(courseID:$courseID){
            courseID
            guideID
            emailHeaderTypeID
            questionnaireID
            name
            langs{
                lang
                regEmail
                emailForVs
                successMessage
                freeWeekEmail
                cancelFreeWeekEmail
            }    
        }
        allLanguageMutations(onlyEnabled:true){
            languageID
            title
            suffix
            file
            generateNiceTitle
            langTitle
            decimal
            priority
            main
            currencyTitle
        }
        allGuides{
            customerID
            astralName
        }
        getAllQuestionnaires(lang:"cz"){
            questionnaireID
            name
        }
    }
`;

/**
 *  Seznam veškerých kategorií
 */
export const GET_COURSES = gql`
    query AllCourses($lang: String!){
        allCourses(lang: $lang){
            courseID
            name
        }
    }
`;

export const DELETE_COURSES = gql`
    mutation deleteCourse($courseIDs:ID!) {
        deleteCourses(courseIDs:$courseIDs)
    }
`;
