import React,{ Component, Fragment } from 'react';
import { Mutation, Query} from 'react-apollo'
import {SERVER_URL,EVS_MONTH_DATA_IMAGE_URL} from '../../../Config/index';
import Notification from '../../../Library/notification';
import {GET_EVS_MONTH_DATA_IMAGES,UPLOAD_EVS_MONTH_DATA_IMAGES,DELETE_EVS_MONTH_DATA_IMAGE} from '../Queries/eliteVS.js';
import EliteVSMonthDataLib from '../Library/eliteVSMonthData';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import ModalNotification from '../../../GlobalComponents/ModalNotification';

const INITIAL_STATE = {
    selectedImages: [],
    showDeleteInfo:false,
    deleteEvsMonthDataImage:null,
    showEditImage:false,
    imagePath:"",
    filename:"",
    evsMonthDataImageID:0,
    imageHash:"",
    loading:false
}

class EliteVSMonthDataImages extends Component{

    constructor(props){
        super(props);

        this.state               = INITIAL_STATE;
        this.setDeleteImage      = this.setDeleteImage.bind(this);
        this.deleteEvsMonthDataImage = this.deleteEvsMonthDataImage.bind(this);
        this.delProductImage     = this.delProductImage.bind(this);
        this.changeImages        = this.changeImages.bind(this);
    }

    setDeleteImage(e,evsMonthDataImageID){

        if(e.target.checked){
            this.setState({
                selectedImages:[evsMonthDataImageID,...this.state.selectedImages]
            });
        }else{
            
            var arr = [...this.state.selectedImages];
            for(let i in arr){
                if(arr[i] == evsMonthDataImageID)arr.splice(i,1);
            }

            this.setState({
                selectedImages:arr
            });
        }

    }

    deleteEvsMonthDataImage(deleteEvsMonthDataImage){
        this.setState({
            showDeleteInfo:true,
            deleteEvsMonthDataImage
        })
    }

    delProductImage(action){
        if(action){
            this.state.deleteEvsMonthDataImage({
                variables:{
                    evsMonthDataImagesID: this.state.selectedImages,
                    evsMonthDataID: this.props.selectedEvsMonthDataID
                }
            })
        }
        this.setState(INITIAL_STATE);
    }

    changeImages(){
        this.setState({
            showEditImage:false,
            imageHash: Math.floor(Math.random() * 100000)
        });
    }

    render(){

        const {images,selectedEvsMonthDataID} = this.props;
        const {showDeleteInfo,showEditImage,imagePath, filename,evsMonthDataImageID,imageHash} = this.state;


        return(
            <div>
                
                <Query query={GET_EVS_MONTH_DATA_IMAGES} variables={{evsMonthDataID:selectedEvsMonthDataID}} >
                {({data,loading,error}) => {

                    if(loading) return (<Loading />);
                    if(error) return (<Error text={error} />);
                
                    return(

                        <Mutation 
                            mutation={DELETE_EVS_MONTH_DATA_IMAGE}
                            update = {async (cache, response) => {

                                let evsMonthDataLib = new EliteVSMonthDataLib();
                                evsMonthDataLib.updateUploadedImagesAfterDelete(cache, response,selectedEvsMonthDataID);
        
                                let notify = new Notification();
                                notify.setNotification(cache,'Úspěšně smazáno',true,false);
            
                            }}
                        >
                        {deleteEvsMonthDataImage => (

                            <Fragment>    
                                <Mutation 
                                    mutation={UPLOAD_EVS_MONTH_DATA_IMAGES}
                                    update = {async (cache, response) => {

                                        let evsMonthDataLib = new EliteVSMonthDataLib();
                                        evsMonthDataLib.updateUploadedImages(cache, response,selectedEvsMonthDataID);
                
                                        let notify = new Notification();
                                        notify.setNotification(cache,'Úspěšně nahráno',true,false);
                    
                                    }}
                                >
                                    {(uploadProductPhotos,{loading}) => (

                                        <Fragment>
                                            {loading ?
                                            
                                                <Loading />
                                            :
                                            
                                            <Fragment>

                                                <div className="form-group input-group">
                                                    <div className="custom-file">
                                                        
                                                        <input
                                                            type="file"
                                                            className="custom-file-input"
                                                            id="customFile"
                                                            required
                                                            multiple
                                                            onChange={
                                                                ({ target: { validity, files: files } }) => {
                                                                    validity.valid && uploadProductPhotos({ variables: { files:files,evsMonthDataID:selectedEvsMonthDataID } });
                                                                }   
                                                            }
                                                        />
                                                        <label className="custom-file-label" htmlFor="customFile">Vyberte fotky</label>
                                                    </div>
                                                    <div className="input-group-append">
                                                        <button className="btn btn-danger btn-delete-files" type="button" onClick={() => this.deleteEvsMonthDataImage(deleteEvsMonthDataImage)}>Odstranit označené fotky</button>
                                                    </div>
                                                </div>

                                                <div className="row">

                                                {data.evsMonthDataImages && data.evsMonthDataImages.map((item,index) => {

                                                    return(
                                                        <div key={index} className="col-3 form-group">
                                                            <div className="cover-item">
                                                                <div className="menu-item">
                                                                    <input type="checkbox" name="delete" onChange={(e) => this.setDeleteImage(e,item.evsMonthDataImageID)} />
                                                                </div>
                                                                <div className="text-center"> 
                                                                    <img src={SERVER_URL + "/" + EVS_MONTH_DATA_IMAGE_URL + "/id-" + selectedEvsMonthDataID + "/mala_" + item.photo + "?" + imageHash} />
                                                                </div>
                                                                
                                                            </div>
                                                        </div>
                                                    )
                                                })}         

                                                </div>

                                                {showDeleteInfo && <ModalNotification yesNo={true} text={'Opravdu chcete vymazat vybrané obrázky ?'} callback={this.delProductImage} />}

                                            </Fragment>
                                            }
                                        </Fragment>

                                    )}

                                    
                                </Mutation>

                                

                            </Fragment>
                        )}  
                        </Mutation>
                        
                    )

                }}

                </Query>

            </div>
        );

    }
}

export default EliteVSMonthDataImages;
