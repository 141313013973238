import React,{ Component,Fragment } from 'react';
import HeadlineElement from './HeadlineElement';
import ColElement from './ColumnsElement';
import AddElement from './AddElement';
import ParagraphElement from './ParagraphElement';
import ImageElement from './ImageElement';
import ButtonElement from './ButtonElement';
import VideoElement from './VideoElement';
import ArticlesElement from './ArticlesElement';
import NewsletterElement from './NewsletterElement'; 
import HtmlElement from './HtmlElement';
import FaqElement from './FaqElement';
import FormElement from './FormElement';
import ServiceElement from './ServiceElement';
import AlertElement from './AlertElement';
import remove from '../../../Public/Images/remove.svg';
import parent from '../../../Public/Images/parent.svg';
import settings from '../../../Public/Images/settings.svg';
import move from '../../../Public/Images/move.svg';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';


class ColumnsElement extends Component{

    constructor(props){
        super(props);
    }

    getColumnClass(type,value){

        var className = "col"+type;
        switch(value){
            case "1": className += "-12";break;
            case "2": className += "-6";break;
            case "3": className += "-4";break;
            case "4": className += "-3";break;
            case "6": className += "-2";break;
        }

        return className;

    }

    render(){

        const {element,lastIndexes,cm,showHelpers,iframeNode,fullWidth,lang} = this.props;

        var parentIndexes = "";
        
        var indexes = lastIndexes.split("-");
        if(indexes.length > 2){
            indexes.pop();
            parentIndexes = indexes.join("-");
        }

        return (
            <div className="cm-columns row">

                {showHelpers && element.showMenu &&
                    <div className="cm-menu cm-col" onClick={(e) => e.stopPropagation()}>
                        <div className="form-inline">
                            {element.cols.length == 0 && 
                                <div className="form-group mr-1">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <label className="input-group-text" htmlFor="inputGroupSelect02">Sloupců v 1 řádku:</label>
                                        </div>
                                        <select className="form-control" name="section-padding" onChange={(e) => cm.setColumnsColNumber(e,lastIndexes)}>
                                            <option value="0"> -- </option>
                                            <option value="1"> 1 </option>
                                            <option value="2"> 2 </option>
                                            <option value="3"> 3 </option>
                                            <option value="4"> 4 </option>
                                            <option value="5"> 5 </option>
                                            <option value="6"> 6 </option>
                                        </select>
                                    </div>
                                </div> 
                            }

                            <div className="form-group mr-1">
                                <div className="item-container" onClick={() => cm.showSettings("columns",lastIndexes)}>
                                    <img className="remove-icon" src={settings} alt="Nastavení modulu" title="Nastavení modulu" />
                                </div>
                                {element.showSettings &&
                                    <Fragment>
                                        <div className="settings">
                                                                                        
                                            <div className="mb-1">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <label className="input-group-text">Třída:</label>
                                                    </div>
                                                    <input type="text" value={element.className} className="form-control" name="className" onChange={(e) => cm.setElementData(e,lastIndexes,"columns")}  />
                                                </div>
                                            </div>
                                            <div className="mb-1">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <label className="input-group-text">Počet sloupců v rámci šířky displaye</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-1">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <label className="input-group-text">větší než 1200</label>
                                                    </div>
                                                    <input type="text" value={element.xlCols} className="form-control" name="xlCols" onChange={(e) => cm.setElementData(e,lastIndexes,"columns")}  />
                                                </div>
                                            </div>
                                            <div className="mb-1">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <label className="input-group-text">992 - 1199</label>
                                                    </div>
                                                    <input type="text" value={element.lgCols} className="form-control" name="lgCols" onChange={(e) => cm.setElementData(e,lastIndexes,"columns")}  />
                                                </div>
                                            </div>
                                            <div className="mb-1">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <label className="input-group-text">768 - 991</label>
                                                    </div>
                                                    <input type="text" value={element.mdCols} className="form-control" name="mdCols" onChange={(e) => cm.setElementData(e,lastIndexes,"columns")}  />
                                                </div>
                                            </div>
                                            <div className="mb-1">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <label className="input-group-text">577 - 767</label>
                                                    </div>
                                                    <input type="text" value={element.smCols} className="form-control" name="smCols" onChange={(e) => cm.setElementData(e,lastIndexes,"columns")}  />
                                                </div>
                                            </div>
                                            <div className="mb-1">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <label className="input-group-text">menší než 576</label>
                                                    </div>
                                                    <input type="text" value={element.xsCols} className="form-control" name="xsCols" onChange={(e) => cm.setElementData(e,lastIndexes,"columns")}  />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="cm-closing-overlay" onClick={() => cm.showSettings("columns",lastIndexes)}></div>
                                    </Fragment>
                                }
                            </div>
                            {parentIndexes != "" &&
                                <div className="form-group">
                                    <div className="item-container" onClick={(e) => cm.selectElement(e,"column",parentIndexes)}>
                                        <img className="remove-icon" src={parent} alt="Nadřazený element" title="Nadřazený element" />
                                    </div>
                                </div>
                            }
                            <div className="form-group">
                                <div className="item-container" onClick={() => cm.removeElement(lastIndexes)}>
                                    <img className="remove-icon" src={remove} alt="Odstranit sloupce" title="Odstranit sloupce" />
                                </div>
                            </div>
                            
                        </div>
                    </div>
                }

                <div className={(fullWidth == 1 ? "container-fluid"  : "container")}>
                    <div className={"row " + element.className}>
                        {element.cols.length > 0 && element.cols.map((item,index) => {
                            
                            var colClasses = "";
                            if(element.xlCols)colClasses += this.getColumnClass("-xl", element.xlCols) + " ";
                            if(element.lgCols)colClasses += this.getColumnClass("-lg", element.lgCols) + " ";
                            if(element.mdCols)colClasses += this.getColumnClass("-md", element.mdCols) + " ";
                            if(element.smCols)colClasses += this.getColumnClass("-sm", element.smCols) + " ";
                            if(element.xsCols)colClasses += this.getColumnClass("", element.xsCols) + " ";
                            
                            return(
                            <div key={index} className={"col cm-column " + colClasses + " " + item.className} rel={index} >
                                {showHelpers && item.showMenu &&
                                    <div className="cm-menu cm-col" onClick={(e) => e.stopPropagation()}>
                                        <div className="form-inline">
                                            <div className="form-group mr-1">
                                                <AddElement columnType="row" lastIndexes = {lastIndexes + "-" + index} element={item} cm={cm}  />
                                            </div>
                                            <div className="form-group mr-1">
                                                <div className="item-container" onClick={() => cm.showSettings("column",lastIndexes + "-" + index)}>
                                                    <img className="remove-icon" src={settings} alt="Nastavení modulu" title="Nastavení modulu" />
                                                </div>
                                                {item.showSettings &&
                                                    <Fragment>
                                                        <div className="settings">
                                                            
                                                            <div className="mb-1">
                                                                <div className="input-group">
                                                                    <div className="input-group-prepend">
                                                                        <label className="input-group-text" htmlFor="inputGroupSelect02">Zarovnání:</label>
                                                                    </div>
                                                                    <select value={item.contentAlign} className="form-control" name="contentAlign" onChange={(e) => cm.setElementData(e,lastIndexes + "-" + index,"column")}>
                                                                        <option value="start"> nahoře </option>
                                                                        <option value="center"> centrovaně </option>
                                                                        <option value="end"> dole </option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="mb-1">
                                                                <div className="input-group">
                                                                    <div className="input-group-prepend">
                                                                        <label className="input-group-text">Odsazení zhora:</label>
                                                                    </div>
                                                                    <input type="number" value={item.paddingTop} className="form-control" name="paddingTop" onChange={(e) => cm.setElementData(e,lastIndexes + "-" + index,"column")}  />
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="mb-1">
                                                                <div className="input-group">
                                                                    <div className="input-group-prepend">
                                                                        <label className="input-group-text">Odsazení zprava:</label>
                                                                    </div>
                                                                    <input type="number" value={item.paddingRight} className="form-control" name="paddingRight" onChange={(e) => cm.setElementData(e,lastIndexes + "-" + index,"column")}  />
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="mb-1">
                                                                <div className="input-group">
                                                                    <div className="input-group-prepend">
                                                                        <label className="input-group-text">Odsazení zespod:</label>
                                                                    </div>
                                                                    <input type="number" value={item.paddingBottom} className="form-control" name="paddingBottom" onChange={(e) => cm.setElementData(e,lastIndexes + "-" + index,"column")}  />
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="mb-1">
                                                                <div className="input-group">
                                                                    <div className="input-group-prepend">
                                                                        <label className="input-group-text">Odsazení zleva:</label>
                                                                    </div>
                                                                    <input type="number" value={item.paddingLeft} className="form-control" name="paddingLeft" onChange={(e) => cm.setElementData(e,lastIndexes + "-" + index,"column")}  />
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="mb-1">
                                                                <div className="input-group">
                                                                    <div className="input-group-prepend">
                                                                        <label className="input-group-text">Třída:</label>
                                                                    </div>
                                                                    <input type="text" value={item.className} className="form-control" name="className" onChange={(e) => cm.setElementData(e,lastIndexes + "-" + index,"column")}  />
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="cm-closing-overlay" onClick={() => cm.showSettings("column",lastIndexes + "-" + index)}></div>
                                                    </Fragment>
                                                }
                                            </div>
                                            <div className="form-group">
                                                <div className="item-container" onClick={(e) => cm.selectElement(e,"columns",lastIndexes)}>
                                                    <img className="remove-icon" src={parent} alt="Nadřazený element" title="Nadřazený element" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className={(item.contentAlign != "start"  ? "cm-col-content h-100 d-flex flex-column justify-content-" + item.contentAlign : "cm-col-content")} onClick={(e) => !element.showMenu && cm.selectElement(e,"column",lastIndexes + "-" + index)} style={(item.paddingTop != 0 || item.paddingRight != 0 || item.paddingBottom != 0 || item.paddingLeft != 0 ? {paddingTop:item.paddingTop+"px",paddingRight:item.paddingRight+"px",paddingBottom:item.paddingBottom+"px",paddingLeft:item.paddingLeft+"px"} : {})}>
                                   
                                    <DragDropContext onDragEnd={(result) => cm.onDragEnd(result,lastIndexes + "-" + index)}>
                                        <Droppable droppableId="droppable">
                                            {(provided, snapshot) => (
                                                <div
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                    
                                                >
                                                    {item.elements && item.elements.map((elmItem,elmIndex) => {

                                                        return(
                                                            <Draggable key={elmIndex} draggableId={elmIndex+1} index={elmIndex}>
                                                                {(provided, snapshot) => (
                                                                <div  
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    
                                                                >
                                                                    
                                                                    {elmItem.headline &&
                                                                        <HeadlineElement handle = {provided.dragHandleProps} lang={lang} iframeNode = {iframeNode} cm={cm} element = {elmItem.headline} lastIndexes = {lastIndexes + "-" + index + "-" + elmIndex} showHelpers = {showHelpers} />
                                                                    }
                                                                    {elmItem.columns &&
                                                                        <ColElement allForms={this.props.allForms} allServices={this.props.allServices} handle = {provided.dragHandleProps} lang={lang} fullWidth = {fullWidth} iframeNode = {iframeNode} cm={cm} element = {elmItem.columns} lastIndexes = {lastIndexes + "-" + index + "-" + elmIndex} showHelpers = {showHelpers} />
                                                                    }
                                                                    {elmItem.paragraph &&
                                                                        <ParagraphElement handle = {provided.dragHandleProps} lang={lang} iframeNode = {iframeNode} cm={cm} element = {elmItem.paragraph} lastIndexes = {lastIndexes + "-" + index + "-" + elmIndex} showHelpers = {showHelpers} />
                                                                    }
                                                                    {elmItem.image &&
                                                                        <ImageElement handle = {provided.dragHandleProps} lang={lang} element = {elmItem.image} lastIndexes = {lastIndexes + "-" + index + "-" + elmIndex}  cm = {cm} showHelpers = {showHelpers} />
                                                                    }
                                                                    {elmItem.button &&
                                                                        <ButtonElement handle = {provided.dragHandleProps} lang={lang} iframeNode = {iframeNode} element = {elmItem.button} lastIndexes = {lastIndexes + "-" + index + "-" + elmIndex}  cm = {cm} showHelpers = {showHelpers} />
                                                                    }
                                                                    {elmItem.video &&
                                                                        <VideoElement handle = {provided.dragHandleProps} lang={lang} element = {elmItem.video} lastIndexes = {lastIndexes + "-" + index + "-" + elmIndex}  cm = {cm} showHelpers = {showHelpers} />
                                                                    }
                                                                    {elmItem.articles &&
                                                                        <ArticlesElement handle = {provided.dragHandleProps} lang={lang} element = {elmItem.articles} lastIndexes = {lastIndexes + "-" + index + "-" + elmIndex}  cm = {cm} showHelpers = {showHelpers} />
                                                                    }
                                                                    {elmItem.newsletter &&
                                                                        <NewsletterElement handle = {provided.dragHandleProps} lang={lang} element = {elmItem.newsletter} lastIndexes = {lastIndexes + "-" + index + "-" + elmIndex}  cm = {cm} showHelpers = {showHelpers} />
                                                                    }
                                                                    {elmItem.form &&
                                                                        <FormElement allForms={this.props.allForms} handle = {provided.dragHandleProps} lang={lang} iframeNode = {iframeNode} element = {elmItem.form} lastIndexes = {lastIndexes + "-" + index + "-" + elmIndex}  cm = {cm} showHelpers = {showHelpers} />
                                                                    }
                                                                    {elmItem.service &&
                                                                        <ServiceElement allServices={this.props.allServices} handle = {provided.dragHandleProps} lang={lang} iframeNode = {iframeNode} element = {elmItem.service} lastIndexes = {lastIndexes + "-" + index + "-" + elmIndex}  cm = {cm} showHelpers = {showHelpers} />
                                                                    }
                                                                    {elmItem.faq &&
                                                                        <FaqElement handle = {provided.dragHandleProps} lang={lang} iframeNode = {iframeNode} element = {elmItem.faq} lastIndexes = {lastIndexes + "-" + index + "-" + elmIndex}  cm = {cm} showHelpers = {showHelpers} />
                                                                    }
                                                                    {elmItem.html &&
                                                                        <HtmlElement handle = {provided.dragHandleProps} lang={lang} element = {elmItem.html} lastIndexes = {lastIndexes + "-" + index + "-" + elmIndex}  cm = {cm} showHelpers = {showHelpers} />
                                                                    }
                                                                    {elmItem.alert &&
                                                                        <AlertElement handle = {provided.dragHandleProps} lang={lang} element = {elmItem.alert} lastIndexes = {lastIndexes + "-" + index + "-" + elmIndex}  cm = {cm} showHelpers = {showHelpers} />
                                                                    }

                                                                </div>
                                                                )}
                                                            </Draggable>
                                                        )
                                                        
                                                    })}
                                                    {provided.placeholder}
                                                                                    
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </div>
                                {showHelpers && <div className={"cm-col-border" + (item.showMenu ? " cm-selected" : "")} onClick={(e) => cm.selectElement(e,"column",lastIndexes + "-" + index)} ></div>}

                            </div>
                        )})}

                        {showHelpers &&<div className={"cm-col-border" + (element.showMenu ? " cm-selected" : "")}></div>}
                        {showHelpers && 
                            <div className="cm-dragable-item" {...this.props.handle}>
                                <div className="item-container">
                                    <img className="move-icon"  src={move} alt="Přesunout element" title="Přesunout element" />
                                </div>
                            </div>
                        }
                    </div>
                </div>
                
            </div>
        );

    }
}

export default ColumnsElement;