/*
    Přidání potahu
*/
import React,{ Component, Fragment,useState,useEffect } from 'react';
import { withApollo,useQuery,useMutation } from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import Modal from 'react-modal';
import {ADD_EDIT_CUSTOMER_EVS_POINTS} from '../Queries/customer.js';
import Notification from '../../../Library/notification';
import Helper from '../../../Library/helper';
import CustomerEVSpoint from '../Library/customerEVSpoint';

const AddEditCustomerEVSPoint = (props) => {

    var customerEVSpoint = new CustomerEVSpoint(props);

    const {selectedCustomerEVSPointID} = props;

    const [formData,setFormData] = useState({
        customerEVSPointID:0,
        info:"",
        points:0,
        customerID: props.customerID,
        month: props.month,
        year: props.year
    })

    const [addEditCustomerEVSPoint,{error:addError,loading:addLoading}] = useMutation(ADD_EDIT_CUSTOMER_EVS_POINTS,{
        onCompleted: (data) => {

            customerEVSpoint.updateList(props.client,data,selectedCustomerEVSPointID,props.variablesList)

            var notify = new Notification();
            notify.setNotification(null,"Úspěšně odstraněno",true,false,props.client);

            props.openCloseModal(false,null);
        }

    });

    useEffect(() => {
        customerEVSpoint.getData(setFormData,formData);
    },[])
   
    return(

        
        <Modal
            className="Modal__Bootstrap modal-dialog modal-lg"
            closeTimeoutMS={150}
            isOpen={true}
            onRequestClose={() => props.openCloseModal(false,null)}
        >
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">{(selectedCustomerEVSPointID && selectedCustomerEVSPointID != 0 ? "Úprava bodů" : "Přidání bodů")}</h4>
                    <button type="button" className="close" onClick={() => props.openCloseModal(false,null)}>
                    <span aria-hidden="true">x</span>
                    <span className="sr-only">Close</span>
                    </button>
                </div>
                <div className="modal-body">

                    <div>
                        {!addLoading ?
                            <Fragment>
                                {addError ?
                                    <Error text={addError} />
                                :
                                    <div>

                                        <div className="row">
                                                                                                 
                                            <div className="col-12 col-sm-8">
                                                <label className="input-label">Info</label>
                                                <div className="form-group">
                                                    <input className="form-control" type="text" name="info" value={formData.info} onChange={(e) => customerEVSpoint.formHandle(e,formData,setFormData)} />
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-4">
                                                <label className="input-label">Počet bodů</label>
                                                <div className="form-group">
                                                    <input className="form-control" type="text" name="points" value={formData.points} onChange={(e) => customerEVSpoint.formHandle(e,formData,setFormData)} />
                                                </div>
                                            </div>

                                        </div>
                                           
                                    </div>
                                }
                            </Fragment>
                        :
                        <Loading />  
                        }  

                    </div>

                </div>
                <div className="modal-footer"> 
                
                    <button type="button" className="btn btn-primary flex-fill" onClick={() => customerEVSpoint.addCustomer(addEditCustomerEVSPoint,formData)}>{(selectedCustomerEVSPointID && selectedCustomerEVSPointID != 0 ? "Upravit" : "Přidat")}</button>
                    <button type="button" className="btn btn-danger" onClick={() => props.openCloseModal(false,null)}>Zavřít</button>
                </div>
            </div>
        </Modal>
                
    )
}

export default withApollo(AddEditCustomerEVSPoint);