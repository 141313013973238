
/**
 * soubor se seznamem veškerých graphql dotazů pro tinyMCE
 */
import gql from 'graphql-tag';

export const GET_TINYMCE_IMAGES = gql`
    query allTinyMCEImages {
        allTinyMCEImages{
            name
            imageID
        }
    }
`;

export const UPLOAD_TINYMCE_IMAGES = gql`
    mutation uploadTinyMCEImages($files:[Upload]!) {
        uploadTinyMCEImages(files:$files){
            name
            imageID
        }
    }
`;

/**
 * smazání produktových fotek
 */
export const DELETE_TINYMCE_IMAGES = gql`
    mutation deleteTinyMCEImages($imagesID:[ID]!) {
        deleteTinyMCEImages(imagesID:$imagesID)
    }
`;

export const GET_TINYMCE_FILES = gql`
    query allTinyMCEFiles {
        allTinyMCEFiles{
            name
            fileID
            type
        }
    }
`;

export const UPLOAD_TINYMCE_FILES = gql`
    mutation uploadTinyMCEFiles($files:[Upload]!) {
        uploadTinyMCEFiles(files:$files){
            name
            fileID
            type
        }
    }
`;

/**
 * smazání produktových fotek
 */
export const DELETE_TINYMCE_FILES = gql`
    mutation deleteTinyMCEFiles($filesID:[ID]!) {
        deleteTinyMCEFiles(filesID:$filesID)
    }
`;