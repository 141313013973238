import { useState } from "react";
import Notification from '../../../Library/notification';

const UseQuestionnaire = (deleteForms,client) => {

    const [selectedItems, SetSelectedItems] = useState([]);
    const [showDeleteNotifi, SetDeleteNotify] = useState(false);

    const DelQuestionnaire = (action) => {
        if(action)
        {
            let questionnaireIDs = selectedItems.join(",");
            deleteForms({
                variables:{
                    questionnaireIDs
                }
            }); 
        }

        SetDeleteNotify(false);
    }

    const ShowDelNotifi = () => {

        if(selectedItems.length > 0){
            SetDeleteNotify(true);
        }else{
            let notify = new Notification();
            notify.setNotification(null,'Nevybrali jste žádnou položku.',false,true,client);
        }
    }

    const AddRemoveItemToDelete = (e,questionnaireID) => {

        var checked = e.target.checked;
        var arr = [...selectedItems];

        if(!checked){
            for(let i in selectedItems){
                if(selectedItems[i] == questionnaireID){
                    arr.splice(i,1);
                }
            }
        }else{
            arr = [questionnaireID,...arr];
        }
        
        SetSelectedItems(arr);
  
    }

    return{
        selectedItems,
        showDeleteNotifi,
        DelQuestionnaire,
        ShowDelNotifi,
        AddRemoveItemToDelete
    }

}

export default UseQuestionnaire;