/*
    Přidání kategorie
*/
import React,{ Component,Fragment } from 'react';
import {Mutation,Query} from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import {ADD_EDIT_EVS_DATA} from '../Queries/eliteVS.js';
import { withApollo } from 'react-apollo';
import Notification from '../../../Library/notification';
import Helper from '../../../Library/helper';
import TinyMCEEditor from '../../TinyMCEFileManager/Components/TinyMCEEditor';
import EliteVSLib from '../Library/eliteVS';
import EliteVSMonthData from './EliteVSMonthData';

const INITIAL_STATE = {
    err:"",
    allLanguageMutations:[],
    selectedTab:1,
    formData:{
        evsDataID:1,
        selectedLang:"cz",
        langs:[]
    }
}

class EliteVS extends Component{

    constructor(props){
        super(props);
        this.state = INITIAL_STATE;
        
        this.eliteVSLib = new EliteVSLib(this); 
    }

    setTab(type){
        this.setState({selectedTab:type});
    }

    componentDidMount = () => {
        
        this.eliteVSLib.getData();
    }


    render(){

        var {err,allLanguageMutations,selectedTab} = this.state;

        return(

            <div id="settings" className="whole-container" >

                <ul className="nav nav-tabs" role="tablist">
                    <li className="nav-item"><a onClick={(e) => {e.preventDefault();this.setTab(1)}} className={"nav-link" + (selectedTab == 1 ? " active" : "")} href="#main"> Statické texty </a></li>
                    <li className="nav-item"><a onClick={(e) => {e.preventDefault();this.setTab(2)}} className={"nav-link" + (selectedTab == 2 ? " active" : "")} href="#main"> Obrázky pro jednotlivé měsíce</a></li>
                    
                </ul>

                {selectedTab == 1 ? 
                    <Mutation 
                        mutation={ADD_EDIT_EVS_DATA}
                        errorPolicy = "all"
                        update = {async (cache, response) => {

                            let notify = new Notification();
                            notify.setNotification(cache,'Úspěšně uloženo',true,false);

                        }}
                        
                    >
                    {
                        (addEditEvsData,{loading, error}) => {

                            const {selectedLang,steps} = this.state.formData;

                            if(error){
                                const helper = new Helper(); 
                                err = helper.getApolloErrorText(error);
                            }

                            return(

                                
                        
                                <div className="row" >
                                    <div className="col-sm one">
                                
                                    <div className="card main">
                                        <div className="card-header d-flex align-items-center">
                                            <h4 className="modal-title">Elitní Vládci Síly</h4>
                                            <div className="ml-auto">
                                                <select className="form-control" name="selectedLang" onChange={(e) => this.eliteVSLib.formHandle(e)}>
                                                    {allLanguageMutations && allLanguageMutations.map((item,index) => (
                                                        <option key={index} value={item.suffix}> {item.suffix} </option>
                                                    ))}
                                                </select>
                                            </div>
                                            
                                        </div>
                                        <div className="card-body">

                                            {err ?
                                                <Error text={err} />
                                            :
                                                <Fragment>
                                                    {!loading ?
                                                    <>

                                                        <div className="row">
                                                            <div className="col-12">
                                                                <label className="input-label">Jak získat body ({selectedLang})</label>
                                                                <div className="form-group">
                                                                    
                                                                    <TinyMCEEditor onChange={(content,type) => this.eliteVSLib.formLangHandleEditor(content,"mainText")} initialValue={this.eliteVSLib.getLangValue("mainText")} /> 
                                                                                                                
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <label className="input-label">Co jsou to elitní Vládci Síly ({selectedLang})</label>
                                                                <div className="form-group">
                                                                    
                                                                    <TinyMCEEditor onChange={(content,type) => this.eliteVSLib.formLangHandleEditor(content,"description")} initialValue={this.eliteVSLib.getLangValue("description")} /> 
                                                                                                                
                                                                </div>
                                                            </div>
                                                        </div>
                                            
                                                    </>

                                                    :

                                                    <Loading />  

                                                    }  
                                                </Fragment>
                                            }
                                                    
                                        </div>
                                        <div className="modal-footer"> 
                                            {!loading && <button type="button" className="btn btn-primary flex-fill" onClick={() => this.eliteVSLib.addEvsData(addEditEvsData)}>Upravit</button>}
                                        </div>
                                    </div>

                                    </div>
                                </div>
                                
                                
                            );

                        }
                    }
                    </Mutation>
                : null}
                {selectedTab == 2 ? 
                    <EliteVSMonthData />
                : null}
            </div>
        
        );

    }

}

export default withApollo(EliteVS);