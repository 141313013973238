import { useState } from "react";
import Notification from '../../../Library/notification';
import { GET_COURSE } from "../Queries/course";

const UseAddCourse = (AddEditCourse,selectedCourseID,client) => {

    const [formData,SetFormData] = useState({
        courseID:0,
        emailHeaderTypeID:1,
        questionnaireID:0,
        guideID:0,
        name:"",
        langs:[],
        selectedLang:'cz'
    });

    const [allLanguageMutations, SetAllLanguageMutations] = useState([]);
    const [allQuestionnaires, SetAllQuestionnaires] = useState([]);
    const [allGuides,SetAllGuides] = useState([]);
    const [loading,SetLoading] = useState(false);
    const [error,SetError] = useState("");

    const GetData = async () => {

        var courseID = 0;
        if(selectedCourseID)courseID = selectedCourseID;
        
        SetLoading(true);

        var data = await client.query({ 
            query: GET_COURSE,
            errorPolicy:"all",
            variables:{courseID:courseID}, 
            fetchPolicy: 'network-only'
        });
         
        if(data.errors){
            SetError(data.errors[0].message);
            return;
        }
        
        //kdyz upravujeme zaznam
        if(data.data)
        {                  
            if(data.data.courseWithLangs)
            {    
                SetLoading(false);
                
                let langs = CompleteLangsArray(data.data.courseWithLangs.langs,data.data.allLanguageMutations);     
                
                SetAllLanguageMutations(data.data.allLanguageMutations);
                SetAllGuides(data.data.allGuides);
                SetAllQuestionnaires(data.data.getAllQuestionnaires);

                SetFormData({...formData,
                    courseID:courseID,
                    emailHeaderTypeID:data.data.courseWithLangs.emailHeaderTypeID,
                    questionnaireID:data.data.courseWithLangs.questionnaireID,
                    guideID:data.data.courseWithLangs.guideID,
                    name:data.data.courseWithLangs.name,
                    langs:langs
                })
            }
        }

        //kdyz pridavame zaznam
        if(courseID == 0)
        {
            SetLoading(false);

            let langs = CompleteLangsArray([],data.data.allLanguageMutations);            
            SetAllLanguageMutations(data.data.allLanguageMutations);
            SetAllGuides(data.data.allGuides);
            SetAllQuestionnaires(data.data.getAllQuestionnaires);

            SetFormData({...formData,
                langs:langs
            })
        }
    }

    const AddCourse = () => {

        if(formData.guideID != 0)
        {
            if(formData.questionnaireID != 0)
            {
                if(formData.name != "")
                {                        
                    let langs = [];
                    for(const lang of formData.langs){
                        langs.push({
                            lang:                lang.lang,
                            regEmail:            lang.regEmail,
                            emailForVs:          lang.emailForVs,
                            successMessage:      lang.successMessage,
                            freeWeekEmail:       lang.freeWeekEmail,
                            cancelFreeWeekEmail: lang.cancelFreeWeekEmail,
                        });
                    }

                    AddEditCourse({
                        variables:{
                            courseID:           formData.courseID,
                            emailHeaderTypeID:  formData.emailHeaderTypeID,
                            questionnaireID:    formData.questionnaireID,
                            guideID:            formData.guideID,
                            name:               formData.name,
                            langs
                        }
                    });
                }
                else
                {
                    let notify = new Notification();
                    notify.setNotification(null,'Pro lepší přehlednost si raději vyplň i interní název.',false,true,client);
                }
            }
            else
            {
                let notify = new Notification();
                notify.setNotification(null,'Bude lepší, když ke kurzu dáš i dotazník, což ?',false,true,client);
            }
        }
        else
        {
            let notify = new Notification();
            notify.setNotification(null,'Bez průvodce se ten kurz fakt nerozjede.',false,true,client);
        }

    }

    const SetFormDataValue = (e) => {

        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;

        SetFormData({...formData,[n]:v})
    }

    const SetFormDataLangValue = (e,paramName) => {
        
        var v, n;
        if(paramName)
        {
            v = e;
            n = paramName
        }
        else
        {
            const t = e.target;
            v = t.type === 'checkbox' ? t.checked : t.value;
            n = t.name;
        }
        
        let langs  = formData.langs;
        for(const lang of langs){
            if(lang.lang == formData.selectedLang){
                lang[n] = v;
            }
        }

        SetFormData({...formData,langs: langs});
         
    }

    const GetLangValue = (propertyName) => {
        for(const lang of formData.langs){
            if(lang.lang == formData.selectedLang){
                return lang[propertyName];
            }
        }
        return "";
    }

    const CompleteLangsArray = (langsFromDb,allActiveLanguages) => {

        let langs = langsFromDb;
        for(const l of allActiveLanguages)
        {
            let langSet = false;
            for(const lang of langs)
            {
                if(l.suffix == lang.lang)
                {
                    if(!lang.text) lang.text = "";
                    langSet = true;
                    break;
                }
            }

            if(!langSet)
            {
                langs.push({
                    regEmail:"",
                    emailForVs:"",
                    successMessage:"",
                    freeWeekEmail:"",
                    cancelFreeWeekEmail:"",
                    lang:l.suffix
                });
            }
        }
        return langs;
    }
    
    return{
        formData,
        allLanguageMutations,
        allQuestionnaires,
        allGuides,
        loading,
        error,
        SetFormDataValue,
        SetFormDataLangValue,
        GetLangValue,
        GetData,
        AddCourse
    }

}

export default UseAddCourse;