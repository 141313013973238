import React,{ Component,Fragment } from 'react';
import {Mutation} from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import Notification from '../../../Library/notification';
import Auth from '../Library/auth';
import {CHANGE_PASSWORD} from '../Queries/changePassword';
import { withApollo } from 'react-apollo';

const INITIAL_STATE = {
	password:{
		oldPassword:'',
		newPassword:'',
		checkNewPassword:'',
	},
	error:"",
	message:"",
	loading:false
}

class ChangePassword extends Component {
		
  constructor(props){
	    
	  super(props);	 
	  this.state = {...INITIAL_STATE};
 
  }	

  componentDidMount(){
	  this.auth = new Auth(this);
  }
    
  
  render() {

	const {error,message,loading} = this.state;
		  
    return (
    	<div id="settings" className="whole-container">		
    		<div className="row">
    			<div className="col-sm one">
					<Mutation
						mutation = {CHANGE_PASSWORD}
						update = {async (cache, response) => {

							let notify = new Notification();

							if(response.data.changeAdminPassword != ""){
								notify.setNotification(cache,response.data.changeAdminPassword,false,true);
							}else{	
								notify.setNotification(cache,'Úspěšně změněno',true,false);
							}

						}}
					>
						{
							(changePassword,response) => {
						
								return(
									<div className="card main">
										
										<div className="card-header d-flex align-items-center">
											Změna hesla
											<button className="btn btn-primary btn-thiner ml-auto" onClick={() => this.auth.changePassword(changePassword)}>Změnit</button>
										</div>
										<div className="card-body">

											{response.error ?
												<Error text={response.error} />
											:
												<Fragment>
													{response.loading ?
														<Loading />
													:
														<div className="row">
																					
															<div className="col-4">
																<label className="input-label">Staré heslo</label>
																<div className="form-group">
																	<input className="form-control" type="password" name="oldPassword" onChange={(e) => this.auth.formHandle(e)} />
																</div>
															</div>
															<div className="col-4">
																<label className="input-label">Nové heslo</label>
																<div className="form-group">
																	<input className="form-control" type="password" name="newPassword" onChange={(e) => this.auth.formHandle(e)} />
																</div>
															</div>
															<div className="col-4">
																<label className="input-label">Zopakovat nové heslo</label>
																<div className="form-group">
																	<input className="form-control" type="password" name="checkNewPassword" onChange={(e) => this.auth.formHandle(e)} />
																</div>
															</div>
																
														</div>
													}
												</Fragment>
											}
														

										</div>
										
									</div>
								)
							}
						}
					</Mutation>
    			</div>
    		</div>    			
        </div>
    );
  }
}

export default withApollo(ChangePassword);
