import React,{ Component } from 'react';
import AllLinks from './AllLinks.js';
import AddEditLink from './AddEditLink';

const INITIAL_STATE = {
    showAddLink : false,
}

class Link extends Component{

    constructor(props){
       super(props);
       this.state = INITIAL_STATE;
       this.openCloseModal = this.openCloseModal.bind(this);

    }

    

    openCloseModal(type){
        this.setState({
            showAddLink:type,
        })
    }

    render(){

        return(

            <div id="settings" className="whole-container" >
            	
                <div className="row" >
                    <div className="col-sm one">
                        <div className="card main">
                            
                            <div className="card-header d-flex align-items-center">
                                Seznam odkazů
                                <button className="btn btn-primary btn-thiner ml-auto" onClick={() => this.openCloseModal(true)}>Přidat</button>
                            </div>
                            <div className="card-body">
                                <AllLinks />
                            </div>
                        </div>
                    </div>
                </div> 	
                {this.state.showAddLink &&
                    <AddEditLink openCloseModal={this.openCloseModal} />
                }		
            </div>
        );

    }

}

export default Link;