import React,{ Component } from 'react';

class SelectSubCategory extends Component{

    constructor(props){
        super(props);
    }

    render(){

        return(
            <React.Fragment>
                {this.props.categories && this.props.categories.map((item,index) => (
                    <React.Fragment key={index}>
                        <option key={index+1} value={item.categoryID} >{item.name}</option>
                        <SelectSubCategory categories={item.subCategory} />
                    </React.Fragment>
                    
                ))}
            </React.Fragment>

            
        )

    }

}

export default SelectSubCategory;