/**
 * Třía která obsahuje veškeré pomocné funkce, pro práci s přidáváním a editací stavů objednávek
 */

 import {GET_EVS_MONTH_DATA,GET_EVS_MONTH_All_DATA,GET_EVS_MONTH_DATA_IMAGES} from '../Queries/eliteVS';
 
 class EliteVSMonthData {
 
     constructor(parent){
         this.parent = parent;
     }
 

     /**
      * Přidání/Editace
      * @param {*} addMaterial funkce z apollo, pomocí které se posílají data na server
      */
 
    addEditEvsMonthData = async (addEditEvsMonthData,callback = null) => {
 
        const data = this.parent.state.formData;
            
        let langs = [];
        for(const lang of data.langs){

            langs.push({
                text:    lang.text,
                lang:    lang.lang
            });
        }  
        
        addEditEvsMonthData({
            variables:{
                
                evsMonthDataID: data.evsMonthDataID,
                month:          parseInt(data.month),
                year:           parseInt(data.year),
                langs:          langs
                
            }
        }).then(responseData => {
            if(callback){
                callback(true,responseData);
            }
        }, errorData =>{
            if(callback){
                callback(false,errorData);
            }
        });
        
    } 

    /**
    * funkce ukládá data po zmene jazykove mutace ve formulari, která byla vložena do formuláře
    * @param {*} e ukazatel na element
    */
    
    formLangObjectHandle(e){
	  
        const t = e.target;
        const v = t.value;
        
        for(const lang of this.parent.state.allLanguageMutations){
            if(lang.languageID == v){
                this.parent.setState({formData:{...this.parent.state.formData,selectedLang: lang.suffix}});
            }
        }

              
    }
 
     
     /**
      * při inicializaci vložení nebo úpravy se vytáhnou potřebná data
      */
 
     async getData(){

        var evsMonthDataID = 0;
        if(this.parent.props.selectedEvsMonthDataID)evsMonthDataID = this.parent.props.selectedEvsMonthDataID;
        
         var data = await this.parent.props.client.query({ 
             query: GET_EVS_MONTH_DATA,
             errorPolicy:"all", 
             fetchPolicy: 'network-only',
             variables:{
                evsMonthDataID: evsMonthDataID
             }
         });      
         
         if(data.errors){
             this.parent.setState({apolloError:data.errors[0].message});
         }

         //kdyz upravujeme zaznam
         if(data.data){                  
             if(data.data.evsMonthDataWithLangs){ 

                let langs = this.completeLangsArray(data.data.evsMonthDataWithLangs.langs,data.data.allLanguageMutations);    

                this.parent.setState({
                    apolloError:"",
                    allLanguageMutations: data.data.allLanguageMutations,
                    selectedEvsMonthDataID:data.data.evsMonthDataWithLangs.evsMonthDataID,
                    formData:{...this.parent.state.formData,
                        evsMonthDataID: data.data.evsMonthDataWithLangs.evsMonthDataID,
                        month:          data.data.evsMonthDataWithLangs.month,
                        year:           data.data.evsMonthDataWithLangs.year,
                        langs:          langs
                    }
                });

             }else{
                 this.parent.setState({ apolloError:"",allLanguageMutations: data.data.allLanguageMutations});
             }
         }

         //kdyz pridavame zaznam
        if(evsMonthDataID == 0){
            let langs = this.completeLangsArray([],data.data.allLanguageMutations);     
            this.parent.setState({
                selectedEvsMonthDataID: evsMonthDataID,
                allLanguageMutations: data.data.allLanguageMutations,
                formData:{...this.parent.state.formData,
                    langs:langs
                }
            });
        }
 
     }

     /**
     * Při vložení nebo úpravě zákazníka dojde k update cache a vykreslí se opět seznam veškerých zákazníků
     * @param {Object} cache apollo cahe v které jsou oložena data
     * @param {Object} response položka, která byla vložena nebo upravována
     * @param {Number} selectedID id položky
     * @param {Object} variables parametry posílané do query
     */

    updateList(cache,response,selectedID,variables){

        const { evsMonthData } = cache.readQuery({ query: GET_EVS_MONTH_All_DATA,variables:variables });
        
        if(!(selectedID && selectedID != 0)){

            const arr = [response.data.addEditEvsMonthData,...evsMonthData]   ;
            
            cache.writeQuery({ 
                query:GET_EVS_MONTH_All_DATA,
                variables:variables,
                data:{
                    evsMonthData: arr
                } 
            });

        }else{

            var arr = [...evsMonthData];
            
            arr.forEach((item,index) => {
                
                if(item.evsMonthDataID == response.data.addEditEvsMonthData.evsMonthDataID){
                    arr[index].evsMonthDataID  = response.data.addEditEvsMonthData.evsMonthDataID;
                    arr[index].month           = response.data.addEditEvsMonthData.month;
                    arr[index].year            = response.data.addEditEvsMonthData.year;
                }

            });
            cache.writeQuery({ 
                query:GET_EVS_MONTH_All_DATA,
                variables:variables,
                data:{
                    evsMonthData: arr
                } 
            });
        }
    }

    /**
     * =uprava seznamu fotek pro produkty po uploadu nových
     * @param {*} cache apollo cache, kde jsou uložena data
     * @param {*} response hodnota, která se vrátila po smazání potahu
     * @param {*} productID ID produktu
     */
     updateUploadedImages(cache, response,evsMonthDataID){

        const { evsMonthDataImages } = cache.readQuery({ query: GET_EVS_MONTH_DATA_IMAGES, variables:{evsMonthDataID} });
        var arr = [...response.data.uploadEvsMonthDataImages,...evsMonthDataImages];

        cache.writeQuery({ 
            query:GET_EVS_MONTH_DATA_IMAGES,
            variables:{evsMonthDataID},
            data:{
                evsMonthDataImages: arr
            } 
        });

    }

    updateUploadedImagesAfterDelete(cache, response,evsMonthDataID){

        const { evsMonthDataImages } = cache.readQuery({ query: GET_EVS_MONTH_DATA_IMAGES, variables:{evsMonthDataID} });
        var arr = [...evsMonthDataImages];

        for(let i in response.data.deleteEvsMonthDataImage){
            for(let j in arr){
                if(response.data.deleteEvsMonthDataImage[i] == arr[j].evsMonthDataImageID){
                    arr.splice(j,1);
                }
            }
        }

        cache.writeQuery({ 
            query:GET_EVS_MONTH_DATA_IMAGES,
            variables:{evsMonthDataID},
            data:{
                evsMonthDataImages: arr
            } 
        });

    }
 
     completeLangsArray = (langsFromDb,allActiveLanguages) => {
        
        let langs = langsFromDb;

        for(const l of allActiveLanguages){
            let langSet = false;

            if(langs){
                for(const lang of langs){
                    if(l.suffix == lang.lang){
                        langSet = true;
                        break;
                    }
                }
            }else{
                langs = [];
            }

            if(!langSet){
                langs.push({
                    text:"", 
                    lang:l.suffix
                });
            }
        }
        
         return langs;
     }
 
      
     /**
      * funkce ukládá data, která byla vložena do formuláře
      * @param {*} e ukazatel na element
      */
     
     formHandle(e){
       
         const t = e.target;
         const v = t.type === 'checkbox' ? t.checked : t.value;
         const n = t.name;
         
         this.parent.setState({formData:{...this.parent.state.formData,[n]: v}});
               
     }
     /**
      * funkce ukládá data po zmene jazykove mutace ve formulari, která byla vložena do formuláře
      * @param {*} e ukazatel na element
      */
     
     
     /**
      * funkce ukládá data (jazykove mutace), která byla vložena do formuláře
      * @param {*} e ukazatel na element
      */
     
    formLangHandle(e){
        
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        let langs       = this.parent.state.formData.langs;
        for(const lang of langs){
            if(lang.lang == this.parent.state.formData.selectedLang){
                lang[n] = v;
            }
        }

        this.parent.setState({...this.parent.state,formData:{...this.parent.state.formData,langs: langs}});
         
    }
 
     /**
      * funkce ukládá data (jazykove mutace), která byla vložena do formuláře přes tinyMCE editor
      * @param {String} data data tinyMCE editoru
      * @param {String} paramName nazev parametru, kteremu nastavujeme textovou hodnotu
      */
     
      formLangHandleEditor(content,paramName){

        
        let langs       = this.parent.state.formData.langs;
        for(const lang of langs){
            if(lang.lang == this.parent.state.formData.selectedLang){
                lang[paramName] = content;
            }
        }

        this.parent.setState({...this.parent.state,formData:{...this.parent.state.formData,langs: langs}});
    }
 
     /**
      * Vraci hodnotu zadaneho parametru z jazykovych mutaci, podle zadaneho jazyku
      * @param {String} paramName - název proměnné v objektu lang
      * 
      * @returns property value
      */
      getLangValue = (paramName) => {
        for(const lang of this.parent.state.formData.langs){
            if(lang.lang == this.parent.state.formData.selectedLang){
                if(lang[paramName] === null){
                    return "";
                }else{
                    return lang[paramName];
                }
            }
        }
        return "";
    }
 
 
     
 }
 
 export default EliteVSMonthData;