import React,{ Component,Fragment } from 'react';
import { withApollo,useQuery } from 'react-apollo';
import {GET_CUSTOMER_MATERIAL_DATA} from '../Queries/customer';
import Loading from '../../../GlobalComponents/Loading';
import SubMaterial from './SubMaterial';

const Materials = (props) => {

    const {data,loading,error,} = useQuery(GET_CUSTOMER_MATERIAL_DATA,{
        variables:{
            lang:"cz",
            customerID:props.customerID
        },
        fetchPolicy: 'network-only',
    });



    return(
        
        
        <div className="card main">
            
            <div className="card-header d-flex align-items-center">
                Seznam materiálů
            </div>
            <div className="card-body">

                {loading ?
                    <Loading />
                :
                    <div>

                        {data.allMaterials && data.allMaterials.length > 0 ?

                            <SubMaterial selectedMaterials = {props.selectedMaterials} materials={data.allMaterials} allMaterials = {data.allMaterials} level={0} setSelectedMaterials={props.setSelectedMaterials} />

                        :
                            <div className="alert alert-warning text-center">Nebyly nalezeny žádné materiály</div>
                        }

                    </div>
                }
                
            </div>
        </div>
                
                
    );

    

}

export default withApollo(Materials);