/**
 * soubor se seznamem veškerých graphql dotazů pro články
 */
import gql from 'graphql-tag';

/**
 * vložení kategorie
 */
export const ADD_EDIT_EVENT = gql`
    mutation AddEditEvent($eventID:ID,$eventDate:String,$active:Int,$recurring:Int,$recurringType:Int,$eventCategoryIDs:[String],$langs:[EventLangsInput]){
        addEditEvent(eventID:$eventID,eventDate:$eventDate,active:$active,recurring:$recurring,recurringType:$recurringType,eventCategoryIDs:$eventCategoryIDs,langs:$langs){
            eventID
            title
            niceTitle
            text
            eventDate
            lang
            active
            recurring
            recurringType
        }
    }
`;

/**
 *  Data pro jednu kategorii
 */

export const GET_EVENT = gql`
    query EventWithLangs($eventID: ID!){
        eventWithLangs(eventID:$eventID){
            eventID            
            active
            recurring
            recurringType
            eventCategoryIDs
            eventDate
            langs{
                lang
                title
                niceTitle
                text
            }    
        }
        allLanguageMutations(onlyEnabled:true){
            languageID
            title
            suffix
            generateNiceTitle
            langTitle
            decimal
            priority
            main
            currencyTitle
        }
    }
`;

/**
 *  Seznam veškerých eventů
 */
export const GET_EVENTS = gql`
    query AllEvents($lang: String!,$offset:Int, $limit: Int, $onlyFuture:Boolean){
        allEvents(lang: $lang,offset:$offset,limit:$limit,onlyFuture:$onlyFuture){  
            eventID
            eventDate
            title
            niceTitle
            text
            lang
            active
            recurring
            recurringType
        }
    }
`;

/**
 * Smazání kategorie.
 */
export const DELETE_EVENTS = gql`
    mutation deleteEvents($eventIDs:ID!) {
        deleteEvents(eventIDs:$eventIDs)
    }
`;