/**
 *  komponenta pro štítky pro produkty
 */
import React,{ Component,Fragment } from 'react';
import { withApollo } from 'react-apollo';
import {Query,Mutation} from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import NoItems from '../../../GlobalComponents/NoItems';
import {GET_CUSTOMERS_INVOICES,ADD_PAYMENT} from '../Queries/customer.js';
import notes from '../../../Public/Images/notes.svg';
import Helper from '../../../Library/helper';
import {SERVER_URL} from '../../../Config/index';
import InvoiceLib from '../Library/invoice';
import Modal from 'react-modal';
import Notification from '../../../Library/notification';
import remove from '../../../Public/Images/remove.svg';

import DatePicker,{ registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import cs from 'date-fns/locale/cs';

registerLocale('cs', cs);

const INITIAL_STATE = {
    showModal:false,
    paymentDate:new Date(),
    paymentItems:[],
    customerListVariables:{
        limit:50,
        offset:0,
    }
}

class Invoice extends Component{

    constructor(props){
       super(props);
       this.state = INITIAL_STATE;

       this.invoiceLib = new InvoiceLib(this);
    }

    openCloseModal(type){
        this.setState({
            showModal:type
        });
    };

    addInvoiceItem(){

        var newItem = {
            name:"",
            price: ""
        }

        var arr = [...this.state.paymentItems,newItem];
        this.setState({paymentItems:arr});
    }    

    render(){
        
        const {customerListVariables,showModal,paymentDate,paymentItems} = this.state;
        const {customerID,membershipDuration} = this.props;

        return(
         
            <Query 
                query={GET_CUSTOMERS_INVOICES}
                variables = {{
                    offset:customerListVariables.offset,
                    limit:customerListVariables.limit,
                    customerID
                }}
                fetchPolicy = {'network-only'}
            >
                {({data,loading,error,fetchMore,refetch }) => {
                    
                    if(loading) return (<Loading />);
                    if(error){
                        const helper = new Helper(); 
                        return (<Error text={helper.getApolloErrorText(error)} />);
                    }

                    return(

                        <Fragment>
                            <div className="card main">
                                <div className="card-header d-flex align-items-center">
                                    <button className="btn btn-primary btn-thiner ml-auto" onClick={(e) => {this.openCloseModal(true);}}>Přidat platbu</button>
                                </div>
                                <div className="card-body">
                    
                                    <div className="data-list">
                                        
                                        <div className="data-list-item header">
                                            <div className="data-list-item-content">
                                                <div className="">Číslo faktury</div>
                                                <div className="text-center static">Datum vložení</div>
                                                <div className="text-center static">Datum splatnosti</div>
                                                <div className="text-center static">Celková částka</div>
                                                <div className="text-right static small">Možnosti</div>
                                            </div>
                                        </div>
                                        
                                        {data.allCustomerInvoices && data.allCustomerInvoices.map((item,index) => {
                                                                                                        
                                            return (
                                            
                                                <div key={index} className="data-list-item-content">
                                                    <div className="">{item.invoiceNumber}</div>
                                                    <div className="text-center static">{item.dateAddStr}</div>
                                                    <div className="text-center static">{item.dueDateStr}</div>
                                                    <div className="text-center static">{item.totalPrice}</div>
                                                    
                                                    <div className="text-right static small">
                                                        <a target="_blank" href={SERVER_URL + "/generate/invoice/" + item.invoiceID}><img title="tisk faktury" className="print-icon" src={notes} /></a> 
                                                    
                                                    </div>
                                                </div>
                                                        
                                            )
                                            
                                        })} 

                                                            
                                        {data.allCustomerInvoices && data.allCustomerInvoices.length == 0 ?
                                            <NoItems text={"Momentálně se zde nenachází žádné faktury."} />
                                        :
                                            <div className="text-center p-5">
                                                <button className="btn btn-primary" onClick={() => fetchMore({
                                                    variables: {
                                                        offset: data.allCustomerInvoices.length
                                                    },
                                                    updateQuery: (prev, { fetchMoreResult }) => {
                                                        if (!fetchMoreResult) return prev;
                                                        return Object.assign({}, prev, {
                                                            allCustomerInvoices: [...prev.allCustomerInvoices, ...fetchMoreResult.allCustomerInvoices]
                                                        });
                                                    }
                                                })}> Načíst další faktury </button>
                                            </div> 

                                        }
                                        
                                    </div>
                                </div>
                            </div>
                            {
                                showModal ?
                                    <Mutation 
                                        mutation={ADD_PAYMENT}
                                        update = {async (cache, response) => {
                
                                            refetch();
                
                                            let notify = new Notification();
                                            notify.setNotification(cache,'Úspěšně vloženo',true,false);
                                            
                                        }}
                
                                        onCompleted = {(response) => {

                                            this.setState({paymentItems:[]},() => {
                                                this.openCloseModal(false);
                                            })
                                               
                                        }}
                                        
                                    >
                                    {
                                        (addManualyCustomerPayment,{data,loading,error}) => {
                
                                            
                                            if(error){
                                                const helper = new Helper(); 
                                                error = helper.getApolloErrorText(error);
                                            }
                
                                            return(
                                                <Modal
                                                    className="Modal__Bootstrap modal-dialog modal-lg"
                                                    closeTimeoutMS={150}
                                                    isOpen={true}
                                                    onRequestClose={() => this.openCloseModal(false)}
                                                >
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h4 className="modal-title">Přidat platbu</h4>
                                                            <button type="button" className="close" onClick={() => this.openCloseModal(false)}>
                                                            <span aria-hidden="true">x</span>
                                                            <span className="sr-only">Close</span>
                                                            </button>
                                                        </div>
                                                        <div className="modal-body">
                
                                                            <div>
                                                                {!loading ?
                                                                    <Fragment>
                                                                        {error ?
                                                                            <Error text={error} />
                                                                        :
                                                                            <div>
                
                                                                                <div className="col-12">
                                                                                    <label className="input-label">Datum platby</label>
                                                                                    <div className="form-group">
                                                                                        <DatePicker className="form-control" dateFormat="dd.MM.yyyy" locale="cs" selected={paymentDate} onChange={date => this.setState({paymentDate:date})} />
                                                                                    </div>
                                                                                </div>    
                                                                                                                                                             
                                                                                <div className="col-12">
                                                                                    
                                                                                    <div className="card main">
                                                                                        <div className="card-header d-flex align-items-center">
                                                                                            Další položky na faktuře
                                                                                            <button className="btn btn-primary btn-thiner ml-auto" onClick={(e) => {this.addInvoiceItem(true);}}>Přidat položku</button>
                                                                                        </div>
                                                                                        <div className="card-body">
                                                                                            
                                                                                                {paymentItems.map((item,index) => (

                                                                                                    <div key={index} className="row form-group">
                                                                                                        <div className="col-7">
                                                                                                            <input onChange={(e) => this.invoiceLib.changeInvoiceItemValue(e,index,"name")} type="text" className="form-control" name="itemName" value={item.name} placeholder="Název položky" />
                                                                                                        </div>
                                                                                                        <div className="col-4">
                                                                                                            <input onChange={(e) => this.invoiceLib.changeInvoiceItemValue(e,index,"price")} type="number" className="form-control" name="itemName" value={item.price} placeholder="Cena" />
                                                                                                        </div>
                                                                                                        <div className="col-1 d-flex align-items-center">
                                                                                                            <img onClick={(e) => this.invoiceLib.removeInvoiceItem(index)} className="edit-icon" src={remove} title="Odstranit položku" />
                                                                                                        </div>
                                                                                                    </div>

                                                                                                ))}

                                                                                                {paymentItems.length == 0 ?
                                                                                                    <NoItems text={"Momentálně se zde nenachází žádné položky."} />
                                                                                                :null}
                                                                                                
                                                                                            
                                                                                        </div>
                                                                                    </div>
                                                                                </div>     
                                                                                
                                                                            </div>
                                                                        }
                                                                    </Fragment>
                                                                :
                                                                <Loading />  
                                                                }  
                
                                                            </div>
                                    
                                                        </div>
                                                        <div className="modal-footer"> 
                                                        
                                                            <button type="button" className="btn btn-primary flex-fill" onClick={() => this.invoiceLib.addPayment(addManualyCustomerPayment,paymentDate,paymentItems,customerID,membershipDuration)}>Přidat</button>
                                                            <button type="button" className="btn btn-danger" onClick={() => this.openCloseModal(false)}>Zavřít</button>
                                                        </div>
                                                    </div>
                                                </Modal>
                                            );
                
                                        }
                                    }
                                    </Mutation>
                                :
                
                                null
                            }
                        </Fragment>
                        );
                    }
                }
            </Query>
            
          
                    
                
        );

    }

}

export default withApollo(Invoice);