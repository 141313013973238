import React,{ Component,Fragment } from 'react';
import ContentEditable from 'react-contenteditable'
import remove from '../../../Public/Images/remove.svg';
import settings from '../../../Public/Images/settings.svg';
import parent from '../../../Public/Images/parent.svg';
import TextFormat from '../Library/textFormat';
import move from '../../../Public/Images/move.svg';

class HeadlineElement extends Component{

    constructor(props){
        super(props);
    }

    componentDidMount(){
        this.tf = new TextFormat(this);
    }

    onPaste(e,type){
        e.preventDefault(); 
        const text = e.clipboardData.getData('text/plain'); 
        if(text != "")this.props.cm.setElementData(type,this.props.lastIndexes,"headline",text,null,true);
    }
    

    render(){

        const {element,showHelpers,cm,lastIndexes,lang} = this.props;
        var parentIndexes = "";
        
        var indexes = lastIndexes.split("-");
        if(indexes.length > 2){
            indexes.pop();
            parentIndexes = indexes.join("-");
        }

        return (
            <div className="cm-headline">

                {showHelpers && element.showMenu &&
                    <div className="cm-menu" onClick={(e) => e.stopPropagation()}>
                        <div className="form-inline">
                            
                            <div className="form-group mr-1">
                                <div className="item-container" onClick={() => cm.showSettings("headline",lastIndexes)}>
                                    <img className="remove-icon" src={settings} alt="Nastavení modulu" title="Nastavení modulu" />
                                </div>
                                {element.showSettings &&
                                    <Fragment>
                                        <div className="settings">
                                            <div className="mb-1">
                                                <select value={element.type} className="form-control" name="type" onChange={(e) => cm.setElementData(e,lastIndexes,"headline")}>
                                                    <option value="h1"> H1 </option>
                                                    <option value="h2"> H2 </option>
                                                    <option value="h3"> H3 </option>
                                                    <option value="h4"> H4 </option>
                                                </select>
                                            </div>
                                            <div className="mb-1">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <label className="input-group-text" htmlFor="inputGroupSelect02">Zarovnání:</label>
                                                    </div>
                                                    <select value={element.textAlign} className="form-control" name="textAlign" onChange={(e) => cm.setElementData(e,lastIndexes,"headline")}>
                                                        <option value="text-left"> vlevo </option>
                                                        <option value="text-center"> centrovaně </option>
                                                        <option value="text-right"> vpravo </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="mb-1">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <label className="input-group-text" htmlFor="inputGroupSelect02">Podnadpis:</label>
                                                    </div>
                                                    <select value={element.hasSubHeadline} className="form-control" name="hasSubHeadline" onChange={(e) => cm.setElementData(e,lastIndexes,"headline")}>
                                                        <option value="1"> Ano </option>
                                                        <option value="0"> Ne </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="mb-1">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <label className="input-group-text">Třída:</label>
                                                    </div>
                                                    <input type="text" value={element.className} className="form-control" name="className" onChange={(e) => cm.setElementData(e,lastIndexes,"headline")}  />
                                                </div>
                                            </div>

                                        </div>
                                        <div className="cm-closing-overlay" onClick={() => cm.showSettings("headline",lastIndexes)}></div>
                                    </Fragment>
                                }
                            </div>
                            {parentIndexes != "" &&
                                <div className="form-group mr-1">
                                    <div className="item-container" onClick={(e) => cm.selectElement(e,cm.columnColType,parentIndexes)}>
                                        <img className="remove-icon" src={parent} alt="Nadřazený element" title="Nadřazený element" />
                                    </div>
                                </div>
                            }
                            
                            <div className="form-group">
                                <div className="item-container" onClick={() => cm.removeElement(lastIndexes)}>
                                    <img className="remove-icon"  src={remove} alt="Odstranit nadpis" title="Odstranit nadpis" />
                                </div>
                            </div>
                        </div>  
                    </div>
                }

                <div className="cm-headline-content" onClick={(e) => {e.stopPropagation(); !element.showMenu && cm.selectElement(e,"headline",lastIndexes)}}>
                    <ContentEditable tagName={element.type} html={element.text[lang]} disabled={false} name="text" onPaste={(e) => this.onPaste(e,"text")} onChange={(evt) => cm.setElementData(evt,lastIndexes,"headline",null,null,true)} className={(element.hasSubHeadline == 1 ? element.textAlign + " has-sub-headline " + element.className : element.textAlign + " " + element.className)} />
                    {element.hasSubHeadline == 1 &&
                        <ContentEditable tagName='p' html={element.subHeadlineText[lang]} disabled={false} name="subHeadlineText" onPaste={(e) => this.onPaste(e,"subHeadlineText")} onChange={(evt) => cm.setElementData(evt,lastIndexes,"headline",null,null,true)} className={element.textAlign + " sub-headline"} />
                    }
                </div>
                {showHelpers && <div className={"cm-border" + (element.showMenu ? " cm-selected" : "")}></div>}
                {showHelpers && 
                    <div className="cm-dragable-item" {...this.props.handle}>
                        <div className="item-container">
                            <img className="move-icon"  src={move} alt="Přesunout element" title="Přesunout element" />
                        </div>
                    </div>
                }

            </div>
        );

    }
}

export default HeadlineElement;