/**
 * soubor se seznamem veškerých graphql dotazů pro kategorie
 */
import gql from 'graphql-tag';

/**
 * vložení kategorie
 */
export const ADD_EDIT_CATEGORY = gql`
    mutation AddEditEventCategory($active:Int,$eventCategoryID:ID, $langs:[EventCategoryLangsInput]){
        addEditEventCategory(active:$active, eventCategoryID:$eventCategoryID,langs:$langs)
    }
`;

/**
 *  Úprava priority kategorie, když se přesouvají položky
 */
export const UPDATE_CATEGORY_PRIORITY = gql`
    mutation updateEventCategoryPriority($eventCategoryID:ID!,$fromIndex:Int!,$toIndex:Int!) {
        updateEventCategoryPriority(eventCategoryID:$eventCategoryID,fromIndex:$fromIndex,toIndex:$toIndex)
    }
`;

/**
 *  Data pro jednu kategorii
 */

export const GET_CATEGORY = gql`
    query EventCategoryWithLangs($eventCategoryID: ID!){
        eventCategoryWithLangs(eventCategoryID:$eventCategoryID){
            eventCategoryID            
            active
            langs{
                lang
                name
                text
                niceName
            }    
        }
        allLanguageMutations(onlyEnabled:true){
            title
            suffix
            file
            generateNiceTitle
            langTitle
            decimal
            priority
            main
        }
    }
`;

/**
 *  Seznam veškerých kategorií - maximlání zanoření je 7
 */
export const GET_CATEGORIES = gql`
    query AllEventCategorys($lang: String!){
        allEventCategorys(lang: $lang){
            eventCategoryID
            name
            niceName
            active
            lang
        }
    }
`;





/**
 * Smazání kategorie.
 */
export const DELETE_CATEGORIES = gql`
    mutation deleteEventCategorys($eventCategoryIDs:ID!) {
        deleteEventCategorys(eventCategoryIDs:$eventCategoryIDs)
        reindexEventCategoryPriority
    }
`;