/**
 * Třía která obsahuje veškeré pomocné funkce, pro práci s přidáváním a editací stavů objednávek
 */

import {GET_EVENT,GET_EVENTS,SEACRH_EVENTS,GET_EVENT_RECOMMENDED, GET_EVENT_MARKETINGS, GET_EVENT_SERVICES,GET_EVENT_FORMS,PUBLISH_EVENT,UNPUBLISH_EVENT} from '../Queries/event';
import Notification from '../../../Library/notification';
import {SERVER_URL} from '../../../Config/index';
const moment             = require("moment");

class Event {

    constructor(parent){
        this.parent = parent;
    }

    
    /**
     * Přidání/Editace
     * @param {*} addEvent funkce z apollo, pomocí které se posílají data na server
     */

    addEvent = async (addEvent,callback = null) => {

        const data = this.parent.state.formData;
        let notify = new Notification();   
        
        if(data.eventCategoryIDs && data.eventCategoryIDs.length > 0){

            if(data.eventDate){
                    
                let langs = [];
                const eventDate = moment(data.eventDate).format("YYYY-MM-DD HH:mm:ss");

                for(const lang of data.langs){

                    langs.push({
                        title:          lang.title,
                        text:           lang.text,
                        lang:           lang.lang
                    });
                }

                if(data.recurring == 0)data.recurringType = 0;
                
                addEvent({
                    variables:{
                        eventID:data.eventID,
                        eventCategoryIDs:data.eventCategoryIDs,
                        eventDate,
                        active: parseInt(data.active),
                        recurring: parseInt(data.recurring),
                        recurringType: parseInt(data.recurringType),
                        langs
                    }
                }).then(responseData => {
                    if(callback){
                        callback(true,responseData);
                    }
                }, errorData =>{
                    if(callback){
                        callback(false,errorData);
                    }
                });

            } else{
                notify.setNotification(null,'Nevybrali jste datum akce.',false,true,this.parent.props.client);
            }
            
        } else{
            notify.setNotification(null,'Akce musí být přiřazena k nějaké kategorii.',false,true,this.parent.props.client);
        }
        
               
    } 
    
    /**
     * při inicializaci vložení nebo úpravy se vytáhnou potřebná data
     */

    async getData(){

        var eventID = 0;
        if(this.parent.props.selectedEventID)eventID = this.parent.props.selectedEventID;
        
        var data = await this.parent.props.client.query({ 
            query: GET_EVENT,
            errorPolicy:"all",
            variables:{eventID:eventID}, 
            fetchPolicy: 'network-only'
        });
        
        if(data.errors){
            this.parent.setState({apolloError:data.errors[0].message});
        }
        
        //kdyz upravujeme zaznam
        if(data.data){                  
            if(data.data.eventWithLangs){  
                                
                let langs = this.completeLangsArray(data.data.eventWithLangs.langs,data.data.allLanguageMutations);    
                
                this.parent.setState({
                    apolloError:"",
                    selectedEventID:eventID,
                    allLanguageMutations: data.data.allLanguageMutations,
                    formData:{...this.parent.state.formData,
                        eventID:                data.data.eventWithLangs.eventID,
                        eventCategoryIDs:       data.data.eventWithLangs.eventCategoryIDs,
                        eventDate:              moment(data.data.eventWithLangs.eventDate).toDate(),
                        active:                 data.data.eventWithLangs.active,
                        recurring:              data.data.eventWithLangs.recurring,
                        recurringType:          data.data.eventWithLangs.recurringType,
                        langs:langs
                    }
                });
            }else{
                this.parent.setState({ apolloError:"",formData:{...this.parent.state.formData,
                    eventCategoryIDs: []
                }});
            }
        }

        //kdyz pridavame zaznam
        if(eventID == 0){
            let langs = this.completeLangsArray([],data.data.allLanguageMutations);     
            this.parent.setState({
                selectedEventID: eventID,
                allLanguageMutations: data.data.allLanguageMutations,
                formData:{...this.parent.state.formData,
                    langs:langs
                }
            });
        }

    }

    completeLangsArray = (langsFromDb,allActiveLanguages) => {
        let langs = langsFromDb;
        for(const l of allActiveLanguages){
            let langSet = false;
            for(const lang of langs){
                if(l.suffix == lang.lang){
                    langSet = true;
                    break;
                }
            }

            if(!langSet){
                langs.push({
                    title:"",                    
                    text:"",
                    lang:l.suffix
                });
            }
        }
        return langs;
    }

    /**
     * Při vložení nebo úpravě zákazníka dojde k update cache a vykreslí se opět seznam veškerých zákazníků
     * @param {Object} cache apollo cahe v které jsou oložena data
     * @param {Object} response položka, která byla vložena nebo upravována
     * @param {Number} selectedID id položky
     * @param {Object} variables parametry posílané do query
     */

    updateList(cache,response,selectedID,variables){

        const { allEvents } = cache.readQuery({ query: GET_EVENTS,variables:variables });
        
        if(!(selectedID && selectedID != 0)){

            const arr = [response.data.addEditEvent,...allEvents]   ;
            
            cache.writeQuery({ 
                query:GET_EVENTS,
                variables:variables,
                data:{
                    allEvents: arr
                } 
            });

        }else{

            var arr = [...allEvents];
            
            arr.forEach((item,index) => {
                
                if(item.eventID == response.data.addEditEvent.eventID){

                    arr[index].eventID          = response.data.addEditEvent.eventID;
                    arr[index].title            = response.data.addEditEvent.title;
                    arr[index].eventDate        = response.data.addEditEvent.eventDate;
                    arr[index].niceTitle        = response.data.addEditEvent.niceTitle;
                    arr[index].lang             = response.data.addEditEvent.lang;
                    arr[index].active           = response.data.addEditEvent.active;
                    arr[index].recurring        = response.data.addEditEvent.recurring;
                    arr[index].recurringType    = response.data.addEditEvent.recurringType;

                }

            });
            cache.writeQuery({ 
                query:GET_EVENTS,
                variables:variables,
                data:{
                    allEvents: arr
                } 
            });
        }
    }    

    /**
     * 
     * @param {*} cache apollo cache, kde jsou uložena data
     * @param {*} response hodnota, která se vrátila po smazání objednávky 
     */

    updateAfterDelete(cache, response,variables){

        var resp = response.data.deleteEvents.split(",");

        const { allEvents } = cache.readQuery({ query: GET_EVENTS ,variables});
        var arr = [...allEvents];

        resp.forEach((it,ind) => {
            arr.forEach((item,index) => {
                if(item.eventID == it){
                    arr = [...arr.slice(0,index),...arr.slice(index + 1)]
                }
            });
        }) 
    
        cache.writeQuery({ 
            query:GET_EVENTS,
            variables,
            data:{
                allEvents: [...arr]
            } 
        });

    }

    /**
     * funkce ukládá data, která byla vložena do formuláře
     * @param {*} e ukazatel na element
     */
    
    formHandle(e){
	  
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        this.parent.setState({formData:{...this.parent.state.formData,[n]: v}});
              
    }

    setEventDate(date){
        this.parent.setState({formData:{...this.parent.state.formData,eventDate: date}});
    }
    
    
    /**
     * funkce ukládá data (jazykove mutace), která byla vložena do formuláře
     * @param {*} e ukazatel na element
     */
    
    formLangHandle(e){
        
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        let langs       = this.parent.state.formData.langs;
        for(const lang of langs){
            if(lang.lang == this.parent.state.formData.selectedLang){
                lang[n] = v;
            }
        }

        this.parent.setState({...this.parent.state,formData:{...this.parent.state.formData,langs: langs}});
         
    }

    /**
     * funkce ukládá data (jazykove mutace), která byla vložena do formuláře přes tinyMCE editor
     * @param {String} data data tinyMCE editoru
     * @param {String} paramName nazev parametru, kteremu nastavujeme textovou hodnotu
     */
    
    formLangHandleEditor(content,paramName){
        
        let langs       = this.parent.state.formData.langs;
        for(const lang of langs){
            if(lang.lang == this.parent.state.formData.selectedLang){
                lang[paramName] = content;
            }
        }

        this.parent.setState({...this.parent.state,formData:{...this.parent.state.formData,langs: langs}});
    }

    /**
     * Vraci hodnotu zadaneho parametru z jazykovych mutaci, podle zadaneho jazyku
     * @param {String} paramName - název proměnné v objektu lang
     * 
     * @returns property value
     */
    getLangValue = (paramName) => {
        for(const lang of this.parent.state.formData.langs){
            if(lang.lang == this.parent.state.formData.selectedLang){
                if(lang[paramName] === null){
                    return "";
                }else{
                    return lang[paramName];
                }
            }
        }
        return "";
    }

    /**
     * Vraci počet znaků zadané proměnné v objektu jazykových mutací lang
     * @param {String} paramName - název proměnné v objektu lang
     * 
     * @returns počet znaků
     */
    getCharCount = (paramName) => {        
        for(const lang of this.parent.state.formData.langs){
            if(lang.lang == this.parent.state.formData.selectedLang){
                return lang[paramName].length;
            }
        }
        return 0;
    }
    
}

export default Event;