/**
 * Třía která obsahuje veškeré pomocné funkce, pro práci s přidáváním a editací zákazníků
 */

 import {GET_CUSTOMERS_EVS_POINTS,GET_CUSTOMER_EVS_POINT} from '../Queries/customer.js';
 import Notification from '../../../Library/notification';
 import Helper from '../../../Library/helper';
 
 class CustomerEVSpoint {
 
     constructor(props){
         this.props = props;
     }
 
     /**
      * při inicializaci přidání zákazníka nebo jeho úpravy vytáhne potřebná data k zákazníkovi
      */
 
     async getData(setFormData,formData){
 
         var customerEVSPointID = 0;
         if(this.props.selectedCustomerEVSPointID)customerEVSPointID = this.props.selectedCustomerEVSPointID;
         
         var data = await this.props.client.query({ 
             query: GET_CUSTOMER_EVS_POINT,
             errorPolicy:"all",
             variables:{customerEVSPointID:customerEVSPointID}, 
             fetchPolicy: 'network-only'
         });
 
         if(data.errors){
             //this.parent.setState({apolloError:data.errors[0].message});
         }
 
         if(data.data){
              
             if(data.data.customerEVSPoint){
                  
                 setFormData({...formData,
                     customerEVSPointID:  data.data.customerEVSPoint.customerEVSPointID,
                     info:                data.data.customerEVSPoint.info,
                     points:              data.data.customerEVSPoint.points
                 })
                      
             }
         }
     }
 
     /**
      * Přidání nebo úprava nového zákazníka
      * @param {*} addCustomer funkce z apollo, pomocí které se posílají data na server
      */
     async addCustomer(addCustomerEVSPoint,formData){
 
         const data = formData;
         let notify = new Notification();   
 
         if(data.info != ""){         
             if(data.points != ""){
                 
                data.month  = parseInt(data.month);
                data.year   = parseInt(data.year);
                data.points = parseInt(data.points);

                addCustomerEVSPoint({
                    variables:data
                });
                    
             }else{
                 notify.setNotification(null,'Nevyplnili jste body',false,true,this.props.client); 
             }
            
         }else{
             notify.setNotification(null,'Nevyplnili jste popis.',false,true,this.props.client);
         }
 
     }
 
     /**
      * Při vložení nebo úpravě zákazníka dojde k update cache a vykreslí se opět seznam veškerých zákazníků
      * @param {*} cache apollo cahe v které jsou oložena data
      * @param {*} response položka, která byla vložena nebo upravována
      */
 
     updateList(cache,response,selectedCustomerEVSPointID,variableList){
 
         const { allCustomerEVSPoints } = cache.readQuery({ query: GET_CUSTOMERS_EVS_POINTS, variables:variableList });
 
         if(!(selectedCustomerEVSPointID && selectedCustomerEVSPointID != 0)){
 
             cache.writeQuery({ 
                 query:GET_CUSTOMERS_EVS_POINTS,
                 variables:variableList,
                 data:{
                    allCustomerEVSPoints: [response.addEditCustomerEVSPoint,...allCustomerEVSPoints]
                 } 
             });
 
         }else{
 
             var arr = [...allCustomerEVSPoints];
             
             arr.forEach((item,index) => {
                 if(item.customerEVSPointID == response.addEditCustomerEVSPoint.customerEVSPointID){
 
                     arr[index].customerID = response.addEditCustomerEVSPoint.customerID;
                     arr[index].info       = response.addEditCustomerEVSPoint.info;
                     arr[index].points     = response.addEditCustomerEVSPoint.points;
                     
                 }
 
             });
 
             cache.writeQuery({ 
                 query:GET_CUSTOMERS_EVS_POINTS,
                 variables:variableList,
                 data:{
                    allCustomerEVSPoints: arr
                 } 
             });
         }
     }
 
     /**
      * Update seznamu zákazníků po smazání vybraných zákazníků
      * @param {*} cache apollo cache, kde jsou uložena data
      * @param {*} response hodnota, která se vrátila po smazání potahu
      */
     
     updateAfterDelete(cache, response,variablesList){
 
         var resp = response.deleteCustomerEVSPoint.split(",");
 
         const { allCustomerEVSPoints } = cache.readQuery({ query: GET_CUSTOMERS_EVS_POINTS,variables:variablesList });
         var arr = [...allCustomerEVSPoints];
 
         resp.forEach((it,ind) => {
             arr.forEach((item,index) => {
                 if(item.customerEVSPointID == it){
                     arr = [...arr.slice(0,index),...arr.slice(index + 1)]
                 }
             });
         })
         
     
         cache.writeQuery({ 
             query:GET_CUSTOMERS_EVS_POINTS,
             variables:variablesList,
             data:{
                allCustomerEVSPoints: [...arr]
             } 
         });
 
     }     
     
     formHandle(e,formData,setFormData){
       
         const t = e.target;
         const v = t.type === 'checkbox' ? t.checked : t.value;
         const n = t.name;
         
         setFormData({...formData,[n]: v})
         
               
     }
 
 }
 
 export default CustomerEVSpoint;