/**
 * Třía která obsahuje veškeré pomocné funkce, pro práci s přidáváním a editací stavů objednávek
 */

import Notification from '../../../Library/notification';
import {GET_VIDEOS, GET_VIDEO} from '../Queries/index.js';

class Video {

    constructor(parent){
        this.parent = parent;
    }

    /**
     * Přidání/Editace videa
     * @param {*} addVideo funkce z apollo, pomocí které se posílají data na server
     */

    addVideo = (addVideo) => {

        const data = this.parent.state.formData;
        let notify = new Notification();   

        if(data.categoryID != 0){ 
                                 
            let langs = [];
            for(const lang of data.langs){
                langs.push({
                    name: lang.name,
                    url: lang.url,
                    emailText: lang.emailText,
                    lang:lang.lang
                });
            }

            addVideo({
                variables:{
                    videoID:data.videoID,
                    categoryID:parseInt(data.categoryID),
                    langs
                }
            });
        
                 
        }else{
            notify.setNotification(null,'Nevybrali jste kategorii.',false,true,this.parent.props.client);
        }
    } 


    /**
     * 
     * @param {*} cache apollo cache, kde jsou uložena data
     * @param {*} response hodnota, která se vrátila po smazání objednávky 
     */

    updateAfterDelete(cache, response){

        var resp = response.data.deleteVideos.split(",");

        const { allVideos } = cache.readQuery({ query: GET_VIDEOS ,variables:{lang:'cz'}});
        var arr = [...allVideos];
        
        resp.forEach((it,ind) => {
            arr.forEach((item,index) => {
                if(item.videoID == it){
                    arr = [...arr.slice(0,index),...arr.slice(index + 1)]
                }
            });
        }) 
    
        cache.writeQuery({ 
            query:GET_VIDEOS,
            variables:{lang:'cz'},
            data:{
                allVideos: [...arr]
            } 
        });

    }
    
    /**
     * při inicializaci přidání kategorie nebo její úpravy vytáhne potřebná data k dopravě
     */

    async getData(){

        var videoID = 0;
        if(this.parent.props.selectedVideoID)videoID = this.parent.props.selectedVideoID;
        
        var data = await this.parent.props.client.query({ 
            query: GET_VIDEO,
            errorPolicy:"all",
            variables:{videoID:videoID}, 
            fetchPolicy: 'network-only'
        });
        
        
        if(data.errors){
            this.parent.setState({apolloError:data.errors[0].message});
        }
        
        //kdyz upravujeme zaznam
        if(data.data){                  
            if(data.data.videoWithLangs){
                let langs = this.completeLangsArray(data.data.videoWithLangs.langs,data.data.allLanguageMutations);                
                langs = JSON.parse(JSON.stringify(langs));

                for(let lang of langs){
                    if(lang.url && lang.url != ""){
                        lang.url = "https://player.vimeo.com/video/" + lang.url;
                    }
                }

                this.parent.setState({
                    apolloError:"",
                    selectedVideoID:videoID,
                    allLanguageMutations: data.data.allLanguageMutations,
                    allCategories:data.data.allAdminCategories,
                    formData:{...this.parent.state.formData,
                        videoID:     data.data.videoWithLangs.videoID,
                        categoryID:  data.data.videoWithLangs.categoryID,
                        langs:langs
                    }
                });
            }else{
                this.parent.setState({ apolloError:""});
            }
        }

        //kdyz pridavame zaznam
        if(videoID == 0){
            let langs = this.completeLangsArray([],data.data.allLanguageMutations);            
            langs = JSON.parse(JSON.stringify(langs));

            this.parent.setState({
                allLanguageMutations: data.data.allLanguageMutations,
                allCategories:data.data.allAdminCategories,
                formData:{...this.parent.state.formData,
                    langs:langs
                }
            });
        }

    }

    completeLangsArray = (langsFromDb,allActiveLanguages) => {
        let langs = langsFromDb;
        for(const l of allActiveLanguages){
            let langSet = false;
            for(const lang of langs){
                if(l.suffix == lang.lang){
                    langSet = true;
                    break;
                }
            }

            if(!langSet){
                langs.push({
                    name:"",
                    url:"",
                    emailText:"",
                    lang:l.suffix
                });
            }
        }
        return langs;
    }

    /**
     * funkce ukládá data, která byla vložena do formuláře
     * @param {*} e ukazatel na element
     */
    
    formHandle(e){
	  
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        this.parent.setState({formData:{...this.parent.state.formData,[n]: v}});
              
    }

    /**
     * funkce ukládá data (jazykove mutace), která byla vložena do formuláře
     * @param {*} e ukazatel na element
     */
    
    formLangHandle(e){
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        let langs       = this.parent.state.formData.langs;

        for(const lang of langs){
            if(lang.lang == this.parent.state.formData.selectedLang){
                lang[n] = v;
            }
        }

        this.parent.setState({...this.parent.state,formData:{...this.parent.state.formData,langs: langs}});
         
    }

    /**
     * funkce ukládá data (jazykove mutace), která byla vložena do formuláře přes tinyMCE editor
     * @param {String} data data tinyMCE editoru
     * @param {String} paramName nazev parametru, kteremu nastavujeme textovou hodnotu
     */
    
    formLangHandleEditor(content,paramName){
        
        let langs       = this.parent.state.formData.langs;

        for(const lang of langs){
            if(lang.lang == this.parent.state.formData.selectedLang){
                lang[paramName] = content;
            }
        }

        this.parent.setState({...this.parent.state,formData:{...this.parent.state.formData,langs: langs}});
    }

    /**
     * Vraci hodnotu zadaneho parametru z jazykovych mutaci, podle zadaneho jazyku
     * @param {String} propertyName - název proměnné v objektu lang
     * 
     * @returns property value
     */
    getLangValue = (propertyName) => {
        for(const lang of this.parent.state.formData.langs){
            if(lang.lang == this.parent.state.formData.selectedLang){
                return lang[propertyName];
            }
        }
        return "";
    }
    

    

}

export default Video;