/**
 * soubor se seznamem veškerých graphql dotazů pro odkazy
 */
 import gql from 'graphql-tag';

 /**
 *  Data pro jednu kategorii
 */

export const GET_ROOMS_SETTINGS = gql`
    query GetRoomsSettings($lang:String){
        settings(lang:$lang){
            openAula 
        }
    }
`;

export const SAVE_ROOMS_SETTINGS = gql`
    mutation GetRoomsSettings($settings:SettingsInput){
        saveRoomsSettings(settings:$settings)
    }
`;