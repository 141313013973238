import React,{ Component,Fragment } from 'react';
import { withApollo,useQuery } from 'react-apollo';
import {GET_CUSTOMER_MATERIAL_DATA} from '../Queries/customer';
import Loading from '../../../GlobalComponents/Loading';
import SubMaterial from './SubMaterial';

const Affiliate = (props) => {

    return(
        
        
        <div className="card main">
            
            <div className="card-header d-flex align-items-center">
                Láskyplný dar
            </div>
            <div className="card-body">

                
                <div className="row">
                    <div className="col-12 col-sm-4">
                        <label className="input-label">Provize z první platby</label>
                        <div className="form-group">
                            <input className="form-control" type="text" name="affiliateProfit" value={props.affiliateProfit} onChange={(e) => props.customerLib.formHandle(e)} />
                        </div>
                    </div>

                    <div className="col-12 col-sm-4">
                        <label className="input-label">Provize z druhé a každé další platby</label>
                        <div className="form-group">
                            <input className="form-control" type="text" name="affiliateProfit2" value={props.affiliateProfit2} onChange={(e) => props.customerLib.formHandle(e)} />
                        </div>
                    </div>   
                </div>               
                
                
            </div>
        </div>
                
                
    );

    

}

export default withApollo(Affiliate);