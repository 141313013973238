import React,{ Component, Fragment,useState } from 'react';
import {ADD_CAMPAIGN_CONTACTS} from '../Queries/campaignContact.js';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import { withApollo, useQuery,useMutation } from 'react-apollo';
import Helper from '../../../Library/helper';
import {UpdateAfterAdd,AddContacts} from '../Library/campaignContact';
import Modal from 'react-modal';
import Notification from '../../../Library/notification';


const AddCampaignContact = (props) => {

    const {campaignID,listVariables,SetShowAddEmails,client} = props;

    const [err,SetErr] = useState("");

    const [formData,SetFormData] = useState({
        contacts:"",
        lang:"cz"
    });
    
    const [AddCampaignContacts,{loading, error}] = useMutation(ADD_CAMPAIGN_CONTACTS,{
        update:(cache, response) => {

            let notify = new Notification();
            if(response.data.addCampaignContacts.error)
            {
                notify.setNotification(null,response.data.addCampaignContacts.error,false,true,client);
                SetShowAddEmails(false);
            }
            
            if(response.data.addCampaignContacts.contacts && response.data.addCampaignContacts.contacts.length > 0)
            {
                UpdateAfterAdd(cache, response,listVariables);
                SetShowAddEmails(false);

                notify.setNotification(null,"Emaily úspěšně vloženy",true,false,client);
            }
            
        },
        onError: (error) => {
            var helper = new Helper();
            SetErr(helper.getApolloErrorText(error));
            setTimeout(()=>{
                SetErr("")
            },3000)
        }
    });

    const FormHandle = (e) => {
       
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        SetFormData({...formData,[n]: v});
              
    }

    return(

        <Modal
            className="Modal__Bootstrap modal-dialog modal-lg"
            closeTimeoutMS={150}
            isOpen={true}
            onRequestClose={() => SetShowAddEmails(false)}
        >
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Přidat emaily</h4>
                    <button type="button" className="close" onClick={() => SetShowAddEmails(false)}>
                    <span aria-hidden="true">x</span>
                    <span className="sr-only">Close</span>
                    </button>
                </div>
                <div className="modal-body">

                    <div>
                        {!loading ?
                                                            
                            <div>

                                {err ?
                                    <Error text={err} />
                                :null}

                                <div className="row">

                                    <div className="col-2">
                                        <label className="input-label">Jazyk emailů</label>
                                        <div className="form-group">
                                            <select className="form-control" name="lang" value={formData.lang} onChange={(e) => FormHandle(e)}>
                                                {props.allLanguageMutations.map((item,index) => {
                                                    return (
                                                        <option key={index}>{item.suffix}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                                                                            
                                    <div className="col-12">
                                        <label className="input-label">Emaily</label>
                                        <div className="form-group">
                                            <input className="form-control" type="text" name="contacts" value={formData.contacts} onChange={(e) => FormHandle(e)} />
                                            <p className="no-top-margin">Chceš li vložit více emailů najednou, odděl je mezerou ;)</p>
                                        </div>
                                    </div>

                                </div>

                            </div>   
                           
                        :
                        <Loading />  
                        }  

                    </div>

                </div>
                <div className="modal-footer"> 
                
                    <button type="button" className="btn btn-primary flex-fill" onClick={() => AddContacts(AddCampaignContacts,formData,campaignID,client)}>Přidat</button>
                    <button type="button" className="btn btn-danger" onClick={() => SetShowAddEmails(false)}>Zavřít</button>
                </div>
            </div>
        </Modal>
                        

    );

    
}

export default withApollo(AddCampaignContact);
