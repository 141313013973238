/**
 * soubor se seznamem veškerých graphql dotazů pro odkazy
 */
import gql from 'graphql-tag';

/**
 * vložení odkazu
 */
export const ADD_EDIT_MATERIAL = gql`
    mutation AddEditMaterial(
        $materialID:ID,
        $parentID: ID, 
        $active:Int, 
        $daysToOpenNewMaterial:Int,
        $langs:[MaterialLangsInput]
    ){
        addEditMaterial(
            materialID:$materialID,
            parentID: $parentID, 
            active:$active, 
            daysToOpenNewMaterial:$daysToOpenNewMaterial,
            langs:$langs
        )
    }
`;

/**
 *  Úprava priority odkazu, když se přesouvají položky
 */
export const UPDATE_MATERIAL_PRIORITY = gql`
    mutation updateMaterialPriority($parentID:ID,$materialID:ID!,$fromIndex:Int!,$toIndex:Int!) {
        updateMaterialPriority(parentID:$parentID,materialID:$materialID,fromIndex:$fromIndex,toIndex:$toIndex)
    }
`;


/**
 *  Data pro jednu kategorii
 */

export const GET_MATERIAL = gql`
    query MaterialWithLangs($materialID: ID!){
        materialWithLangs(materialID:$materialID){
            materialID
            active
            daysToOpenNewMaterial
            parentID
            langs{
                lang
                name
                description
                niceName
            }    
        }
        allLanguageMutations(onlyEnabled:true){
            languageID
            title
            suffix
            generateNiceTitle
            langTitle
            decimal
            priority
            main
            currencyTitle
        }
    }
`;

/**
 *  Seznam veškerých kategorií - maximlání zanoření je 7
 */
export const GET_MATERIALS = gql`
    query AllMaterials($lang: String){
        allMaterials(lang:$lang){
            materialID
            name
            active
            parentID
            subMaterial{
                materialID
                name
                active
                parentID
                subMaterial{
                    materialID
                    name
                    active
                    parentID
                    subMaterial{
                        materialID
                        name
                        active
                        parentID
                        subMaterial{
                            materialID
                            name
                            active
                            parentID
                            subMaterial{
                                materialID
                                name
                                active
                                parentID
                                subMaterial{
                                    materialID
                                    name
                                    active
                                    parentID
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

/**
 * Smazání kategorie.
 */
export const DELETE_MATERIALS = gql`
    mutation deleteMaterial($materialID:ID!,$parentID:ID) {
        deleteMaterial(materialID:$materialID)
        reindexMaterialPriority(parentID:$parentID)
    }
`;
