import React,{ Component } from 'react';
import TinyMCEFileManager from './TinyMCEFileManager';
import { Editor } from '@tinymce/tinymce-react';

const INITIAL_STATE = {
    
    showManager:false,
    tinyMCECallBack:"",
    tinyMCEFileType:""

}


class TinyMCEEditor extends Component {

    constructor(props){

        super(props);
        this.state = INITIAL_STATE;
        this.hideManager = this.hideManager.bind(this);
        
    }

    hideManager(){
        this.setState({
            showManager:false
        })
    }

    render(){

        var myServices = "";
        const {showManager,tinyMCECallBack,tinyMCEFileType} = this.state;
        const {allServices,allForms} = this.props;

        if(allServices && allServices.length > 0) myServices = " myservices |";
        if(allForms && allForms.length > 0) myServices += " allforms |";

        return(
            <div>

            <Editor
                name="description"
                apiKey = '9z3phhnyq7jkeeqztql1euovfotvpd2gdduygtdpk14wu4ij'
                value={this.props.initialValue}
                init={{
                    plugins: 'link image code paste emoticons media lists',
                    toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | formatselect |' + myServices + '  numlist bullist checklist | link | blockquote | image | media | emoticons | code',
                    paste_as_text: true,
                    language_url : '/langs/cs.js',
                    language: "cs",
                    height:1000,
                    image_advtab: true,
                    link_assume_external_targets: true,
                    file_picker_types: 'file image',
                    file_picker_callback: (callback, value, meta) => {
                        
                        if(meta.filetype == "image" || meta.filetype == "file"){
                            this.setState({showManager:true,tinyMCECallBack:callback, tinyMCEFileType:meta.filetype}) 
                        }

                    },
                    setup:(editor) => {

                        if(allServices && allServices.length > 0){


                            /* example, adding a toolbar menu button */
                            editor.ui.registry.addMenuButton('myservices', {
                                text: 'Služby',
                                fetch: function (callback) {
                                    var items = [];

                                    for(let i = 0; i < allServices.length; i++){

                                        items.push({
                                            type: 'menuitem',
                                            text: allServices[i].name,
                                            onAction: function () {
                                                editor.insertContent('<p>[---------s:' + allServices[i].serviceID + '@~@' + allServices[i].name + '---------]</p><p></p>');
                                            }
                                        })

                                    }

                                    callback(items);
                                }
                            });

                        }

                        if(allForms && allForms.length > 0){

                            /* example, adding a toolbar menu button */
                            editor.ui.registry.addMenuButton('allforms', {
                                text: 'Formuláře',
                                fetch: function (callback) {
                                    var items = [];

                                    for(let i = 0; i < allForms.length; i++){

                                        items.push({
                                            type: 'menuitem',
                                            text: allForms[i].name,
                                            onAction: function () {
                                                editor.insertContent('<p>[---------f:' + allForms[i].formID + '@~@' + allForms[i].name + '---------]</p><p></p>');
                                            }
                                        })

                                    }

                                    callback(items);
                                }
                            });

                        }
                    }
                        
                }}
                onEditorChange={(content) => this.props.onChange(content,"text")}
            />
            {showManager && 
                <TinyMCEFileManager callback={this.hideManager} tinyMCECallBack = {tinyMCECallBack} tinyMCEFileType={tinyMCEFileType} />
            }
            </div>
        )

    }

}

export default TinyMCEEditor;  