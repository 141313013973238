/*
    Přidání kategorie
*/
import React,{ Component,Fragment } from 'react';
import {Mutation,Query} from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import {SAVE_ROOMS_SETTINGS} from '../Queries/roomsSettings';
import { withApollo } from 'react-apollo';
import Notification from '../../../Library/notification';
import Helper from '../../../Library/helper';
import RoomsSettingsLib from '../Library/roomsSettings';

const INITIAL_STATE = {
    err:"",
    allLanguageMutations:[],
    formData:{
        openAula:"0"
    }
}

class RoomsSettings extends Component{

    constructor(props){
        super(props);
        this.state = INITIAL_STATE;
        
        this.roomsSettingsLib = new RoomsSettingsLib(this); 
    }

    componentDidMount = () => {
        
        this.roomsSettingsLib.getData();
    }


    render(){

        var {err,formData} = this.state;

        return(

            <Mutation 
                mutation={SAVE_ROOMS_SETTINGS}
                errorPolicy = "all"
                update = {async (cache, response) => {

                    let notify = new Notification();
                    notify.setNotification(cache,'Úspěšně uloženo',true,false);

                }}
                
            >
            {
                (saveRoomsSettings,{loading, error}) => {

                    const {selectedLang,steps} = this.state.formData;
                    const {selectedMaterialID} = this.props;

                    if(error){
                        const helper = new Helper(); 
                        err = helper.getApolloErrorText(error);
                    }

                    return(

                        <div id="settings" className="whole-container" >
            	
                        <div className="row" >
                            <div className="col-sm one">
                        
                            <div className="card main">
                                <div className="card-header d-flex align-items-center">
                                    <h4 className="modal-title">Nastavení místností</h4>
                                </div>
                                <div className="card-body">

                                    {err ?
                                        <Error text={err} />
                                    :
                                        <Fragment>
                                            {!loading ?
                                            <>

                                                <div className="row">

                                                    <div className="col-12 col-sm-3">
                                                        <div className="form-group">
                                                            <label className="input-label">Otevřít aulu pro všechny registrované</label>
                                                            <div className="form-group">
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" name="openAula" id="openAula1" value="1" checked={(formData.openAula == "1" ?  true : false)} onChange={(e) => this.roomsSettingsLib.formHandle(e)} />
                                                                    <label className="form-check-label" htmlFor="openAula1">
                                                                        Ano
                                                                    </label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" name="openAula" id="openAula2" value="0" checked={(formData.openAula == "0" ?  true : false)} onChange={(e) => this.roomsSettingsLib.formHandle(e)} />
                                                                    <label className="form-check-label" htmlFor="openAula2">
                                                                        Ne
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div> 
                                                    </div>

                                                </div>
                                                
                                            </>

                                            :

                                            <Loading />  

                                            }  
                                        </Fragment>
                                    }
                                            
                                </div>
                                <div className="modal-footer"> 
                                    {!loading && <button type="button" className="btn btn-primary flex-fill" onClick={() => this.roomsSettingsLib.saveRoomsSettings(saveRoomsSettings)}>Upravit</button>}
                                </div>
                            </div>

                            </div>
                            </div>
                        </div>
                        
                    );

                }
            }
            </Mutation>
        
        );

    }

}

export default withApollo(RoomsSettings);