/**
 * soubor se seznamem veškerých graphql dotazů pro kategorie
 */
import gql from 'graphql-tag';

/**
 * vložení kategorie
 */
export const ADD_EDIT_CATEGORY = gql`
    mutation AddEditCategory($active:Int,$categoryID:ID, $langs:[CategoryLangsInput]){
        addEditCategory(active:$active, categoryID:$categoryID,langs:$langs)
    }
`;

/**
 *  Úprava priority kategorie, když se přesouvají položky
 */
export const UPDATE_CATEGORY_PRIORITY = gql`
    mutation updateCategoryPriority($categoryID:ID!,$fromIndex:Int!,$toIndex:Int!) {
        updateCategoryPriority(categoryID:$categoryID,fromIndex:$fromIndex,toIndex:$toIndex)
    }
`;

/**
 *  Data pro jednu kategorii
 */

export const GET_CATEGORY = gql`
    query CategoryWithLangs($categoryID: ID!){
        categoryWithLangs(categoryID:$categoryID){
            categoryID            
            active
            langs{
                lang
                name
                niceName
            }    
        }
        allLanguageMutations(onlyEnabled:true){
            title
            suffix
            file
            generateNiceTitle
            langTitle
            decimal
            priority
            main
        }
    }
`;

/**
 *  Seznam veškerých kategorií - maximlání zanoření je 7
 */
export const GET_CATEGORIES = gql`
    query AllAdminCategories($lang: String!){
        allAdminCategories(lang: $lang){
            categoryID
            name
            niceName
            active
            lang
        }
    }
`;





/**
 * Smazání kategorie.
 */
export const DELETE_CATEGORIES = gql`
    mutation deleteCategories($categoryIDs:ID!) {
        deleteCategories(categoryIDs:$categoryIDs)
        reindexPriority
    }
`;