/**
 * Třía která obsahuje veškeré pomocné funkce, pro práci s tinyMCE
 */
import {GET_TINYMCE_IMAGES,GET_TINYMCE_FILES} from '../Queries/index';

import image from '../../../Public/Images/Icons/image.svg';
import pdf from '../../../Public/Images/Icons/pdf.svg';
import xml from '../../../Public/Images/Icons/xml.svg';
import doc from '../../../Public/Images/Icons/doc.svg';
import xls from '../../../Public/Images/Icons/xls.svg';
import txt from '../../../Public/Images/Icons/txt.svg';
import zip from '../../../Public/Images/Icons/zip.svg';
import ppt from '../../../Public/Images/Icons/ppt.svg';

class TinyMCElib{

    constructor(parent,initialState){
        this.parent = parent;
        this.initialState = initialState;
    }

    getIcon(type){

        var icon = "";

        switch(type){

            case "image": icon = image; break;
            case "pdf": icon = pdf; break;
            case "xml": icon = xml; break;
            case "doc": icon = doc; break;
            case "xls": icon = xls; break;
            case "txt": icon = txt; break;
            case "zip": icon = zip; break;
            case "ppt": icon = ppt; break;

        }

        return icon;

    }

    updateUploadedImagesAfterDelete(cache, response){

        const { allTinyMCEImages } = cache.readQuery({ query: GET_TINYMCE_IMAGES });
        var arr = [...allTinyMCEImages];

        for(let i in response.data.deleteTinyMCEImages){
            for(let j in arr){
                if(response.data.deleteTinyMCEImages[i] == arr[j].imageID){
                    arr.splice(j,1);
                }
            }
        }

        cache.writeQuery({ 
            query:GET_TINYMCE_IMAGES,
            data:{
                allTinyMCEImages: arr
            } 
        });

    }

    updateUploadedFilesAfterDelete(cache, response){

        const { allTinyMCEFiles } = cache.readQuery({ query: GET_TINYMCE_FILES });
        var arr = [...allTinyMCEFiles];

        for(let i in response.data.deleteTinyMCEFiles){
            for(let j in arr){
                if(response.data.deleteTinyMCEFiles[i] == arr[j].fileID){
                    arr.splice(j,1);
                }
            }
        }

        cache.writeQuery({ 
            query:GET_TINYMCE_FILES,
            data:{
                allTinyMCEFiles: arr
            } 
        });

    }

    updateUploadedImages(cache, response){

        const { allTinyMCEImages } = cache.readQuery({ query: GET_TINYMCE_IMAGES });
        var arr = [...response.data.uploadTinyMCEImages,...allTinyMCEImages];

        cache.writeQuery({ 
            query:GET_TINYMCE_IMAGES,
            data:{
                allTinyMCEImages: arr
            } 
        });

    }

    updateUploadedFiles(cache, response){

        const { allTinyMCEFiles } = cache.readQuery({ query: GET_TINYMCE_FILES });
        var arr = [...response.data.uploadTinyMCEFiles,...allTinyMCEFiles];

        cache.writeQuery({ 
            query:GET_TINYMCE_FILES,
            data:{
                allTinyMCEFiles: arr
            } 
        });

    }

    selectFile(tinyMCECallBack,file){
        tinyMCECallBack(file);

        this.parent.props.callback(false);
    }

    setDeleteFile(e,imageID){

        if(e.target.checked){
            this.parent.setState({
                selectedFiles:[imageID,...this.parent.state.selectedFiles]
            });
        }else{
            
            var arr = [...this.parent.state.selectedFiles];
            for(let i in arr){
                if(arr[i] == imageID)arr.splice(i,1);
            }

            this.parent.setState({
                selectedFiles:arr
            });
        }

    }

    deleteTinyMCEData(deleteTinyMCEData){
        
        this.parent.setState({
            showDeleteInfo:true,
            deleteTinyMCEData
        })
    }

    delTinyMCEFile(action,fileType){
        if(action){
            if(fileType == "image"){
                this.parent.state.deleteTinyMCEData({
                    variables:{
                        imagesID: this.parent.state.selectedFiles
                    }
                })
            }else{
                this.parent.state.deleteTinyMCEData({
                    variables:{
                        filesID: this.parent.state.selectedFiles
                    }
                })
            }
        }
        this.parent.setState(this.initialState);
	}

}

export default TinyMCElib;