import {GET_AFFILIATE_DATA} from '../Queries/affiliate';
import {GET_CUSTOMERS,GET_CUSTOMER_PAYMENTS} from '../../Customer/Queries/customer';
import Notification from '../../../Library/notification';

class Affiliate {

    constructor(props){
        this.props = props;
    }

    async getData(formData,setFormdata){

        var data = await this.props.client.query({
            query:GET_AFFILIATE_DATA,
            fetchPolicy: 'network-only',
            variables:{
                lang:formData.selectedLang
            }
        })

        if(data){

            for(let val of data.data.settingsWithLang.langs){
                delete(val["__typename"]);
            }

            let langs = [];
            for(let val of data.data.allCustomerTypeWithLang){

                langs["ct" + val.customerTypeID] = {
                    langs:this.completeLangsArray(val.langs,data.data.allLanguageMutations)
                }
                
            }
            
            setFormdata({...formData, 
                allLanguageMutations: data.data.allLanguageMutations,
                affiliateTotalAmountToPaidOff: data.data.affiliateTotalAmountToPaidOff,
                allAffiliateCustomers: data.data.allAffiliateCustomers,
                allCustomerTypeWithLang: data.data.allCustomerTypeWithLang,
                langs
            });
        }
    }

    async getCustomers(text,type,formData,setFormdata){

        if(text != ""){

            var data = await this.props.client.query({
                query:GET_CUSTOMERS,
                variables:{
                    offset:0, 
                    limit:100000,
                    text:text
                },
                fetchPolicy: 'network-only'
            });

            if(data){
                
                if(type == "who"){
                    setFormdata({...formData, 
                        allCustomers: data.data.allCustomers,
                        searchText: text
                    });
                }else{
                    setFormdata({...formData, 
                        allParents: data.data.allCustomers,
                        searchTextForWho:text
                    });
                }
            }
        }else{
            if(type == "who"){
                setFormdata({...formData, 
                    allCustomers: [],
                    searchText: ""
                });
            }else{
                setFormdata({...formData, 
                    allCustomers: [],
                    searchText: ""
                });
            }
        }


    }

    async selectCutomer(item,formData,setFormdata){

        setFormdata({...formData,loadingCustomerPayments:true});

        var data = await this.props.client.query({
            query:GET_CUSTOMER_PAYMENTS,
            variables:{
                customerID:item.customerID
            },
            fetchPolicy: 'network-only'
        });

        var paymentsList = [];
        if(data){
            paymentsList = data.data.oneCustomer.customerPayments
        }

        setFormdata({...formData,searchText:"",paymentsList,loadingCustomerPayments:false,allCustomers:[],selectedPaymentIDs:[],selectedCustomer:item});
    }
    

    removeSelectedParent(customer,formData,setFormdata){

        var arr = [...formData.selectedParents];

        for(let i in arr){
            if(customer == arr[i]){
                arr.splice(i,1);
            }
        }

        setFormdata({...formData,selectedParents:arr});

    }

    selectPayment(customerPaymentID,formData,setFormdata,paymentFrom){

        var obj = {
            customerPaymentID,
            commisionInAffiliateFrom: (paymentFrom ? paymentFrom : "1")
        }

        if(formData.selectedPaymentIDs.length > 0){

            var arr = [...formData.selectedPaymentIDs];
            var check = false;

            for(let i in arr){
                if(arr[i].customerPaymentID == customerPaymentID){
                    check = true;
                    if(paymentFrom){
                        arr[i].commisionInAffiliateFrom = paymentFrom;
                    }else{
                        arr.splice(i,1);
                    }
                }
            }

            if(!check){
                setFormdata({...formData,selectedPaymentIDs:[obj,...formData.selectedPaymentIDs]});
            }else{
                setFormdata({...formData,selectedPaymentIDs:arr});
            }

        }else{
            setFormdata({...formData,selectedPaymentIDs:[obj]})
        }
    }

    addAffiliate(addAffiliate,formData){

        if(formData.selectedCustomer && formData.selectedCustomer.email){

            if(formData.selectedParents.length > 0){

                var selectedParents = [];
                for(let val of formData.selectedParents){
                    selectedParents.push(val.customerID);
                }

                addAffiliate({
                    variables:{
                        selectedCustomer:  formData.selectedCustomer.customerID,
                        selectedParents:   selectedParents,
                        selectedPayments:  formData.selectedPaymentIDs
                    }
                });

            }else{
                let notify = new Notification();
                notify.setNotification(null,'Nevybrali jste pod koho se má dotyčný začlenit.',false,true,this.props.client);
            }

        }else{
            let notify = new Notification();
            notify.setNotification(null,'Nevybrali jste kdo má být začleněn do daru.',false,true,this.props.client);
        }

    }

    saveCustomerTypes(formData,saveCustomerTypes){

        var data = [];

        for(let i in formData.langs){

            for(let j in formData.langs[i].langs){

                data.push({
                    customerTypeID: i.replace("ct",""),
                    lang:formData.langs[i].langs[j].lang,
                    affiliateProfit: parseFloat(formData.langs[i].langs[j].affiliateProfit),
                    affiliateProfit2: parseFloat(formData.langs[i].langs[j].affiliateProfit2),
                    anualAffiliateProfit: parseFloat(formData.langs[i].langs[j].anualAffiliateProfit),
                });

            }
   
        }

        console.log(data);

        saveCustomerTypes({
            variables:{
                data
            }
        })

    }

    saveAffiliateSettings(formData,saveAffiliateSettings){

        saveAffiliateSettings({
            variables:{
                langs:formData.langs
            }
        })

    }

    formHandle(e,formData,setFormdata){
	  
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        setFormdata({...formData,[n]: v});
              
    }
  
    
    /**
     * funkce ukládá data (jazykove mutace), která byla vložena do formuláře
     * @param {*} e ukazatel na element
     */
    
    formLangHandle(e,formData,setFormdata,customerTypeID){
        
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        let langs = formData.langs;

        console.log(customerTypeID);
  
        for(const lang of langs["ct" + customerTypeID].langs){
            if(lang.lang == formData.ctSelectedLang){
                lang[n] = v;
            }
        }  

        setFormdata({...formData,langs: langs});
         
    }

    /**
     * Vraci hodnotu zadaneho parametru z jazykovych mutaci, podle zadaneho jazyku
     * @param {String} paramName - název proměnné v objektu lang
     * 
     * @returns property value
     */
    getLangValue = (paramName,formData,customerTypeID) => {

        if(formData.langs["ct" + customerTypeID]){
            for(const lang of formData.langs["ct" + customerTypeID].langs){
                if(lang.lang == formData.ctSelectedLang){
                    if(lang[paramName] === null){
                        return "";
                    }else{
                        return lang[paramName];
                    }
                }
            }
        }
        return "";
    }

    completeLangsArray = (langsFromDb,allActiveLanguages) => {

        let langs = langsFromDb;
        for(const l of allActiveLanguages){
            let langSet = false;
            for(const lang of langs){
                if(l.suffix == lang.lang){
                    langSet = true;
                    break;
                }
            }

            if(!langSet){
                langs.push({
                    affiliateProfit:0,
                    affiliateProfit2:0,
                    anualAffiliateProfit:0,
                    lang:l.suffix
                });
            }
        }
        return langs;
    }
    
}

export default Affiliate;