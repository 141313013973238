/**
 * soubor se seznamem veškerých graphql dotazů pro odkazy
 */
 import gql from 'graphql-tag';

 /**
  * vložení odkazu
  */
 export const ADD_EDIT_WELCOME_PAGE = gql`
     mutation AddEditWelcomePage(
         $steps:[WelcomePageInputSteps]
     ){
         addEditWelcomePage(steps:$steps)
     }
 `;

 /**
 *  Data pro jednu kategorii
 */

export const GET_WELCOME_PAGE = gql`
    query WelcomePageWithLangs{
        welcomePageWithLangs{
            customerWelcomePageID
            active
            langs{
                lang
                text
                text2
                buttonText
                videoUrl
                videoDesc
            }    
        }
        allLanguageMutations(onlyEnabled:true){
            languageID
            title
            suffix
            generateNiceTitle
            langTitle
            decimal
            priority
            main
            currencyTitle
        }
    }
`;