/*
    Přidání kategorie
*/
import React,{ Component,Fragment } from 'react';
import {Mutation,Query} from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import Modal from 'react-modal';
import { Editor } from '@tinymce/tinymce-react';
import {ADD_EDIT_CATEGORY,GET_CATEGORIES,GET_CATEGORY} from '../Queries/index.js';
import SelectSubCategory from './SelectSubCategory';
import { withApollo } from 'react-apollo';
import Notification from '../../../Library/notification';
import Helper from '../../../Library/helper';
import CategoryLib from '../Library/category';
import TinyMCEEditor from '../../TinyMCEFileManager/Components/TinyMCEEditor';


const INITIAL_STATE = {
    err:"",
    formData:{
        active:1,
        eventCategoryID:0,
        langs:[],
        selectedLang:'cz'
    },
    allLanguageMutations:[],
    allFilterCategories:[]
}

class AddEditCategory extends Component{

    constructor(props){
        super(props);
        this.state = INITIAL_STATE;
        this.addCategory     = this.addCategory.bind(this);
        
        this.categoryLib = new CategoryLib(this); 
    }

    componentDidMount(){
        this.categoryLib.getData();
    }

    addCategory(addCategory){

        let langs = [];
        for(const lang of this.state.formData.langs){
            langs.push({
                name: lang.name,
                text: lang.text,
                lang:lang.lang
            });
        }

        addCategory({
            variables:{
                active:parseInt(this.state.formData.active),
                eventCategoryID:this.state.formData.eventCategoryID,
                langs
            }
        });
        

    }

    render(){

        var {err} = this.state;

        return(

            <Mutation 
                mutation={ADD_EDIT_CATEGORY}
                errorPolicy = "all"
                refetchQueries = {(data)=>{
                    return [{
                        query:GET_CATEGORIES,
                        variables: { lang:"cz" }
                    }]
                }}
                update = {async (cache, response) => {

                    let notify = new Notification();

                    if(this.props.selectedCategoryID && this.props.selectedCategoryID != 0) notify.setNotification(cache,'Úspěšně uloženo',true,false);
                    else notify.setNotification(cache,'Úspěšně přidáno',true,false);

                }}

                onCompleted = {() => {
                    this.props.openCloseModal(false);
                }}
                
            >
            {
                (addEditCategory,{loading, error}) => {

                    const {langs,active,selectedLang} = this.state.formData;
                    const {allLanguageMutations} = this.state;
                    
                    if(error){
                        const helper = new Helper(); 
                        err = helper.getApolloErrorText(error);
                    }

                    return(
                        <Modal
                            className="Modal__Bootstrap modal-dialog modal-lg"
                            closeTimeoutMS={150}
                            isOpen={true}
                            onRequestClose={() => this.props.openCloseModal(false)}
                        >
                            <div className="modal-content">
                                <div className="modal-header d-flex align-items-center">
                                    <h4 className="modal-title">{(this.props.selectedCategoryID && this.props.selectedCategoryID != 0 ? "Úprava kategorie" : "Přidání kategorie")}</h4>
                                    <div className="ml-auto">
                                        <select className="form-control" name="selectedLang" onChange={(e) => this.categoryLib.formHandle(e)}>
                                            {allLanguageMutations && allLanguageMutations.map((item,index) => (
                                                <option key={index} value={item.suffix}> {item.suffix} </option>
                                            ))}
                                        </select>
                                    </div>
                                    <button type="button" className="close ml-3" onClick={() => this.props.openCloseModal(false)}>                                    
                                        <span aria-hidden="true">x</span>
                                        <span className="sr-only">Close</span>
                                    </button>
                                </div>
                                <div className="modal-body">

                                    {err ?
                                        <Error text={err} />
                                    :
                                        <Fragment>
                                            {!loading ?
                                            <div className="row">

                                                <div className="col-12">
                                                    <label className="input-label">Aktivní</label>
                                                    <div className="form-group">
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" name="active" id="active1" value="1" checked={(active == 1 ?  true : false)} onChange={(e) => this.categoryLib.formHandle(e)} />
                                                            <label className="form-check-label" htmlFor="active1">
                                                                Ano
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" name="active" id="active2" value="0" checked={(active == 0 ?  true : false)} onChange={(e) => this.categoryLib.formHandle(e)} />
                                                            <label className="form-check-label" htmlFor="active2">
                                                                Ne
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                                        
                                                <div className="col-12 col-sm-6">
                                                    <label className="input-label">Název kategorie ({selectedLang})</label>
                                                    <div className="form-group">
                                                        <input className="form-control" type="text" name="name" value={this.categoryLib.getLangValue("name")} onChange={(e) => this.categoryLib.formLangHandle(e)} />
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label className="input-label">Popis kategorie ({selectedLang})</label>
                                                        <div className="form-group">
                                                            <TinyMCEEditor onChange={(content,type) => this.categoryLib.formLangHandleEditor(content,type)} initialValue={this.categoryLib.getLangValue("text")} /> 
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>

                                            :

                                            <Loading />  

                                            }  
                                        </Fragment>
                                    }
                                            
                                </div>
                                <div className="modal-footer"> 
                                    {!loading && <button type="button" className="btn btn-primary flex-fill" onClick={() => this.addCategory(addEditCategory)}>{(this.props.selectedCategoryID && this.props.selectedCategoryID != 0 ? "Upavit" : "Přidat")}</button>}
                                    <button type="button" className="btn btn-danger" onClick={() => this.props.openCloseModal(false)}>Zavřít</button>
                                </div>
                            </div>
                        </Modal>
                    );

                }
            }
            </Mutation>
        
        );

    }

}

export default withApollo(AddEditCategory);