/**
 * Třía která obsahuje veškeré pomocné funkce, pro práci s přidáváním a editací stavů objednávek
 */

 import {GET_EVS_DATA} from '../Queries/eliteVS';
 
 class WelcomePage {
 
     constructor(parent){
         this.parent = parent;
     }
 

     /**
      * Přidání/Editace
      * @param {*} addMaterial funkce z apollo, pomocí které se posílají data na server
      */
 
      addEvsData = async (addEditEvsData,callback = null) => {
 
         const data = this.parent.state.formData;
             
         let langs = [];
         for(const lang of data.langs){

            langs.push({
                mainText:    lang.mainText,
                description: lang.description,
                lang:        lang.lang
            });
        }      
 
        addEditEvsData({
            variables:{data:{
                evsDataID: data.evsDataID,
                langs: langs
            }}
         }).then(responseData => {
             if(callback){
                 callback(true,responseData);
             }
         }, errorData =>{
             if(callback){
                 callback(false,errorData);
             }
         });
       
    } 


     /**
     * funkce ukládá data po zmene jazykove mutace ve formulari, která byla vložena do formuláře
     * @param {*} e ukazatel na element
     */
    
    formLangObjectHandle(e){
	  
        const t = e.target;
        const v = t.value;
        
        for(const lang of this.parent.state.allLanguageMutations){
            if(lang.languageID == v){
                this.parent.setState({formData:{...this.parent.state.formData,selectedLang: lang.suffix,selectedCurrency:lang.currencyTitle}});
            }
        }

              
    }
 
     
     /**
      * při inicializaci vložení nebo úpravy se vytáhnou potřebná data
      */
 
     async getData(){
         
         var data = await this.parent.props.client.query({ 
             query: GET_EVS_DATA,
             errorPolicy:"all", 
             fetchPolicy: 'network-only'
         });
         
         
         if(data.errors){
             this.parent.setState({apolloError:data.errors[0].message});
         }

         //kdyz upravujeme zaznam
         if(data.data){                  
             if(data.data.evsDataWithLangs){ 

                let langs = this.completeLangsArray(data.data.evsDataWithLangs[0].langs,data.data.allLanguageMutations);    

                this.parent.setState({
                    apolloError:"",
                    allLanguageMutations: data.data.allLanguageMutations,
                    formData:{...this.parent.state.formData,
                        langs:      langs
                    }
                });

             }else{
                 this.parent.setState({ apolloError:""});
             }
         }
 
     }
 
     completeLangsArray = (langsFromDb,allActiveLanguages) => {
        
        let langs = langsFromDb;

        for(const l of allActiveLanguages){
            let langSet = false;

            if(langs){
                for(const lang of langs){
                    if(l.suffix == lang.lang){
                        langSet = true;
                        break;
                    }
                }
            }else{
                langs = [];
            }

            if(!langSet){
                langs.push({
                    mainText:"",  
                    description:"",
                    lang:l.suffix
                });
            }
        }
        
         return langs;
     }
 
      
     /**
      * funkce ukládá data, která byla vložena do formuláře
      * @param {*} e ukazatel na element
      */
     
     formHandle(e){
       
         const t = e.target;
         const v = t.type === 'checkbox' ? t.checked : t.value;
         const n = t.name;
         
         this.parent.setState({formData:{...this.parent.state.formData,[n]: v}});
               
     }
     /**
      * funkce ukládá data po zmene jazykove mutace ve formulari, která byla vložena do formuláře
      * @param {*} e ukazatel na element
      */
     
     
     /**
      * funkce ukládá data (jazykove mutace), která byla vložena do formuláře
      * @param {*} e ukazatel na element
      */
     
    formLangHandle(e){
        
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        let langs       = this.parent.state.formData.langs;
        for(const lang of langs){
            if(lang.lang == this.parent.state.formData.selectedLang){
                lang[n] = v;
            }
        }

        this.parent.setState({...this.parent.state,formData:{...this.parent.state.formData,langs: langs}});
         
    }
 
     /**
      * funkce ukládá data (jazykove mutace), která byla vložena do formuláře přes tinyMCE editor
      * @param {String} data data tinyMCE editoru
      * @param {String} paramName nazev parametru, kteremu nastavujeme textovou hodnotu
      */
     
      formLangHandleEditor(content,paramName){

        if(paramName == "text")paramName = "description";
        
        let langs       = this.parent.state.formData.langs;
        for(const lang of langs){
            if(lang.lang == this.parent.state.formData.selectedLang){
                lang[paramName] = content;
            }
        }

        this.parent.setState({...this.parent.state,formData:{...this.parent.state.formData,langs: langs}});
    }
 
     /**
      * Vraci hodnotu zadaneho parametru z jazykovych mutaci, podle zadaneho jazyku
      * @param {String} paramName - název proměnné v objektu lang
      * 
      * @returns property value
      */
      getLangValue = (paramName) => {
        for(const lang of this.parent.state.formData.langs){
            if(lang.lang == this.parent.state.formData.selectedLang){
                if(lang[paramName] === null){
                    return "";
                }else{
                    return lang[paramName];
                }
            }
        }
        return "";
    }
 
 
     
 }
 
 export default WelcomePage;