/*
    Přidání kategorie
*/
import React,{ Component,Fragment } from 'react';
import {Mutation,Query} from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import Modal from 'react-modal';
import TinyMCEEditor from '../../TinyMCEFileManager/Components/TinyMCEEditor';
import {ADD_EDIT_CAMPAIGN_EMAIL} from '../Queries/campaignEmail';
import { withApollo } from 'react-apollo';
import Notification from '../../../Library/notification';
import Helper from '../../../Library/helper';
import CampaignEmailLib from '../Library/campaignEmail';


const INITIAL_STATE = {
    err:"",
    formData:{
        campaignEmailID:0,
        testEmail:"sidris@laskyplnysvet.cz",
        hours:0,
        langs:[],
        selectedLang:'cz'
    },
    allLanguageMutations:[]
}

class AddEditCampaignEmail extends Component{

    constructor(props){
        super(props);
        this.state = INITIAL_STATE;
        
        this.campaignEmailLib = new CampaignEmailLib(this); 
    }

    componentDidMount(){
        this.campaignEmailLib.getData();
    }    

    render(){

        var {err,formData} = this.state;
        var {selectedCampaignEmailID,listVariables} = this.props;

        return(

            <Mutation 
                mutation={ADD_EDIT_CAMPAIGN_EMAIL}
                errorPolicy = "all"
                update = {async (cache, response) => {
                    this.campaignEmailLib.updateList(cache,response,selectedCampaignEmailID,listVariables);

                    let notify = new Notification();

                    if(selectedCampaignEmailID && selectedCampaignEmailID != 0) notify.setNotification(cache,'Úspěšně uloženo',true,false);
                    else notify.setNotification(cache,'Úspěšně přidáno',true,false);

                }}

                onCompleted = {() => {
                    this.props.openCloseModal(false);
                }}
                
            >
            {
                (addEditCampaignEmail,{loading, error}) => {

                    const {selectedLang} = this.state.formData;
                    const {allLanguageMutations} = this.state;
                    
                    if(error){
                        const helper = new Helper(); 
                        err = helper.getApolloErrorText(error);
                    }

                    return(
                        <Modal
                            className="Modal__Bootstrap modal-dialog modal-lg modal-news"
                            closeTimeoutMS={150}
                            isOpen={true}
                            onRequestClose={() => this.props.openCloseModal(false)}
                        >
                            <div className="modal-content">
                                <div className="modal-header d-flex align-items-center">
                                    <h4 className="modal-title">{(selectedCampaignEmailID && selectedCampaignEmailID != 0 ? "Úprava emailu" : "Přidání emailu")}</h4>
                                    <div className="ml-auto">
                                        <select className="form-control" name="selectedLang" onChange={(e) => this.campaignEmailLib.formLangObjectHandle(e)}>
                                            {allLanguageMutations && allLanguageMutations.map((item,index) => (
                                                <option key={index} value={item.languageID}> {item.suffix} </option>
                                            ))}
                                        </select>
                                    </div>
                                    <button type="button" className="close ml-3" onClick={() => this.props.openCloseModal(false)}>                                    
                                        <span aria-hidden="true">x</span>
                                        <span className="sr-only">Close</span>
                                    </button>
                                </div>
                                <div className="modal-body">

                                    {err ?
                                        <Error text={err} />
                                    :
                                        <Fragment>
                                            {!loading ?
                                            <div>                
                                                <div className="form-group">
                                                    <label className="input-label">Předmět emailu ({selectedLang})</label>
                                                    <div className="form-group">
                                                        <input className="form-control" type="text" name="subject" value={this.campaignEmailLib.getLangValue("subject")} onChange={(e) => this.campaignEmailLib.formLangHandle(e)} />
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <label className="input-label">Preheader ({selectedLang})</label>
                                                    <div className="form-group">
                                                        <input className="form-control" type="text" name="preheader" value={this.campaignEmailLib.getLangValue("preheader")} onChange={(e) => this.campaignEmailLib.formLangHandle(e)} />
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <label className="input-label">Počet hodin </label>
                                                    <p style={{fontSize:"12px",margin:"0px"}}>Za kolik hodin se má zaslat tento email od data zaslání předchozího emailu. Pokud je toto první email, tak je to doba od data, kdy nějaký člověk vloží do kampaně email.</p>
                                                    <div className="form-group">
                                                        <input className="form-control" type="text" name="hours" value={formData.hours} onChange={(e) => this.campaignEmailLib.formHandle(e)} />
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <TinyMCEEditor onChange={(content,type) => this.campaignEmailLib.formLangHandleEditor(content,"text")} initialValue={this.campaignEmailLib.getLangValue("text")} />           
                                                </div>

                                                <div className="row">

                                                    <div className="col-12 col-sm-6">
                                                    <input className="form-control" type="text" name="testEmail" value={formData.testEmail} onChange={(e) => this.campaignEmailLib.formHandle(e)} />
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <button className="btn btn-block btn-primary" onClick={() => this.campaignEmailLib.sendEmail(formData.selectedLang,this.campaignEmailLib.getLangValue("subject"),this.campaignEmailLib.getLangValue("preheader"),this.campaignEmailLib.getLangValue("text"),formData.testEmail)} >Odeslat testovací</button>
                                                    </div>

                                                </div>
                                                
                                            </div>

                                            :

                                            <Loading />  

                                            }  
                                        </Fragment>
                                    }
                                            
                                </div>
                                <div className="modal-footer"> 
                                    {!loading && <button type="button" className="btn btn-primary flex-fill" onClick={() => this.campaignEmailLib.addCampaignEmail(addEditCampaignEmail,this.props.campaignID)}>{(selectedCampaignEmailID && selectedCampaignEmailID != 0 ? "Upravit" : "Přidat")}</button>}
                                    <button type="button" className="btn btn-danger" onClick={() => this.props.openCloseModal(false)}>Zavřít</button>
                                </div>
                            </div>
                        </Modal>
                    );

                }
            }
            </Mutation>
        
        );

    }

}

export default withApollo(AddEditCampaignEmail);