import React,{ Component,Fragment, useState } from 'react';
import { withApollo,useQuery,useMutation } from 'react-apollo';
import {GET_CUSTOMERS_EVS_POINTS,DELETE_CUSTOMER_EVS_POINTS} from '../Queries/customer';
import Loading from '../../../GlobalComponents/Loading';
import NoItems from '../../../GlobalComponents/NoItems';
import moment from 'moment';
import edit from '../../../Public/Images/edit.svg';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import Notification from '../../../Library/notification';
import Error from '../../../GlobalComponents/Error';
import Helper from '../../../Library/helper';
import CustomerEVSpoint from '../Library/customerEVSpoint';
import AddEditCustomerEVSPoint from './AddEditCustomerEVSPoint';


const EVSPoints = (props) => {

    let years = [];
    let yearToday = moment().format("YYYY");
    for(let i = 2021; i <= parseInt(yearToday)+1; i++){
        years.push(i);
    }

    const [month,setMonth]                         = useState(moment().format("M"));
    const [year,setYear]                           = useState(moment().format("YYYY"));
    const [selectedCustomers,setSelectedCustomers] = useState({points:[]});
    const [showDeleteNotifi,setShowDeleteNotifi]   = useState(false);
    const [showAdd,setShowAdd]                     = useState(false);
    const [selectedCustomerEVSPointID,setSelectedCustomerEVSPointID] = useState(false);

    var variablesList = {
        customerID:props.customerID,
        month:parseInt(month),
        year:parseInt(year),
    }

    const {data,loading,error} = useQuery(GET_CUSTOMERS_EVS_POINTS,{
        variables:variablesList,
        fetchPolicy: 'network-only',
    });

    const [deleteCustEVSPoint,{error:deleteError,loading:deleteLoading}] = useMutation(DELETE_CUSTOMER_EVS_POINTS,{
        onCompleted: (data) => {

            var customerEVSpoint = new CustomerEVSpoint();
            customerEVSpoint.updateAfterDelete(props.client, data,variablesList);

            var notify = new Notification();
            notify.setNotification(null,"Úspěšně odstraněno",true,false,props.client);
        }

    });

    
    const deleteCustomerEVSPoint = (e,customerEVSPointID) => {

        var checked = e.target.checked;
        var arr = [...selectedCustomers.points];

        if(!checked){
            for(let i in selectedCustomers.points){
                if(selectedCustomers.points[i] == customerEVSPointID){
                    arr.splice(i,1);
                }
            }
        }else{
            arr = [customerEVSPointID,...arr];
        }

        setSelectedCustomers({
            points:arr
        });
  
    }

    const delCustomerEVSPoint = (action) => {

        if(action){

            let customerEVSPointIDs = selectedCustomers.points.join(",");

            deleteCustEVSPoint({
                variables:{
                    customerEVSPointIDs: customerEVSPointIDs
                }
            })
        }

        setSelectedCustomers({
            points:[]
        });
        setShowDeleteNotifi(false);

    }

    const showDelNotifi = () => {

        if(selectedCustomers.points.length > 0){
            setShowDeleteNotifi(true);
        }else{
            let notify = new Notification();
            notify.setNotification(null,'Nevybrali jste body.',false,true,props.client);
        }
    }

    const openCloseModal = (type,customerEVSPointID) => {
        setShowAdd(type);
        setSelectedCustomerEVSPointID(customerEVSPointID);
    }

    var totalPoints = 0;

    var err = "";
    if(error || deleteError){
        const helper = new Helper(); 
        //err = helper.getApolloErrorText(error || deleteError); 
    }

    return(
        
        
        <div className="card main">
            
            <div className="card-header d-flex align-items-center">
                <select value={year} className="form-control mr-1" style={{width:"100px"}} onChange={(e) => setYear(e.target.value)}> 
                    {years.map((item,index) => (
                        <option key={index} value={item}>{item}</option>
                    ))} 
                </select>
                <select value={month} className="form-control" style={{width:"200px"}} onChange={(e) => setMonth(e.target.value)}>
                    <option value="1">leden</option>
                    <option value="2">únor</option>
                    <option value="3">březen</option>
                    <option value="4">duben</option>
                    <option value="5">květen</option>
                    <option value="6">červen</option>
                    <option value="7">červenec</option>
                    <option value="8">srpen</option>
                    <option value="9">září</option>
                    <option value="10">říjen</option>
                    <option value="11">listopad</option>
                    <option value="12">prosinec</option>
                </select>
                <button className="btn btn-primary btn-thiner ml-auto" onClick={(e) => {openCloseModal(true,null);}}>Přidat body do daného měsíce</button>
                <button className="btn btn-danger btn-thiner ml-1" onClick={(e) => {showDelNotifi();}}>Smazat označené</button>
            </div>
            <div className="card-body">

                {loading ?
                    <Loading />
                :
                    (err != "" ?
                        <Error text={err} />
                    :
                        <div className="data-list">
                                                
                            <div className="data-list-item header">
                                <div className="data-list-item-content">
                                    <div className="text-left static">Datum připsání</div>
                                    <div className="text-left">Info</div>
                                    <div className="text-center static">Body</div>
                                    <div className="text-right static small">Možnosti</div>
                                </div>
                            </div>
                            
                            {data.allCustomerEVSPoints && data.allCustomerEVSPoints.map((item,index) => {
                                    
                                totalPoints += item.points;

                                var checked = false;
                                for(let i in selectedCustomers.points){
                                    if(selectedCustomers.points[i] == item.customerEVSPointID)checked = true;
                                }
                                                                                

                                return (
                                
                                    <div key={index} className="data-list-item-content">
                                        <div className="text-left static">{moment(item.dateAdd).format("DD.MM. v HH:mm:ss")}</div>
                                        <div className="text-left">{item.info}</div>
                                        <div className="text-center static">{item.points}</div>
                                        
                                        <div className="text-right static small">
                                            <img onClick={() => openCloseModal(true,item.customerEVSPointID)} className="edit-icon" src={edit} />
                                            <input className="delete-checked" type="checkbox" name="deleteCustomerEVSPoint[]" checked={checked} onChange = {(e) => deleteCustomerEVSPoint(e,item.customerEVSPointID)} />
                                        </div>
                                    </div>
                                            
                                )
                                
                            })} 

                                                
                            {data.allCustomerEVSPoints && data.allCustomerEVSPoints.length == 0 ?
                                <NoItems text={"V tomto měsíci se zde nenachází žádné body."} />
                            :
                                <div className="data-list-item footer">
                                    <div className="data-list-item-content">
                                        <div className="text-left static"><strong>Celkem</strong></div>
                                        <div className="text-left"></div>
                                        <div className="text-center static">{totalPoints}</div>
                                        
                                        <div className="text-right static small">
                                            
                                        </div>
                                    </div>
                                </div>
                            }
                            
                        </div>
                    )
                }
                
            </div>
            {showAdd &&
                <AddEditCustomerEVSPoint customerID={props.customerID} month={month} year={year} variablesList = {variablesList} openCloseModal={openCloseModal} selectedCustomerEVSPointID = {selectedCustomerEVSPointID} />
            }
            {showDeleteNotifi && <ModalNotification yesNo={true} text={'Opravdu chcete vymazat vybrané body ?'} callback={delCustomerEVSPoint} /> }
        </div>
                
                
    );

    

}

export default withApollo(EVSPoints);