/*
    Přidání štítku
*/
import React,{ Component, Fragment } from 'react';
import {Mutation,Query} from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import Modal from 'react-modal';
import {ADD_EDIT_EVENT} from '../Queries/event';
import { withApollo } from 'react-apollo'; 
import EventLib from '../Library/event';
import Notification from '../../../Library/notification';
import Helper from '../../../Library/helper';
import { Editor } from '@tinymce/tinymce-react';
import DatePicker,{ registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import cs from 'date-fns/locale/cs';
import TinyMCEEditor from '../../TinyMCEFileManager/Components/TinyMCEEditor';
import EventCategorySelector from './EventCategorySelector';


registerLocale('cs', cs);

const INITIAL_STATE = {
    apolloError:"",
    selectedEventID:null,
    addEvent:null,
    formData:{
        selectedLang:"cz",
        eventDate:"",
        eventID:0,
        eventCategoryIDs:null,
        active:1,
        recurring:0,
        recurringType:0,
        langs:[],
    },
    allLanguageMutations:[]
}

class AddEditEvent extends Component{

    constructor(props){
        super(props);
        this.state = INITIAL_STATE;
        
        this.eventLib = new EventLib(this); 
    }

    componentDidMount(){
        this.eventLib.getData();     
    }

    setSelectedCats = (eventCategoryIDs) => {  
        this.setState({formData:{...this.state.formData,eventCategoryIDs}});
    }

    render(){

        const {apolloError,allLanguageMutations,selectedEventID} = this.state;
        var {listVariables} = this.props;

        return(

            <Mutation 
                mutation={ADD_EDIT_EVENT}
                update = {async (cache, response) => {

                    this.eventLib.updateList(cache,response,selectedEventID,listVariables);
                    
                    let notify = new Notification();

                    if(selectedEventID && selectedEventID != 0) notify.setNotification(cache,'Úspěšně uloženo',true,false);
                    else notify.setNotification(cache,'Úspěšně přidáno',true,false);


                }}
                onCompleted = {(response) => {              
                    this.props.openCloseModal(false);    
                }}
                
            >
            {
                (addEvent,{data,loading,error}) => {

                    if(error){
                        const helper = new Helper(); 
                        error = helper.getApolloErrorText(error);
                    }

                    const {selectedLang,active,recurring,recurringType,eventDate,eventCategoryIDs} = this.state.formData;
                                
                    return(
                        <Modal
                            className="Modal__Bootstrap modal-dialog modal-lg"
                            closeTimeoutMS={150}
                            isOpen={true}
                            onRequestClose={() => this.props.openCloseModal(false)}
                        >
                            <div className="modal-content">
                            <div className="modal-header d-flex align-items-center">
                                    <h4 className="modal-title">{(selectedEventID && selectedEventID != 0 ? "Úprava akce" : "Přidání akce")}</h4>
                                    <div className="ml-auto">
                                        <select className="form-control" name="selectedLang" onChange={(e) => this.eventLib.formHandle(e)}>
                                            {allLanguageMutations && allLanguageMutations.map((item,index) => (
                                                <option key={index} value={item.suffix}> {item.suffix} </option>
                                            ))}
                                        </select>
                                    </div>
                                    <button type="button" className="close ml-3" onClick={() => this.props.openCloseModal(false)}>                                    
                                        <span aria-hidden="true">x</span>
                                        <span className="sr-only">Close</span>
                                    </button>
                                </div>
                                <div className="modal-body">

                                    {error || apolloError ?
                                        <Error text={error || apolloError} />
                                    :
                                    <div>
                                        
                                        {!loading ?
                                            <Fragment>  
                                                 
                                                <div>                                                                                                                                                         
                                                    <div className="row">
                                                                                                                
                                                        
                                                        <div className="col-12 col-sm-6">
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <div className="form-group">
                                                                        <label className="input-label">Nadpis akce  ({selectedLang})</label>
                                                                        <div className="form-group">
                                                                            <input className="form-control" type="text" name="title" value={this.eventLib.getLangValue("title")} onChange={(e) => this.eventLib.formLangHandle(e)} />
                                                                        </div>
                                                                    </div>  
                                                                </div>  
                                                                <div className="col-6">
                                                                    <div className="form-group">
                                                                        <label className="input-label">Datum akce</label>
                                                                        <div className="form-group">
                                                                            <DatePicker showTimeSelect className="form-control" dateFormat="dd.MM.yyyy HH:mm" locale="cs" selected={eventDate} onChange={date => this.eventLib.setEventDate(date)} />
                                                                        </div>
                                                                    </div>  
                                                                </div>

                                                                <div className="col-6">
                                                                    <label className="input-label">Aktivní</label>
                                                                    <div className="form-group">
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input" type="radio" name="active" id="active1" value="1" checked={(active == 1 ?  true : false)} onChange={(e) => this.eventLib.formHandle(e)} />
                                                                            <label className="form-check-label" htmlFor="active1">
                                                                                Ano
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input" type="radio" name="active" id="active2" value="0" checked={(active == 0 ?  true : false)} onChange={(e) => this.eventLib.formHandle(e)} />
                                                                            <label className="form-check-label" htmlFor="active2">
                                                                                Ne
                                                                            </label>
                                                                        </div>
                                                                    </div> 
                                                                </div>

                                                                <div className="col-3">
                                                                    <label className="input-label">Opakující se</label>
                                                                    <div className="form-group">
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input" type="radio" name="recurring" id="recurring1" value="1" checked={(recurring == 1 ?  true : false)} onChange={(e) => this.eventLib.formHandle(e)} />
                                                                            <label className="form-check-label" htmlFor="recurring1">
                                                                                Ano
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input" type="radio" name="recurring" id="recurring2" value="0" checked={(recurring == 0 ?  true : false)} onChange={(e) => this.eventLib.formHandle(e)} />
                                                                            <label className="form-check-label" htmlFor="recurring2">
                                                                                Ne
                                                                            </label>
                                                                        </div>
                                                                    </div> 
                                                                </div>

                                                                {recurring == 1 ?
                                                                    <div className="col-9">
                                                                        <label className="input-label">Typ opakování</label>
                                                                        <div className="form-group">
                                                                            <div className="form-check form-check-inline">
                                                                                <input className="form-check-input" type="radio" name="recurringType" id="recurringType1" value="1" checked={(recurringType == 1 ?  true : false)} onChange={(e) => this.eventLib.formHandle(e)} />
                                                                                <label className="form-check-label" htmlFor="recurringType1">
                                                                                    každý den
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check form-check-inline">
                                                                                <input className="form-check-input" type="radio" name="recurringType" id="recurringType2" value="2" checked={(recurringType == 2 ?  true : false)} onChange={(e) => this.eventLib.formHandle(e)} />
                                                                                <label className="form-check-label" htmlFor="recurringType2">
                                                                                    každý týden
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check form-check-inline">
                                                                                <input className="form-check-input" type="radio" name="recurringType" id="recurringType3" value="3" checked={(recurringType == 3 ?  true : false)} onChange={(e) => this.eventLib.formHandle(e)} />
                                                                                <label className="form-check-label" htmlFor="recurringType3">
                                                                                    každý měsíc
                                                                                </label>
                                                                            </div>
                                                                        </div> 
                                                                    </div>
                                                                :null}
                                                                
                                                            </div>

                                                        </div> 

                                                        <div className="col-12 col-sm-6">
                                                            <label className="input-label">Přiřadit kategorii</label>
                                                            {eventCategoryIDs ? <EventCategorySelector allEventCategories={eventCategoryIDs}  setSelectedCats={this.setSelectedCats}/>:null}                                                                          
                                                        </div> 
 
                                                            
                                                        
                                                        <div className="col-12">
                                                            <div className="form-group">
                                                                <label className="input-label">Popis akce ({selectedLang})</label>
                                                                <div className="form-group">
                                                                    <TinyMCEEditor onChange={(content,type) => this.eventLib.formLangHandleEditor(content,type)} initialValue={this.eventLib.getLangValue("text")} /> 
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>                                               
                                                </div>
  
                                            </Fragment>

                                        :
                                        <Loading />  
                                        }  

                                    </div>
                                    }
               
                                </div>
                                <div className="modal-footer">                                
                                    <button type="button" className="btn btn-primary flex-fill" onClick={() => this.eventLib.addEvent(addEvent)}>{(selectedEventID && selectedEventID != 0 ? "Uložit" : "Přidat")}</button>
                                    <button type="button" className="btn btn-danger" onClick={() => this.props.openCloseModal(false)}>Zavřít</button>
                                </div>

                            </div>
                        </Modal>
                    );

                }
            }
            </Mutation>
        
        );

    }

}

export default withApollo(AddEditEvent);