/**
 * soubor se seznamem veškerých graphql dotazů pro kategorie
 */
import gql from 'graphql-tag';

/**
 * vložení kategorie
 */
export const ADD_EDIT_VIDEO = gql`
    mutation AddEditVideo($videoID:ID,$categoryID:Int, $langs:[VideoLangsInput]){
        addEditVideo(videoID:$videoID, categoryID:$categoryID,langs:$langs)
    }
`;

/**
 *  Úprava priority kategorie, když se přesouvají položky
 */
export const UPDATE_VIDEO_PRIORITY = gql`
    mutation updateVideoPriority($videoID:ID!,$fromIndex:Int!,$toIndex:Int!) {
        updateVideoPriority(videoID:$videoID,fromIndex:$fromIndex,toIndex:$toIndex)
    }
`;

/**
 *  Data pro jednu kategorii
 */

export const GET_VIDEO = gql`
    query VideoWithLangs($videoID: ID!){
        videoWithLangs(videoID:$videoID){
            videoID
            categoryID
            langs{
                lang
                name
                url
                emailText
            }    
        }
        allLanguageMutations(onlyEnabled:true){
            title
            suffix
            file
            generateNiceTitle
            langTitle
            decimal
            priority
            main
        }
        allAdminCategories(lang:"cz"){
            categoryID
            name
        }
    }
`;

/**
 *  Seznam veškerých kategorií - maximlání zanoření je 7
 */
export const GET_VIDEOS = gql`
    query AllVideos($lang: String!){
        allVideos(lang: $lang){
            videoID
            categoryID
            hash
            name
            url
            emailText
            priority
            lang
            category{
                name
                niceName
            }
        }
    }
`;

/**
 * Smazání kategorie.
 */
export const DELETE_VIDEOS = gql`
    mutation deleteVideos($videoIDs:ID!) {
        deleteVideos(videoIDs:$videoIDs)
        reindexPriority
    }
`;