import React,{ Component } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import edit from '../../../Public/Images/edit.svg';
import move from '../../../Public/Images/move.svg';
import DeleteMaterial from './DeleteMaterial';

class SubMaterial extends Component{

    constructor(props){
        super(props);
        this.onDragEnd = this.onDragEnd.bind(this);
    }

    onDragEnd(result,updateMaterialPriority){

        const IDs = result.draggableId.split("-");

        updateMaterialPriority({
            variables:{
                parentID: IDs[1],
                materialID: IDs[0],
                fromIndex:result.source.index,
                toIndex:  result.destination.index
            }
        });

    }

    render(){

        return(
            
            <DragDropContext onDragEnd={(result) => this.onDragEnd(result,this.props.updateMaterialPriority)}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                    >
                            {this.props.materials && this.props.materials.map((item,index) => {
                                
                                if(item.materialID){
                                return (
                                    <Draggable key={index} draggableId={item.materialID+"-"+item.parentID} index={index}>
                                        {(provided, snapshot) => (
                                        <div className="data-list-item" 
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <div className="data-list-item-content categories">
                                                <div className="cross" style={(this.props.level == 0 ? {paddingLeft:10}: {paddingLeft:this.props.level * 20 + 10})}>
                                                    <img className="move-icon" src={move} />
                                                    {item.name}
                                                </div>
                                                <div className="text-center">{(item.active == 1 ? "Ano" : "Ne" )}</div>
                                                <div className="text-right">
                                                    <img onClick={() => this.props.openCloseModal(true,item.materialID)} className="edit-icon" src={edit} /> 
                                                    <DeleteMaterial materialID={item.materialID} parentID={item.parentID} subMaterial={item.subMaterial} deleteMaterial={this.props.deleteMaterial} />
                                                </div>
                                            </div>
                                            {item.subMaterial && <SubMaterial openCloseModal={this.props.openCloseModal} openContentManager={this.props.openContentManager} deleteMaterial={this.props.deleteMaterial} updateMaterialPriority={this.props.updateMaterialPriority} materials={item.subMaterial} level={this.props.level + 1} />}
                                        </div>
                                        )}
                                    </Draggable>
                                )
                                }else{
                                    return "";
                                }
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

        )

    }

}

export default SubMaterial;