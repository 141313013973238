/**
 * soubor se seznamem veškerých graphql dotazů pro kategorie
 */
import gql from 'graphql-tag';

/**
 * vložení kategorie
 */
export const ADD_EDIT_QUESTIONNAIRE = gql`
    mutation AddEditQuestionnaire($questionnaireID:ID,$name:String,$langs:[QuestionnaireLangsInput],$questionnaireSteps:[QuestionnaireStepInput]){
        addEditQuestionnaire(questionnaireID:$questionnaireID,name:$name,langs:$langs,questionnaireSteps:$questionnaireSteps){
            questionnaireID
            name
        }
    }
`;

/**
 *  Data pro jednu kategorii
 */

export const GET_QUESTIONNAIRE = gql`
    query QuestionnaireWithLangs($questionnaireID:ID){

        questionnaireWithLangs(questionnaireID:$questionnaireID){
            questionnaireID
            name
            langs{
                lang
                welcomeMessage
            }
        }
        getAllQuestionnaireStepsWithLangs(questionnaireID:$questionnaireID){
            questionnaireStepID
            questionTypeID
            priority
            items{
                questionnaireStepItemID
                questionnaireStepID
                priority
                langs{
                    questionnaireStepItemLangID
                    lang
                    text
                }
            }
            langs{
                questionnaireStepLangID
                lang
                question
            }
        }
        
        allLanguageMutations(onlyEnabled:true){
            languageID
            title
            suffix
            file
            generateNiceTitle
            langTitle
            decimal
            priority
            main
            currencyTitle
        }
        allQuestionTypes{
            questionTypeID
            type
        }
    }
`;

/**
 *  Seznam veškerých kategorií
 */
export const GET_QUESTIONNAIRES = gql`
    query AllQuestionnaires($lang: String!){
        getAllQuestionnaires(lang: $lang){
            questionnaireID
            name
        }
    }
`;

export const DELETE_QUESTIONNAIRES = gql`
    mutation deleteQuestionnaire($questionnaireIDs:ID!) {
        deleteQuestionnaire(questionnaireIDs:$questionnaireIDs)
    }
`;
