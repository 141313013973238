import { useState } from "react";
import Notification from '../../../Library/notification';
import { GET_QUESTIONNAIRE } from "../Queries/questionnaire";

const QUESTIONNAIRE_STEP = {
    questionnaireStepID:0,
    questionTypeID:1,
    deleted:false,
    added:false,
    items:[],
    langs:[]
} 

const QUESTIONNAIRE_STEP_ITEM = {
    questionnaireStepItemID:0,
    langs:[],
    deleted:false,
    added:false,
} 

const UseAddQuestionnaire = (AddEditQuestionnaire,selectedQuestionnaireID,client) => {

    const [formData,SetFormData] = useState({
        questionnaireID:0,
        name:"",
        selectedLang:'cz',
        questionnaireSteps:[],
        langs:[]
    });

    const [allLanguageMutations, SetAllLanguageMutations] = useState([]);
    const [allQuestionTypes, SetAllQuestionTypes] = useState([]);
    const [loading,SetLoading] = useState(false);
    const [error,SetError] = useState("");

    const GetData = async () => {

        var questionnaireID = 0;
        if(selectedQuestionnaireID)questionnaireID = selectedQuestionnaireID;
        
        SetLoading(true);

        var data = await client.query({ 
            query: GET_QUESTIONNAIRE,
            errorPolicy:"all",
            variables:{questionnaireID:questionnaireID}, 
            fetchPolicy: 'network-only'
        });
         
        if(data.errors){
            SetError(data.errors[0].message);
            return;
        }
        
        //kdyz upravujeme zaznam
        if(data.data)
        {                  
            if(data.data.questionnaireWithLangs)
            {    
                SetLoading(false);

                let langs = CompleteLangsArray(data.data.questionnaireWithLangs.langs,data.data.allLanguageMutations);     
                                
                SetAllLanguageMutations(data.data.allLanguageMutations);
                SetAllQuestionTypes(data.data.allQuestionTypes);

                var questionnaireSteps = [];
                for(let step of data.data.getAllQuestionnaireStepsWithLangs)
                {
                    questionnaireSteps.push({
                        questionTypeID:      step.questionTypeID,
                        questionnaireStepID: step.questionnaireStepID,
                        deleted:             false,
                        added:               false,
                        items:               GetStepItems(step),
                        langs:               GetStepLangs(step)
                    })
                }

                SetFormData({...formData,
                    questionnaireID:questionnaireID,
                    name:data.data.questionnaireWithLangs.name,
                    questionnaireSteps,
                    langs:langs
                })

            }
        }

        //kdyz pridavame zaznam
        if(questionnaireID == 0)
        {
            SetLoading(false);

            let langs = CompleteLangsArray([],data.data.allLanguageMutations);            
            SetAllLanguageMutations(data.data.allLanguageMutations);
            SetAllQuestionTypes(data.data.allQuestionTypes);

            SetFormData({...formData,
                langs:langs
            })
        }
    }

    const AddQuestionnaire = () => {

        if(formData.name != "")
        {    
            let langs = [];
            for(const lang of formData.langs){
                langs.push({
                    lang:           lang.lang,
                    welcomeMessage: lang.welcomeMessage
                });
            }

            AddEditQuestionnaire({
                variables:{
                    questionnaireID:    formData.questionnaireID,
                    name:               formData.name,
                    questionnaireSteps: formData.questionnaireSteps,
                    langs
                }
            });
        }
        else
        {
            let notify = new Notification();
            notify.setNotification(null,'Raději vyplň i interní název, bo se v tom potom nevyznáš :)',false,true,client);
        }
    }

    const AddQuestionnaireStep = () => {

        let langs = CompleteLangsArray([],allLanguageMutations,"step");
        var newStep = {...QUESTIONNAIRE_STEP};
        newStep.langs = langs;
        newStep.added = true;

        var arr = [...formData.questionnaireSteps,newStep];

        SetFormData({...formData,questionnaireSteps:arr})

    }

    const AddQuestionnaireStepItem = (index) => {

        let langs = CompleteLangsArray([],allLanguageMutations,"answer");
        var newItem = {...QUESTIONNAIRE_STEP_ITEM};
        newItem.langs = langs;
        newItem.added = true;

        var arr = [...formData.questionnaireSteps];
        var arrItems = [...formData.questionnaireSteps[index].items,newItem];

        arr[index] = {...arr[index],items:arrItems}

        SetFormData({...formData,questionnaireSteps:arr})

    }

    const SetFormDataValue = (e) => {

        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;

        SetFormData({...formData,[n]:v})
    }

    const SetFormDataLangValue = (e,paramName) => {
        
        var v, n;
        if(paramName)
        {
            v = e;
            n = paramName
        }
        else
        {
            const t = e.target;
            v = t.type === 'checkbox' ? t.checked : t.value;
            n = t.name;
        }
        
        let langs  = formData.langs;
        for(const lang of langs){
            if(lang.lang == formData.selectedLang){
                lang[n] = v;
            }
        }

        SetFormData({...formData,langs: langs});
         
    }

    const SetStepLangValue = (e,index) => {
        
        const t = e.target;
        var v = t.type === 'checkbox' ? t.checked : t.value;
        var n = t.name;
        
        var langArr = [...formData.questionnaireSteps[index].langs];

        var editedLang;
        var editetIndex = 0;

        for(const i in langArr){
            if(langArr[i].lang == formData.selectedLang){
                editedLang = langArr[i];
                editetIndex = i;
            }
        }

        editedLang = {...editedLang,[n]:v}
        langArr[editetIndex] = editedLang;

        var arr = [...formData.questionnaireSteps];
        arr[index] = {...arr[index],langs:langArr}
        
        SetFormData({...formData,questionnaireSteps: arr});
         
    }

    const SetStepItemLangValue = (e,index,ind) => {

        const t = e.target;
        var v = t.type === 'checkbox' ? t.checked : t.value;
        var n = t.name;

        var itemsArr = [...formData.questionnaireSteps[index].items];
        var langArr = [...itemsArr[ind].langs];

        var editedLang;
        var editetIndex = 0;

        for(const i in langArr){
            if(langArr[i].lang == formData.selectedLang){
                editedLang = langArr[i];
                editetIndex = i;
            }
        }

        editedLang = {...editedLang,[n]:v}
        langArr[editetIndex] = editedLang;

        itemsArr[ind] = {...itemsArr[ind],langs:langArr};

        var arr = [...formData.questionnaireSteps];
        arr[index] = {...arr[index],items:itemsArr}
        
        SetFormData({...formData,questionnaireSteps: arr});

    }

    const SortSteps = (result) => {

        if(result.destination.index != result.source.index){

            var arr = [...formData.questionnaireSteps];    

            if(result.source.index < result.destination.index){
                arr.splice(result.destination.index + 1,0,arr[result.source.index]);
                arr = [...arr.slice(0,result.source.index),...arr.slice(result.source.index + 1)]
            }else{
                arr.splice(result.destination.index,0,arr[result.source.index]);
                arr = [...arr.slice(0,result.source.index+1),...arr.slice(result.source.index + 2)]
            }

            SetFormData({...formData,questionnaireSteps: arr});

        }
    }

    const SortStepItems = (index,result) => {

        if(result.destination.index != result.source.index){

            var arr = [...formData.questionnaireSteps[index].items];    

            if(result.source.index < result.destination.index){
                arr.splice(result.destination.index + 1,0,arr[result.source.index]);
                arr = [...arr.slice(0,result.source.index),...arr.slice(result.source.index + 1)]
            }else{
                arr.splice(result.destination.index,0,arr[result.source.index]);
                arr = [...arr.slice(0,result.source.index+1),...arr.slice(result.source.index + 2)]
            }

            var steps = [...formData.questionnaireSteps];
            steps[index] = {...steps[index],items:arr}

            SetFormData({...formData,questionnaireSteps: steps});

        }
    }

    const SetStepQuestionType = (index,value) => {

        var arr = [...formData.questionnaireSteps];
        arr[index] = {...arr[index],questionTypeID:value};

        SetFormData({...formData,questionnaireSteps: arr});

    }

    const GetLangValue = (propertyName) => {
        for(const lang of formData.langs){
            if(lang.lang == formData.selectedLang){
                return lang[propertyName];
            }
        }
        return "";
    }

    const GetStepLangValue = (langs,propertyName) => {

        for(const lang of langs){
            if(lang.lang == formData.selectedLang){
                return lang[propertyName];
            }
        }
        return "";
    }

    const RemoveStep = (index) => {
        
        var arr = [...formData.questionnaireSteps];
        arr[index] = {...arr[index],deleted:true}

        SetFormData({...formData,questionnaireSteps:arr})
    }

    const RemoveStepItem = (index,ind) => {
        
        var arr      = [...formData.questionnaireSteps];
        var arrItems = [...formData.questionnaireSteps[index].items];

        arrItems[ind] = {...arrItems[ind],deleted:true}
        arr[index]    = {...arr[index],items:arrItems}

        SetFormData({...formData,questionnaireSteps:arr})
    }

    const CompleteLangsArray = (langsFromDb,allActiveLanguages,type) => {

        let langs = langsFromDb;
        for(const l of allActiveLanguages)
        {
            let langSet = false;
            for(const lang of langs)
            {
                if(l.suffix == lang.lang)
                {
                    if(!lang.text && type == "answer") lang.text = "";
                    langSet = true;
                    break;
                }
            }

            if(!langSet)
            {
                var langData = {
                    lang:l.suffix
                }
                if(type == "step")
                {
                    langData.question = "";
                    langData.questionnaireStepLangID = 0;
                }
                else if(type == "answer")
                { 
                    langData.text = "";
                    langData.questionnaireStepItemLangID = 0;
                }
                else
                { 
                    langData.welcomeMessage = "";
                }

                langs.push(langData);
            }
        }
        return langs;
    }

    const GetStepLangs = (step) => {

        var stepLangs = [];
        for(let lang of step.langs)
        {
            stepLangs.push({
                questionnaireStepLangID: lang.questionnaireStepLangID,
                lang:                    lang.lang,
                question:                lang.question
            });
        }

        return stepLangs;
    }

    const GetStepItems = (step) => {

        var stepItems = [];
        for(let item of step.items)
        {
            stepItems.push({
                questionnaireStepItemID: item.questionnaireStepItemID,
                langs: GetStepItemLangs(item)
            });
        }

        return stepItems;
    }

    const GetStepItemLangs = (item) => {

        var stepItemLangs = [];
        for(let lang of item.langs)
        {
            stepItemLangs.push({
                questionnaireStepItemLangID: lang.questionnaireStepItemLangID,
                lang:                        lang.lang,
                text:                        lang.text
            });
        }

        return stepItemLangs;
    }
    
    return{
        formData,
        allLanguageMutations,
        loading,
        error,
        allQuestionTypes,
        SetFormDataValue,
        SetFormDataLangValue,
        GetLangValue,
        GetStepLangValue,
        GetData,
        AddQuestionnaire,
        AddQuestionnaireStep,
        SetStepLangValue,
        SetStepQuestionType,
        RemoveStep,
        RemoveStepItem,
        AddQuestionnaireStepItem,
        SetStepItemLangValue,
        SortSteps,
        SortStepItems
    }

}

export default UseAddQuestionnaire;