/*
    Přidání kategorie
*/
import React,{ Component,Fragment } from 'react';
import {Mutation,Query} from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import {ADD_EDIT_WELCOME_PAGE} from '../Queries/welcomePage.js';
import { withApollo } from 'react-apollo';
import Notification from '../../../Library/notification';
import Helper from '../../../Library/helper';
import TinyMCEEditor from '../../TinyMCEFileManager/Components/TinyMCEEditor';
import WelcomePageLib from '../Library/welcomePage';

const INITIAL_STATE = {
    err:"",
    allLanguageMutations:[],
    formData:{
        selectedLang:"cz",
        steps:[]
    }
}

class WelcomePage extends Component{

    constructor(props){
        super(props);
        this.state = INITIAL_STATE;
        
        this.welcomePageLib = new WelcomePageLib(this); 
    }

    componentDidMount = () => {
        
        this.welcomePageLib.getData();
    }


    render(){

        var {err,allLanguageMutations} = this.state;

        return(

            <Mutation 
                mutation={ADD_EDIT_WELCOME_PAGE}
                errorPolicy = "all"
                update = {async (cache, response) => {

                    let notify = new Notification();
                    notify.setNotification(cache,'Úspěšně uloženo',true,false);

                }}
                
            >
            {
                (addEditWelcomePage,{loading, error}) => {

                    const {selectedLang,steps} = this.state.formData;
                    const {selectedMaterialID} = this.props;

                    if(error){
                        const helper = new Helper(); 
                        err = helper.getApolloErrorText(error);
                    }

                    return(

                        <div id="settings" className="whole-container" >
            	
                        <div className="row" >
                            <div className="col-sm one">
                        
                            <div className="card main">
                                <div className="card-header d-flex align-items-center">
                                    <h4 className="modal-title">Uvítací stránka v portále</h4>
                                    <div className="ml-auto">
                                        <select className="form-control" name="selectedLang" onChange={(e) => this.welcomePageLib.formHandle(e)}>
                                            {allLanguageMutations && allLanguageMutations.map((item,index) => (
                                                <option key={index} value={item.suffix}> {item.suffix} </option>
                                            ))}
                                        </select>
                                    </div>
                                    
                                </div>
                                <div className="card-body">

                                    {err ?
                                        <Error text={err} />
                                    :
                                        <Fragment>
                                            {!loading ?
                                            <>

                                                {steps && steps.map((item,index) => {

                                                    return(

                                                        <div key={index} className="card main mb-5">
                                                            <div className="card-header d-flex align-items-center">
                                                                <h4 className="modal-title"><span className="badge badge-warning">Krok {index + 1}</span></h4>
                                                            </div>
                                                            <div className="card-body">

                                                                <div className="row" key={index}>
                                                                    <div className="col-12 col-sm-4">
                                                                        <label className="input-label">Název tlačítka ({selectedLang})</label>
                                                                        <div className="form-group">
                                                                            <input className="form-control" type="text" name="buttonText" value={this.welcomePageLib.getLangValue("buttonText",index)} onChange={(e) => this.welcomePageLib.formLangHandle(e,index)} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-sm-8">
                                                                        <label className="input-label">URL adresa videa ({selectedLang})</label>
                                                                        <div className="form-group">
                                                                            <input className="form-control" type="text" name="videoUrl" value={this.welcomePageLib.getLangValue("videoUrl",index)} onChange={(e) => this.welcomePageLib.formLangHandle(e,index)} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-sm-6">
                                                                        <label className="input-label">Popisek pod videem ({selectedLang})</label>
                                                                        <div className="form-group">
                                                                            <input className="form-control" type="text" name="videoDesc" value={this.welcomePageLib.getLangValue("videoDesc",index)} onChange={(e) => this.welcomePageLib.formLangHandle(e,index)} />
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-12">
                                                                        <label className="input-label">Text před videem ({selectedLang})</label>
                                                                        <div className="form-group">
                                                                            
                                                                            <TinyMCEEditor onChange={(content,type) => this.welcomePageLib.formLangHandleEditor(content,type,index)} initialValue={this.welcomePageLib.getLangValue("text",index)} /> 
                                                                                                                        
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12">
                                                                        <label className="input-label">Text za videem ({selectedLang})</label>
                                                                        <div className="form-group">
                                                                            
                                                                            <TinyMCEEditor onChange={(content,type) => this.welcomePageLib.formLangHandleEditor(content,"text2",index)} initialValue={this.welcomePageLib.getLangValue("text2",index)} /> 
                                                                                                                        
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    )

                                                })}
                                            </>

                                            :

                                            <Loading />  

                                            }  
                                        </Fragment>
                                    }
                                            
                                </div>
                                <div className="modal-footer"> 
                                    {!loading && <button type="button" className="btn btn-primary flex-fill" onClick={() => this.welcomePageLib.addWelcomePage(addEditWelcomePage)}>Upravit</button>}
                                </div>
                            </div>

                            </div>
                            </div>
                        </div>
                        
                    );

                }
            }
            </Mutation>
        
        );

    }

}

export default withApollo(WelcomePage);