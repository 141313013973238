import { useState } from 'react';
import { withApollo,useQuery } from 'react-apollo';
import {GET_CUSTOMERS_WITH_AFF_URL} from '../Modules/Customer/Queries/customer';
import Loading from './Loading';
import SearchInput from './SearchInput';
import NoItems from './NoItems';

const  SearchCustomer = ({SetCustomer}) => {

    const [showCustomerList, SetShowCustomerList] = useState(false);
    const [customerListVariables,SetCustomerListVariables] = useState({
        limit:50,
        offset:0,
        text:""
    });

    const {data,loading,error} = useQuery(GET_CUSTOMERS_WITH_AFF_URL,{
        variables:customerListVariables
    });

    const SetCustomers = (text) => {
        if(text == "")
        {
            SetShowCustomerList(false);
            SetCustomer(null);
        }
        else
        {
            SetShowCustomerList(true);
            SetCustomerListVariables({...customerListVariables,text:text});
        }    
       
    }

    const SelectCustomer = (item) => {
        SetCustomer(item);
        SetShowCustomerList(false);
    }
    
    return(
        <>
            <SearchInput value={customerListVariables.text} className="form-control" placeholder="Vyhledat Vládce Síly" onChange={(text) => SetCustomers(text)} />
            {showCustomerList ? 
                <div className="">
                    {loading ?
                        <Loading />
                    :
                        <>
                            <div className="data-list">
                                {data.allCustomers.map((item,index) => {
                                    return(
                                        <div key={index} className="data-list-item-content">
                                            <div onClick={() => SelectCustomer(item)} className="pointer">{(item.astralName ? item.astralName + " - " : "")}{item.name} {item.surname} ({item.email})</div>
                                        </div>
                                    )
                                })}

                            </div>
                            {data.allCustomers && data.allCustomers.length == 0 ?
                                <div className="mt-2"><NoItems text={"Vládce Síly nebyl nalezen."} /></div>
                            :null}
                        </>
                    }
                </div>
            :null}
        </>
    )
}

export default SearchCustomer;