import React,{ Component,Fragment } from 'react';
import remove from '../../../Public/Images/remove.svg';

class AddElement extends Component{

    constructor(props){
        super(props);
    }


    render(){

        const {lastIndexes,element,cm,lang,isSection,columnType} = this.props;

        return (
           
            <div className="cm-dropdown">
                <button onClick={() => cm.showAddElements(lastIndexes)}>{isSection ? "Obsah" : "Přidat obsah"}</button>
                {element.showAddElements &&
                    
                    <Fragment>
                        <div className="section-menu">
                            <div className="d-flex">
                                <ul className={(isSection ? "" : "w-100")}>
                                    {!columnType || columnType == "row" ?
                                        <li onClick={(e) => cm.addElementTo("columns",lastIndexes)}>Sloupce</li>
                                    :null}
                                    {!columnType || columnType == "drag" ?
                                        <li onClick={(e) => cm.addElementTo("drag_columns",lastIndexes)}>Seřazené sloupce</li>
                                    :null}
                                    <li onClick={(e) => cm.addElementTo("headline",lastIndexes)}>Nadpis</li>
                                    <li onClick={(e) => cm.addElementTo("paragraph",lastIndexes)}>Odstavec</li>
                                    <li onClick={(e) => cm.addElementTo("image",lastIndexes)}>Obrázek</li>
                                    <li onClick={(e) => cm.addElementTo("button",lastIndexes)}>Tlačítko</li>
                                    <li onClick={(e) => cm.addElementTo("video",lastIndexes)}>Video</li>
                                    <li onClick={(e) => cm.addElementTo("articles",lastIndexes)}>Články</li>
                                    <li onClick={(e) => cm.addElementTo("newsletter",lastIndexes)}>Newsletter</li>
                                    <li onClick={(e) => cm.addElementTo("form",lastIndexes)}>Formulář</li>
                                    <li onClick={(e) => cm.addElementTo("service",lastIndexes)}>Služba</li>
                                    <li onClick={(e) => cm.addElementTo("faq",lastIndexes)}>FAQ</li>
                                    <li onClick={(e) => cm.addElementTo("html",lastIndexes)}>HTML</li>
                                    <li onClick={(e) => cm.addElementTo("alert",lastIndexes)}>Upozornění</li>
                                </ul>
                                {isSection ? 
                                    <div>
                                        
                                        <div className="input-group mb-1">
                                            <div className="custom-file">
                                                <input type="file" className="custom-file-input" id="customFile" onChange={(e) => cm.uploadSectionBackgroundImage(e,lastIndexes,element.backgroundImage)}  />
                                                <label className="custom-file-label" htmlFor="customFile">Obrázek pozadí</label>
                                            </div>
                                            {element.backgroundImage != "" &&
                                                <div className="input-group-append">
                                                    <div className="item-container" onClick={() => cm.removeSectionBackgroundImage(lastIndexes)}>
                                                        <img className="remove-icon" src={remove} alt="Odstranit obrázek na pozadí" title="Odstranit obrázek na pozadí" />
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className="form-group mb-1">
                                            <select value={element.backgroundColorClass} className="form-control" name="section-bg-color" onChange={(e) => cm.setSectionBackgroundColorClass(e,lastIndexes)}>
                                                <option value=""> --- barva pozadí ---</option>
                                                <option value="grey"> šedá </option>
                                                <option value="blue"> modrá </option>
                                            </select>
                                        </div>
                                        
                                        <div className="form-group mb-1">
                                            <select value={element.fullWidth} className="form-control" name="section-full-width" onChange={(e) => cm.setSectionFullWidth(e,lastIndexes)}>
                                                <option value="0"> pevná šířka </option>
                                                <option value="1"> na celou šířku </option>
                                            </select>
                                        </div>
                                        <div className="form-group mb-1">
                                            <select value={element.fullHeight} className="form-control" name="section-full-height" onChange={(e) => cm.setSectionFullHeight(e,lastIndexes)}>
                                                <option value="0"> auto výška </option>
                                                <option value="1"> na celou výšku </option>
                                            </select>
                                        </div>
                                        <div className="form-group mb-1">
                                            <input type="text" className="form-control w-100" placeholder="název sekce" value={(element.sectionName[lang] ? element.sectionName[lang] : "")} onChange={(e) => cm.setSectionName(e,lastIndexes)} />
                                        </div>
                                        <div className="form-group">
                                            <input type="text" className="form-control w-100" placeholder="třída" value={element.className} onChange={(e) => cm.setSectionClassName(e,lastIndexes)} />
                                        </div>
                                        <div className="form-group mb-1 mt-4">
                                            <label className="input-group-text w-100">Odsazení zhora / zespod</label>
                                        </div>
                                        <div className="mb-1">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <label className="input-group-text">větší než 1200</label>
                                                </div>
                                                <input type="number" value={element.ptXl} className="form-control" name="ptXl" onChange={(e) => cm.setSectionPadding(e,lastIndexes)}  />
                                                <input type="number" value={element.pbXl} className="form-control" name="pbXl" onChange={(e) => cm.setSectionPadding(e,lastIndexes)}  />
                                            </div>
                                        </div>
                                        
                                        <div className="mb-1">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <label className="input-group-text">992 - 1199</label>
                                                </div>
                                                <input type="number" value={element.ptLg} className="form-control" name="ptLg" onChange={(e) => cm.setSectionPadding(e,lastIndexes)}  />
                                                <input type="number" value={element.pbLg} className="form-control" name="pbLg" onChange={(e) => cm.setSectionPadding(e,lastIndexes)}  />
                                            </div>
                                        </div>
                                        
                                        <div className="mb-1">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <label className="input-group-text">768 - 991</label>
                                                </div>
                                                <input type="number" value={element.ptMd} className="form-control" name="ptMd" onChange={(e) => cm.setSectionPadding(e,lastIndexes)}  />
                                                <input type="number" value={element.pbMd} className="form-control" name="pbMd" onChange={(e) => cm.setSectionPadding(e,lastIndexes)}  />
                                            </div>
                                        </div>
                                        
                                        <div className="mb-1">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <label className="input-group-text">577 - 767</label>
                                                </div>
                                                <input type="number" value={element.ptSm} className="form-control" name="ptSm" onChange={(e) => cm.setSectionPadding(e,lastIndexes)}  />
                                                <input type="number" value={element.pbSm} className="form-control" name="pbSm" onChange={(e) => cm.setSectionPadding(e,lastIndexes)}  />
                                            </div>
                                        </div>
                                        <div className="mb-1">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <label className="input-group-text">menší než 576</label>
                                                </div>
                                                <input type="number" value={element.ptXs} className="form-control" name="ptXs" onChange={(e) => cm.setSectionPadding(e,lastIndexes)}  />
                                                <input type="number" value={element.pbXs} className="form-control" name="pbXs" onChange={(e) => cm.setSectionPadding(e,lastIndexes)}  />
                                            </div>
                                        </div>
                                        
                                    </div>
                                :null}
                            </div>
                        </div>
                        
                        <div className="cm-closing-overlay" onClick={() => cm.showAddElements(lastIndexes)}></div>
                    </Fragment>
                }
            </div>
        );

    }
}

export default AddElement;