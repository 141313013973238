import { withApollo,Mutation} from 'react-apollo';
import React,{useState,useEffect,Fragment} from 'react';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import Helper from '../../../Library/helper';
import AffiliateLib from '../Library/affiliate';
import {SAVE_AFFILIATE_SETTINGS,SAVE_AFFILIATE_CUSTOMER_TYPES,DELETE_AFFILIATE_CUSTOMER} from '../Queries/affiliate';
import Notification from '../../../Library/notification';
import plus from '../../../Public/Images/plus.svg';
import minus from  '../../../Public/Images/minus.svg';
import remove from  '../../../Public/Images/remove.svg';
import AddAffiliate from './AddAffiliate';
import moment from 'moment';
import ModalNotification from '../../../GlobalComponents/ModalNotification';

const Affiliate  = (props) => {

    const affiliateLib = new AffiliateLib(props);
    
    const [formData,setFormdata] = useState({
        selectedLang:"cz",
        ctSelectedLang:"cz",
        allLanguageMutations:[],
        affiliateTotalAmountToPaidOff:[],
        allAffiliateCustomers:[],
        allCustomerTypeWithLang:[],
        langs:[],
    });

    const [openIndex,setOpenIndex]          = useState(null);
    const [showAdd,setShowAdd]              = useState(false);
    const [reload, setReload]               = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(null);

    useEffect(() => {
        affiliateLib.getData(formData,setFormdata);
    },[reload,formData.selectedLang]);

    const deleteAffiliate = async (act) => {
        if(act){
            await props.client.mutate({
                mutation:DELETE_AFFILIATE_CUSTOMER,
                variables:{
                    parentID:confirmDelete.parentID,
                    customerID:confirmDelete.customerID,
                },
                update:() => {
                    setReload(!reload);
                }
            })
        }

        setConfirmDelete(null);
    }

    var totalProfit = 0;
    var totalValueToPaidOff = 0;
    
    return(

        <Mutation 
            mutation={SAVE_AFFILIATE_SETTINGS}
            update = {async (cache, response) => {
                
                let notify = new Notification();
                notify.setNotification(cache,'Úspěšně uloženo',true,false);

            }}
            
        >
        {
            (saveAffiliateSettings,{data,loading,error}) => {

                if(error){
                    const helper = new Helper(); 
                    error = helper.getApolloErrorText(error);
                }
                
                return(
                    <div id="settings" className="whole-container" >

                        <div className="card mb-3">

                            <div className="card-header d-flex align-items-center">
                                Láskyplný dar
                                <div className="ml-auto mr-2">
                                    <select className="form-control" value={formData.selectedLang} name="selectedLang" onChange={(e) => affiliateLib.formHandle(e,formData,setFormdata)}>
                                        {formData.allLanguageMutations && formData.allLanguageMutations.map((item,index) => (
                                            <option key={index} value={item.suffix}> {item.suffix} </option>
                                        ))}
                                    </select>
                                </div>
                                <button className="btn btn-primary btn-thiner" onClick={(e) => setShowAdd(true)}>Přidat</button>
                            </div>
                            <div className="card-body">

                                {formData.affiliateTotalAmountToPaidOff.length > 0 ? 

                                    <>
                                        <div className="row">

                                            {formData.affiliateTotalAmountToPaidOff.map((item,index) => (
                                                <div key={index} className="col-12 cl-sm-6 col-md-4">
                                                    <div>{item.domainName}</div>
                                                    <div>{item.value} {item.currencyTitle}</div>
                                                </div>
                                            ))}

                                        </div>
                                        <hr />
                                        <div className="data-list">
                                                    
                                            <div className="data-list-item header">
                                                <div className="data-list-item-content">
                                                    <div>Jméno</div>
                                                    <div className="text-center">Charizmatické jméno</div>
                                                    <div className="text-center">Členství do</div>
                                                    <div className="text-center">Počet VS</div>
                                                    <div className="text-center">K vyplacení</div>
                                                    <div className="text-right">Celkový výdělek <br /><span style={{fontSize:'11px'}}>(obsahuje částku k vyplacení)</span></div>
                                                    <div className="text-right static small"></div>
                                                </div>
                                            </div>
                                            {formData.allAffiliateCustomers && formData.allAffiliateCustomers.map((item,index) => {
                                                
                                                var profit = 0;
                                                for(let val of item.parent.affiliateProfitsList){
                                                    if(val.customerPaymentsGroup)profit += val.customerPaymentsGroup.affiliateProfit;
                                                }

                                                totalProfit += profit;
                                                totalValueToPaidOff += item.parent.totalValueToPaidOff.value;

                                                var count = 0;
                                                for(let val of item.parent.affiliateProfitsList){
                                                    if(val.dateDeleted == "0000-00-00 00:00:00")count++;
                                                }
                                                
                                                return(
                                                    <Fragment key={index}>
                                                        <div key={index} className="data-list-item-content">
                                                            <div> 
                                                                {openIndex && openIndex == (index + 1) ?
                                                                    <img onClick={() => setOpenIndex(null)} src={minus} className="content-icon mr-1" />
                                                                :
                                                                    <img onClick={() => setOpenIndex(index+1)} src={plus} className="content-icon mr-1" />
                                                                }
                                                                
                                                                {item.parent.name} {item.parent.surname}</div>
                                                            <div className="text-center">{item.parent.astralName}</div>
                                                            <div className="text-center">{item.parent.membershipTo}</div>
                                                            <div className="text-center">{count}</div>
                                                            <div className="text-center">{item.parent.totalValueToPaidOff.value} {item.parent.domain.currencyTitle}</div>
                                                            <div className="text-right">{profit} {item.parent.domain.currencyTitle}</div>
                                                            <div className="text-right static small"><img title="Vymaže všechny lidi pod tímto uživatelem" onClick={() => setConfirmDelete({parentID:item.parent.customerID,customerID:0})} className="remove-icon" src={remove} /></div>
                                                        </div>
                                                        <div className={(openIndex && openIndex == (index + 1) ? "vs-content open" : "vs-content")}>
    
                                                            {item.parent.affiliateProfitsList.map((it,ind) => (
                                                                <div key={ind} className="data-list-item-content">
                                                                    <div>{(it.dateDeleted != "0000-00-00 00:00:00" ? <del>{it.customer.name} {it.customer.surname}</del> : it.customer.name + " " + it.customer.surname)} {(it.dateDeleted != "0000-00-00 00:00:00" ? <div style={{fontSize:"10px"}}>{"(odstraněn/a " + moment(it.dateDeleted).format("DD.MM.YYYY HH:mm:ss") + ")"}</div> : "")}</div>
                                                                    <div className="text-center">{it.customer.astralName}</div>
                                                                    <div className="text-center"></div>
                                                                    <div className="text-center"></div>
                                                                    <div className="text-center"></div>
                                                                    <div className="text-right">{(it.customerPaymentsGroup ? it.customerPaymentsGroup.affiliateProfit : 0 )} {item.parent.domain.currencyTitle}</div>
                                                                    <div className="text-right static small">{(it.dateDeleted == "0000-00-00 00:00:00" ? <img title="Vymaže pouze toto uživatele" onClick={() => setConfirmDelete({parentID:item.parent.customerID,customerID:it.customer.customerID})} className="remove-icon" src={remove} /> : null)}</div>
                                                                </div>
                                                            ))}
    
                                                        </div>
                                                    </Fragment>
                                                )
                                            })}
                                            <div className="data-list-item footer">
                                                <div className="data-list-item-content">
                                                    <div><strong>Celkem</strong></div>
                                                    <div className="text-center"></div>
                                                    <div className="text-center"></div>
                                                    <div className="text-center"></div>
                                                    <div className="text-center"><strong>{(formData.allAffiliateCustomers[0] ? totalValueToPaidOff + " " +formData.allAffiliateCustomers[0].parent.domain.currencyTitle : "")}</strong></div>
                                                    <div className="text-right"><strong>{(formData.allAffiliateCustomers[0] ? totalProfit + " " +formData.allAffiliateCustomers[0].parent.domain.currencyTitle : "")}</strong></div>
                                                    <div className="text-right static small"></div>
                                                </div>
                                            </div>
                                                
                                            
                                        </div>
                                    </>

                                :
                                    <Loading />
                                }

                            </div>
                        </div>

                        <Mutation 
                            mutation={SAVE_AFFILIATE_CUSTOMER_TYPES}
                            update = {async (cache, response) => {
                                
                                let notify = new Notification();
                                notify.setNotification(cache,'Úspěšně uloženo',true,false);

                            }}
                            
                        >
                        {
                            (saveCustomerTypes,{data,loading,error}) => {

                                if(error){
                                    const helper = new Helper(); 
                                    error = helper.getApolloErrorText(error);
                                }
                                
                                return(

                                <div className="card mb-3">

                                    <div className="card-header d-flex align-items-center">
                                        Provize
                                        <div className="ml-auto mr-2">
                                            <select className="form-control" value={formData.ctSelectedLang} name="ctSelectedLang" onChange={(e) => affiliateLib.formHandle(e,formData,setFormdata)}>
                                                {formData.allLanguageMutations && formData.allLanguageMutations.map((item,index) => (
                                                    <option key={index} value={item.suffix}> {item.suffix} </option>
                                                ))}
                                            </select>
                                        </div>
                                        <button className="btn btn-primary btn-thiner" onClick={(e) => affiliateLib.saveCustomerTypes(formData,saveCustomerTypes)}>Uložit</button>
                                        
                                    </div>
                                    <div className="card-body">

                                        <div className="data-list">
                                                                
                                            <div className="data-list-item header">
                                                <div className="data-list-item-content">
                                                    <div>Název</div>
                                                    <div className="text-center">Profit z první platby</div>
                                                    <div className="text-center">Profit z druhé a každé další platby</div>
                                                    <div className="text-center">Profit z roční platby</div>
                                                </div>
                                            </div>

                                            {formData.allCustomerTypeWithLang.map((item,index) => {

                                                return(
                                                    <div key={index} className="data-list-item-content">
                                                        <div>{item.name}</div>
                                                        <div className="text-center"><input className="form-control" type="text" name="affiliateProfit" value={affiliateLib.getLangValue("affiliateProfit",formData,item.customerTypeID)} onChange={(e) => affiliateLib.formLangHandle(e,formData,setFormdata,item.customerTypeID)}  /></div>
                                                        <div className="text-center"><input className="form-control" type="text" name="affiliateProfit2" value={affiliateLib.getLangValue("affiliateProfit2",formData,item.customerTypeID)} onChange={(e) => affiliateLib.formLangHandle(e,formData,setFormdata,item.customerTypeID)}  /></div>
                                                        <div className="text-center"><input className="form-control" type="text" name="anualAffiliateProfit" value={affiliateLib.getLangValue("anualAffiliateProfit",formData,item.customerTypeID)} onChange={(e) => affiliateLib.formLangHandle(e,formData,setFormdata,item.customerTypeID)}  /></div>
                                                    </div>
                                                )

                                            })}
        
                                        </div>
                                        
                                    </div>

                                </div>
                                )
                            }
                        }
                        </Mutation>

                        {showAdd && <AddAffiliate setShowAdd = {setShowAdd} setReload = {setReload} reload = {reload} />}
                        {confirmDelete && <ModalNotification yesNo={true} text={'Opravdu chcete vymazat daného uživatele z láskyplného daru ?'} callback={deleteAffiliate} /> }	
            
                        
                        
                    </div>

                )
            }
        }
        </Mutation>
                
    );
   

}

export default withApollo(Affiliate);