import React,{ Component,Fragment, useState } from 'react';
import { withApollo,useQuery } from 'react-apollo';
import {GET_CUSTOMER_QUESTIONNAIRE_STEPS} from '../Queries/customer';
import Loading from '../../../GlobalComponents/Loading';
import QuestionnaireText from '../../Questionnaire/Components/QuestionnaireText';
import QuestionnaireCheckbox from '../../Questionnaire/Components/QuestionnaireCheckbox';
import QuestionnaireRadio from '../../Questionnaire/Components/QuestionnaireRadio';


const CustomerQuestionnaireSteps = (props) => {

    const {data,loading,error} = useQuery(GET_CUSTOMER_QUESTIONNAIRE_STEPS,{
        variables:{
            lang:"cz",
            customerID:props.customerID,
            questionnaireID:props.questionnaireID
        }
    })

    return(
        
        <div className="card main">
    
            <div className="card-body">

                {loading ?
                    <Loading />
                :
                    <div id="questionnaire" className="no-padding">

                        {data.customerQuestionnaireSteps.length == 0 && data.oneCustomer.hasCompletedQuestionnaire == 1 ?
                            <div className="alert alert-warning text-center no-bottom-margin">Tento Vládce Síly vyplňoval dotazník dříve než byla tato funkce naprogramována, proto zde nic není.</div>
                        :
                            <>

                            {data.oneCustomer.hasCompletedQuestionnaire == 0 ?
                                <div className="alert alert-warning text-center no-bottom-margin">Vládce Síly nevyplnil ještě všechny otázky. Jakmile přejde do portálu, bude mít možnost je vyplnit.</div>
                            :null}

                            {data.getCompleteQuestionnaireSteps.map((item,index) => {

                                var questionStep = null;
                                var questionResult = null;
                                
                                for(let val of data.customerQuestionnaireSteps){

                                    if(val.questionnaireStepID == item.questionnaireStepID){
                                        questionStep = item;
                                        questionResult = val;
                                        break;
                                    }
                                }

                                if(questionStep && questionResult){

                                    return(
                                        <div key={index} className="step-result">

                                            {questionStep.questionTypeID == 1 ?
                                                <QuestionnaireText disabled = {true} question={questionStep.question} text={questionResult.text} />
                                            :null}

                                            {questionStep.questionTypeID == 2 ?
                                                <QuestionnaireCheckbox disabled = {true} questionnaireStepID = {item.questionnaireStepID} question={questionStep.question} values={questionResult.checkboxQuestionnaireStepItemIDs.split(",")} items={questionStep.items} />
                                            :null}

                                            {questionStep.questionTypeID == 3 ?
                                                <QuestionnaireRadio disabled = {true} questionnaireStepID = {item.questionnaireStepID} question={questionStep.question} value={questionResult.radioQuestionnaireStepItemID}  items={questionStep.items} />
                                            :null}
                                            

                                        </div>
                                    )
                                }else{
                                    return "";
                                }

                            })}

                            </>
                        
                        }

                    </div>
                }
                
            </div>
        </div>

    );

}

export default withApollo(CustomerQuestionnaireSteps);