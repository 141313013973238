import React,{ Component } from 'react';


class SelectSubMaterial extends Component{

    constructor(props){
        super(props);
    }

    render(){ 

        return(
            <React.Fragment>
                {this.props.materials && this.props.materials.map((item,index) => (
                    <React.Fragment key={index}>
                        <option key={index+1} value={item.materialID} >{item.name}</option>
                        {false && <SelectSubMaterial materials={item.subMaterial} /> } 
                    </React.Fragment> 
                    
                ))}
            </React.Fragment>

            
        )

    }

}

export default SelectSubMaterial; 