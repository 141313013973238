import { withApollo,Mutation} from 'react-apollo';
import React,{useState,useEffect} from 'react';
import {ADD_AFFILIATE} from '../Queries/affiliate';
import AffiliateLib from '../Library/affiliate';
import Notification from '../../../Library/notification';
import Helper from '../../../Library/helper';
import Modal from 'react-modal';
import Error from '../../../GlobalComponents/Error';
import Loading from '../../../GlobalComponents/Loading';
import SearchInput from '../../../GlobalComponents/SearchInput';
import NoItems from '../../../GlobalComponents/NoItems';
import remove from '../../../Public/Images/remove.svg';
import moment from 'moment';

const AddAffiliate = (props) => {

    var affLib = new AffiliateLib(props);

    const [formData,setFormdata] = useState({
        allCustomers:[],
        paymentsList:[],
        selectedCustomer:{},
        loadingCustomerPayments:false,
        allParents:[],
        selectedParents:[],
        searchText:"",
        searchTextForWho:"",
        selectedPaymentIDs:[]
    });

    return(
        <Mutation 
                mutation={ADD_AFFILIATE}
                update = {async (cache, response) => {

                    let notify = new Notification();
                    notify.setNotification(cache,'Úspěšně přidáno',true,false);
                    
                }}

                onCompleted = {(response) => {
                    props.setShowAdd(false);  
                    props.setReload(!props.reload); 
                }}
                
            >
            {
                (addAffiliate,{data,loading,error}) => {

                    if(error){
                        console.log(error);
                        const helper = new Helper(); 
                        //error = helper.getApolloErrorText(error);
                    }

                    return(
                        <Modal
                            className="Modal__Bootstrap modal-dialog modal-lg"
                            closeTimeoutMS={150}
                            isOpen={true}
                            onRequestClose={() => props.setShowAdd(false)}
                        >
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">Začlenění do láskyplného daru</h4>
                                    <button type="button" className="close" onClick={() => props.setShowAdd(false)}>
                                    <span aria-hidden="true">x</span>
                                    <span className="sr-only">Close</span>
                                    </button>
                                </div>
                                <div className="modal-body">

                                    <div>
                                        {!loading ?
                                            <>
                                                {error ?
                                                    <Error text={error} />
                                                :
                                                    <div className="row">

                                                        <div className="col-12 col-sm-6">

                                                            <div className="card mb-3">

                                                                <div className="card-header d-flex align-items-center">
                                                                    Kdo
                                                                </div>
                                                                <div className="card-body">

                                                                    <div className="form-group">
                                                                        <SearchInput key={formData.searchText} value={formData.searchText} className="form-control" placeholder="Vyhledejte uživatele, který má být přidán" onChange={(text) => affLib.getCustomers(text,"who",formData,setFormdata)} />
                                                                    </div>
                                                                    <div>
                                                                        {formData.allCustomers.length > 0 ?

                                                                            <ul className="customer-list">
                                                                                {formData.allCustomers.map((item,index) => 
                                                                                    <li onClick={() => affLib.selectCutomer(item,formData,setFormdata)} key={index}>{item.name} {item.surname} {(item.astralName != "" ? "(" + item.astralName + ")" : "")} {(item.name == "" && item.surname == "" ? "(" + item.email + ")" : "")}</li>
                                                                                )}
                                                                            </ul>

                                                                        :null}
                                                                    </div>
                                                                    {formData.selectedCustomer.name || formData.selectedCustomer.surname || formData.selectedCustomer.astralName || formData.selectedCustomer.email ?

                                                                        <>

                                                                            <ul className="selected-customer-list">
                                                                                
                                                                                <li className="d-flex align-items-center">
                                                                                    {formData.selectedCustomer.name} {formData.selectedCustomer.surname} {(formData.selectedCustomer.astralName != "" ? "(" + formData.selectedCustomer.astralName + ")" : "")} {(formData.selectedCustomer.name == "" && formData.selectedCustomer.surname == "" ? "(" + formData.selectedCustomer.email + ")" : "")}
                                                                                    <img className="remove-icon ml-auto" src={remove} onClick={() => setFormdata({...formData,selectedCustomer:{}})} />
                                                                                </li>
                                                                                
                                                                            </ul>

                                                                            {!formData.loadingCustomerPayments ?

                                                                                <>

                                                                                    {formData.paymentsList.length > 0 ?

                                                                                    <div className="data-list">
                                                                                                                                        
                                                                                        <div className="data-list-item header">
                                                                                            <div className="data-list-item-content">
                                                                                                <div className="static small"></div>
                                                                                                <div className="text-center">Datum připsáni daru</div>
                                                                                                <div className="text-center">Datum platby</div>
                                                                                                </div>
                                                                                        </div>

                                                                                        
                                                                                            {formData.paymentsList.map((item,index) => {

                                                                                                var checked = false;
                                                                                                var affFrom = 1;

                                                                                                for(let val of formData.selectedPaymentIDs){
                                                                                                    if(val.customerPaymentID == item.customerPaymentID){
                                                                                                        checked = true;
                                                                                                        affFrom = val.commisionInAffiliateFrom;
                                                                                                    }  
                                                                                                }
                                                                                                
                                                                                                return(
                                                                                                    <div key={index} className="data-list-item-content">
                                                                                                        <div className="static small"><input type="checkbox" checked={checked} onChange={() => affLib.selectPayment(item.customerPaymentID,formData,setFormdata)} className="delete-checked" /></div>
                                                                                                        <div className="text-center">
                                                                                                            <select value={affFrom} className="form-control" onChange={(e) => affLib.selectPayment(item.customerPaymentID,formData,setFormdata,e.target.value)}>
                                                                                                                <option value="1">Okamžitě</option>
                                                                                                                <option value="2">14 dní od vytvoření platby</option>
                                                                                                            </select>
                                                                                                        </div>
                                                                                                        <div className="text-center">{moment(item.dateAdd).format("DD.MM.YYYY HH:mm:ss")}</div>
                                                                                                    </div>
                                                                                                )
                                                                                            })}
                                                                                        
                                                                                    </div>
                                                                                    
                                                                                    :null}

                                                                                </>
                                                                            
                                                                            :
                                                                                <Loading />  
                                                                            }

                                                                        </>
                                                                    
                                                                    :
                                                                        <NoItems text={"Zatím nebyl nikdo vybrán."} />
                                                                    }

                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div className="col-12 col-sm-6">

                                                            <div className="card mb-3">

                                                                <div className="card-header d-flex align-items-center">
                                                                    Pod koho
                                                                </div>
                                                                <div className="card-body">

                                                                    <div className="form-group">
                                                                        <SearchInput key={formData.searchTextForWho} value={formData.searchTextForWho} className="form-control" placeholder="Vyhledejte pod koho má být daný uživatel přidán" onChange={(text) => affLib.getCustomers(text,"forWho",formData,setFormdata)} />
                                                                    </div>
                                                                    <div>
                                                                        {formData.allParents.length > 0 ?

                                                                            <ul className="customer-list">
                                                                                {formData.allParents.map((item,index) => 
                                                                                    <li onClick={() => setFormdata({...formData,searchTextForWho:"",allParents:[],selectedParents:[item,...formData.selectedParents]})} key={index}>{item.name} {item.surname} {(item.astralName != "" ? "(" + item.astralName + ")" : "")} {(item.name == "" && item.surname == "" ? "(" + item.email + ")" : "")}</li>
                                                                                )}
                                                                            </ul>

                                                                        :null}
                                                                    </div>
                                                                        {formData.selectedParents.length > 0 ?

                                                                            <ul className="selected-customer-list">
                                                                                {formData.selectedParents.map((it,ind) => (
                                                                                    <li key={ind} className="d-flex align-items-center">
                                                                                        {it.name} {it.surname} {(it.astralName != "" ? "(" + it.astralName + ")" : "")} {(it.name == "" && it.surname == "" ? "(" + it.email + ")" : "")}
                                                                                        <img className="remove-icon ml-auto" src={remove} onClick={() => affLib.removeSelectedParent(it,formData,setFormdata)} />
                                                                                    </li>
                                                                                ))}
                                                                            </ul>

                                                                        :
                                                                            <NoItems text={"Zatím nebyl nikdo vybrán."} />
                                                                        }
                                                                </div>
                                                            </div>

                                                        </div>

                                                        
                                                    </div>
                                                }
                                            </>
                                        :
                                        <Loading />  
                                        }  

                                    </div>
               
                                </div>
                                <div className="modal-footer"> 
                                
                                    <button type="button" className="btn btn-primary flex-fill" onClick={() => affLib.addAffiliate(addAffiliate,formData)}>{"Přidat"}</button>
                                    <button type="button" className="btn btn-danger" onClick={() => props.setShowAdd(false)}>Zavřít</button>
                                </div>
                            </div>
                        </Modal>
                    );

                }
            }
            </Mutation>
    )

}

export default withApollo(AddAffiliate);