/**
 * soubor se seznamem veškerých graphql dotazů pro zákazníka
 */
import gql from 'graphql-tag';


export const GET_AFFILIATE_DATA = gql`
    query GetAffiliateData($lang:String){
        affiliateTotalAmountToPaidOff{
            lang
            value
            currencyTitle
            domainName
        }
        allLanguageMutations(onlyEnabled:true){
            languageID
            title
            suffix
            generateNiceTitle
            langTitle
            decimal
            priority
            main
            currencyTitle
        }
        settingsWithLang{
            langs{
                lang
                firstProfitAmount
                secondProfitAmount
            }
        }
        allCustomerTypeWithLang{
            customerTypeID
            name
            langs{
                lang
                affiliateProfit
                affiliateProfit2
                anualAffiliateProfit
            }
            
        }
        allAffiliateCustomers(lang:$lang){
            customerID
            parentID
            parent{
                name
                surname
                astralName
                membershipTo
                customerID
                domainID
                totalValueToPaidOff{
                    value
                }
                domain{
                    currencyTitle
                }
                affiliateProfitsList{
                    customerID
                    customerAffiliate
                    month
                    year
                    dateAdd
                    dateDeleted
                    customer{
                        customerID
                        name
                        surname
                        astralName
                    }
                    customerPaymentsGroup(useDateFrom:true){
                        customerID
                        value 
                        affiliateProfit
                        count
                    }
                }
            }
        }

    }
`;

export const SAVE_AFFILIATE_SETTINGS = gql`
    mutation SaveAffiliateSettings($langs:[SettingsInput]){
        saveAffiliateSettings(langs:$langs)
    }
`;

export const SAVE_AFFILIATE_CUSTOMER_TYPES = gql`
    mutation SaveAffiliateCustomerTypes($data:[CustomerTypesLangs]){
        saveAffiliateCustomerTypes(data:$data)
    }
`;

export const ADD_AFFILIATE = gql`
    mutation AddAffiliate($selectedCustomer:ID,$selectedParents:[ID],$selectedPayments:[SelectedPaymentsInput]){
        addAffiliate(selectedCustomer:$selectedCustomer,selectedParents:$selectedParents,selectedPayments:$selectedPayments)
    }
`;

export const DELETE_AFFILIATE_CUSTOMER = gql`
mutation DeleteAffiliateCustomer($parentID:ID,$customerID:ID){
    deleteAffiliateCustomer(parentID:$parentID,customerID:$customerID)
}
`;